import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pb from '../../common/ProgressBar'
import { getColor } from '../../common/Utils'
import Cookies from 'js-cookie'
import axios from 'axios'
import {
  getBaseURL
} from '../../common/Utils'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import rtl from 'jss-rtl';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


const theme = createMuiTheme({
  direction: 'rtl',
  typography: {
    fontFamily: [
      'Cairo',
      'Arial',
      'sans-serif'
    ].join(','),
  },

});


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: getColor('primary').dark
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    width: '100%',
    marginTop: '10px'
  },
  autocomplete: {
    marginTop: '15px'
  }
}));

export default function FormDialog(props) {
  const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
  if (
    userData.userInfo.role.name !== 'Admin' &&
    userData.userInfo.role.name !== 'Root'
  )
    window.location.href = '/'

  const url = getBaseURL()
  const [shifts, setShifts] = useState([])

  const classes = useStyles()

  const newShifts = props.shifts.filter(function (objFromA) {
    return !props.shiftsData.find(function (objFromB) {
      return objFromA.id === objFromB.id
    })
  })
  // props.onShiftChange('',newShifts)

  const handleEnteredEvent = (e) => {
    if (!props.isRequesting) {
      if (e.key === 'Enter') {
        props.assign()
      } else if (e.key === 'Escape') {
        props.closeAssignModal()
      }
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Dialog
          open={props.show}
          onClose={props.closeAssignModal}
          aria-labelledby="form-dialog-title"
          onKeyDown={handleEnteredEvent}
        >
          <DialogTitle id="form-dialog-title">اسناد سائق الى مركبة</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ fontSize: '16px', marginBottom: '25px' }}
            >
              السائق : {props.selectedDriver.driver.name}
            </DialogContentText>
            <Autocomplete
              className={classes.autocomplete}

              id="vehicles"
              value={props.selectedVehicle ? props.selectedVehicle : props.vehicles[0]}

              options={props.vehicles}
              onChange={props.onVehicleChange}
              getOptionLabel={(option) => option.plate_number}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="الاليات" variant="outlined" />}
            />
            <Autocomplete
              className={classes.autocomplete}

              id="centers"
              value={props.selectedCenter ? props.selectedCenter : props.centers[0]}

              options={props.centers}
              onChange={props.onCenterChange}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="المراكز" variant="outlined" />}
            />
            <Autocomplete
              className={classes.autocomplete}
              id="shifts"
              value={props.selectedShift ? props.selectedShift : newShifts[0]}

              options={newShifts}
              onChange={props.onShiftChange}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="الوجبات المتاحة للسائق" variant="outlined" />}
            />
            {
              props.isRequesting ?
                <Pb />
                :
                null
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={props.closeAssignModal} color="primary">
              الغاء
            </Button>
            <Button onClick={props.assign} color="primary">
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      </StylesProvider>
    </ThemeProvider>
  );


}


