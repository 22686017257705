import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


// const RowDiv = styled.div`
// display:flex;
// flex-direction:row;
// align-items:baseline;
// justify-content:center;
// margin-top:5px;

// `;

const KeyP = styled.p`

border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-right:5px;
margin-left:5px;
padding-right:5px;
padding-left:5px;
color: #000;
`;

const KeyV = styled.p`

border-radius:5px;
margin-right:5px;
margin-left:5px;
padding-right:5px;
padding-left:5px;
font-size:1rem;
`;
const RowInfo = React.memo(
    function RowInfo(props) {
        return (
            <React.Fragment>
                <Grid style={{
                    marginTop: '5px'
                }} container spacing={1}>
                    <Grid item xs={3}>
                        <KeyP>
                            {props.k}
                        </KeyP>
                    </Grid>
                    <Grid item xs={9}>
                        <KeyV>
                            {props.value}
                        </KeyV>
                    </Grid>



                </Grid>

                <Divider style={{
                    width: '100%'
                }} />
            </React.Fragment>

        )
    })

export default RowInfo
