import React from 'react'
import styled from 'styled-components'


const Root = styled.div`
display:flex;
width:100%;
height:100%;
`;
function HandleError(props) {
    console.log('errors are : ')
    console.log(props.errors)
    return (
        <Root>
            Hello {props.errors}
            <p>{props.errCode.status}</p>
            <p>{props.isThereError}</p>
        </Root>
    )
}

export default HandleError
