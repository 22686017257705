import React, { useState, useEffect } from 'react'
import Root from '../../common/BodyMRoot'
import CrudControl from '../../common/CrudControl'
import Loading from '../../common/Loading'
import axios from 'axios'
import Cookies from 'js-cookie'
import Snackbar from '../../common/SnackBar'
import {
	formatErrorMgs2,
	getBaseURL,
	getStatusCode,
	handleErr,
	configureDate,
} from '../../common/Utils'
import { getGivenItems } from '../../common/ApiHelper'
import NoDataDiv from '../../common/NoDataDiv'
import VehiclesNotAllowTable from './VehiclesNotAllowTable'
import { getfreightsnotallow } from '../../common/ApiHelper'
// import AddCenterModal from './AddCenterModal'
import YesNoDialogDelete from '../../common/YesNoDialog'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FromDatePicker from '../../common/DatePicker'
import ToDatePicker from '../../common/DatePicker'
import styled from 'styled-components'
import FindReplaceIcon from '@material-ui/icons/FindReplace'
import { Alert } from '@material-ui/lab'
import CustomPagination from '../../common/CustomPagination'
import moment from 'moment'

// import EditModal from './EdtiModal'
const url = getBaseURL()

const DatesContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`
function VehiclesNotAllow() {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		// window.location.href = '/'
	}

	const [isLoading, setIsLoading] = useState(true)
	const [data, setdata] = useState([])
	const [filteredNotCompleteFreights, setfilteredNotCompleteFreights] =
		useState([])
	const [daydateSpecific, setdaydateSpecific] = useState(null)
	const [toDate, setToDate] = useState(new Date())
	const [fromDate, setFromDate] = useState(new Date())

	const [fieldsData, setFieldsData] = useState({
		user: '',
		vehicleName: '',
		centername: '',
		shiftName: '',
		status: '',
		todaydate: '',
	})
	const [search, setSearch] = useState('')
	const [departments, setDepartments] = useState([])

	const [bigError, setBigError] = useState(false)
	const [errCode, setErrCode] = useState({
		status: 0,
		title: '',
		server_errors: '',
		how_to_fix: '',
	})
	const [alert, setAlert] = useState({
		show: false,
		color: 'error',
		title: '',
		msg: '',
	})
	const [isRequesting, setIsRequesting] = useState(false)
	const [updateCenters, setUpdateCenters] = useState(false)

	const [showAddCenterModal, setShowAddCenterModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [centerToDelete, setCenterToDelete] = useState(null)
	const [yesNoDialogDelete, setYesNoDialogDelete] = useState({
		open: false,
		title: '',
		msg: '',
	})
	const [alertshow, setalertshow] = useState({
		show: false,
		color: 'error',
		title: '',
		msg: '',
	})
	const [pageSize, setPageSize] = useState(5)
	const [pageNumber, setPageNumber] = useState(1)
	const [totalRows, setTotalRows] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	async function fetchdata() {
		// userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
		// if (userData.userInfo.role.name === 'Reporter') window.location.href = '/'
		setIsLoading(true)
		setIsRequesting(true)
		//get freights of vehicles not have freights yet today.
		let data1 = null
		try {
			data1 = await getfreightsnotallow('freights/notcomplete')
			if (data1) {
				setdata(data1.data)
				setfilteredNotCompleteFreights(data1.data)
				setIsLoading(false)

				// console.log(data1.data)
			}
			// setdata(data1.data)
			// setfilteredNotCompleteFreights(data1.data)
			setIsLoading(false)
			setIsRequesting(false)

			if (
				localStorage.getItem('searchNotCompleteFreightsd') &&
				localStorage.getItem('searchNotCompleteFreightsd') != 'null' &&
				localStorage.getItem('searchNotCompleteFreightsd') != null &&
				data1?.data?.length > 0
			) {
				setSearch(
					localStorage.getItem('searchNotCompleteFreightsd').toString()
				)
				let filtered
				if (
					localStorage.getItem('searchNotCompleteFreightsd').toString().length
				) {
					console.log(data1.data)
					let se = localStorage
						.getItem('searchNotCompleteFreightsd')
						.toString()
					filtered = data1.data?.filter(function (item) {
						return (
							item.user.toLowerCase().includes(se.toLowerCase()) ||
							item.vehicleName.toLowerCase().includes(se.toLowerCase()) ||
							item.centername.toLowerCase().includes(se.toLowerCase()) ||
							item.shiftName.toLowerCase().includes(se.toLowerCase()) ||
							item.status.toLowerCase().includes(se.toLowerCase()) ||
							item.todaydate.toLowerCase().includes(se.toLowerCase())
						)
					})
					setfilteredNotCompleteFreights(filtered)
				} else {
					setfilteredNotCompleteFreights(data)
				}
			}
		} catch (error) {
			let code = getStatusCode(error)
			let errCodeObj = handleErr(code, error)
			setErrCode(errCodeObj)

			setIsRequesting(false)
			setIsLoading(false)
		}
	}
	useEffect(() => {

		// fetchdata()
		getspecificfreightstoday()
		// fetch data from the server
	}, [pageSize, pageNumber, toDate])

	useEffect(() => {
		console.log('sdsdsdfsdf====>')
		document.addEventListener('keydown', handleEnteredEvent)
		return () => {
			document.removeEventListener('keydown', handleEnteredEvent)
		}
	})
	const handleEnteredEvent = (e) => {
		if (e.key === 'Enter') {
			getspecificfreightstoday()
		} else if (e.key === 'Escape') {
			
		}
	}
	// useEffect(()=>{},)

	return (
		<Root>
			{
				<Grid
					container="fluid"
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						position: 'relative',
					}}
				>
					<Grid item xs={8}>
						<DatesContainer>
							<FromDatePicker
								style={{
									padding: '15px',
								}}
								title="من تاريخ"
								selectedDate={fromDate}
								handleDateChange={onFromDateChange}
							></FromDatePicker>
							<FromDatePicker
								style={{
									padding: '15px',
								}}
								title="الى تاريخ"
								selectedDate={toDate}
								handleDateChange={onToDateChange}
							></FromDatePicker>
							<Button
								disabled={isLoading}
								variant="contained"
								color="primary"
								startIcon={<FindReplaceIcon />}
								onClick={getspecificfreightstoday.bind(this)}
							>
								<span
									style={{
										paddingRight: '10px',
									}}
								>
									بحث من جديد
								</span>
							</Button>
						</DatesContainer>
					</Grid>

					<Grid
						item
						xs={6}
						style={{
							display: 'flex',
							justifyContent: 'start',
							position: 'absolute',
							top: '30%',
							left: '3%',
						}}
					>
						<CrudControl
							hiddeButton={true}
							showSearch={true}
							openModal={openAddModal}
							onSearchChange={onSearchChange}
							onSearchEnter={onSearchEnter}
							searchValue={search}
						/>

					</Grid>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							gap: '10px',
							marginTop: 10,
							marginBottom: 10,
						}}
					>
						<Button
							onClick={() => {
								setFromDate(moment()._d)
								setToDate(moment()._d)
							}}
							variant="contained"
							color="secondary"
						>
							اليوم
						</Button>

						<Button
							onClick={() => {
								setFromDate(moment().add(-1, 'days')._d)
								setToDate(moment().add(-1, 'days')._d)
							}}
							variant="contained"
							color="secondary"
						>
							الامس
						</Button>

						<Button
							onClick={() => {
								setFromDate(moment().add(-1, 'weeks')._d)
								setToDate(moment().add(-1, 'days')._d)
							}}
							variant="contained"
							color="secondary"
						>
							قبل اسبوع
						</Button>

						<Button
							onClick={() => {
								setFromDate(moment().add(-1, 'months')._d)
								setToDate(moment().add(-1, 'days')._d)
							}}
							variant="contained"
							color="secondary"
						>
							قبل شهر
						</Button>
					</div>
				</Grid>
			}
			<br />
			<br />
			<br />
			<br />

			{isLoading ? (
				<Loading />
			) : (
				<>
					<VehiclesNotAllowTable data={filteredNotCompleteFreights} />
					{/* <CustomPagination
						count={pageCount}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						pageSize={pageSize}
						setPageSize={setPageSize}
					/> */}
					<br />
					<br />
					<br />
					<br />
					<br />
				</>
			)}
			{alertshow.show ? (
				<Snackbar
					title={alertshow.title}
					msg={alertshow.msg}
					show={alertshow.show}
					period={6000}
					severity={alertshow.color}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
		</Root>
	)
	function closeAddModal(e) {
		setShowAddCenterModal(false)
	}
	function openAddModal(e) {
		if (departments.length === 0) {
			setAlert({
				show: true,
				color: 'warning',
				title: ['لم يتم تحميل جميع المصادر بنجاح'],
				msg: 'يرجى اعادة تحميل الصفحة',
			})
			return
		}
		setShowAddCenterModal(true)
	}
	function onSearchChange(e) {
		setSearch(e.target.value)
		if (localStorage.getItem('searchNotCompleteFreightsd')) {
			localStorage.removeItem('searchNotCompleteFreightsd')
		}
	}
	function onSearchEnter(e) {
		if (e.key === 'Enter') {
			setIsLoading(true)
			let filtered
			if (e.target.value.length) {
				filtered = data?.filter(function (item) {
					return (
						item.user.toLowerCase().includes(search.toLowerCase()) ||
						item.vehicleName.toLowerCase().includes(search.toLowerCase()) ||
						item.centername.toLowerCase().includes(search.toLowerCase()) ||
						item.shiftName.toLowerCase().includes(search.toLowerCase()) ||
						item.status.toLowerCase().includes(search.toLowerCase()) ||
						item.todaydate.toLowerCase().includes(search.toLowerCase())
					)
				})

				setfilteredNotCompleteFreights(filtered)
			} else {
				setfilteredNotCompleteFreights(data)
			}
			setIsLoading(false)
		}
	}

	function onFromDateChange(e) {
		// console.log('rendering on input change')
		setdaydateSpecific(e)
		setFromDate(e)
	}
	function onToDateChange(e) {
		setToDate(e)
	}
	async function getspecificfreightstoday() {
		if (fromDate && fromDate !== null && toDate && toDate !== null) {
			let fromdaydate__ = configureDate(fromDate)
			let todaydate__ = configureDate(toDate)
			// console.log(daydate__)
			if (fromdaydate__ != 'NaN-NaN-NaN' && todaydate__ != 'NaN-NaN-NaN') {
				setIsLoading(true)
				let result = null
				let data = {
					fromDate: fromDate,
					toDate: toDate
				}
				let params = {
					page_size: pageSize,
					page: pageNumber,
					search: search ? search?.toLowerCase() : null,
				}
				try {
					result = await axios({
						url: url + `/freights/notcomplete/specificdaydate`,
						// url: url + `/freights?from=1970-03-01`+'&to=1970-03-29',
						method: 'post',
						headers: {
							'Content-Type': 'application/json',
							Authorization: 'Bearer ' + userData.token,
						},
						data: data,
						params: params
					})

					if (result.data) {
						// console.log(result?.data)
						setdata(result?.data)
						setfilteredNotCompleteFreights(result?.data)
						// setPageCount(result.data)
						// setTotalRows(result.data?.total)
						setIsLoading(false)
						setalertshow({
							show: true,
							title: 'تم البحث بنجاح',
							msg: 'تم البحث بنجاح',
							color: 'success',
						})
					}
					// console.log(result)
				} catch (error) {
					console.log(error)
					setIsLoading(false)
					setalertshow({
						show: true,
						title: 'حدث خظأ ما',
						color: 'error',
						msg: 'حدث خطأ ما',
					})
				}
			} else {
				setalertshow({
					show: true,
					title: 'يرجا اختيار تاريخ صحيح',
					color: 'error',
					msg: 'يرجا اختيار تاريخ صحيح',
				})
			}
		} else {
			setalertshow({
				show: true,
				title: ' يجب اختيار الفترة بشكل صحيح',
				msg: ' يجب اختيار الفترة بشكل صحيح',
				color: 'warning',
			})
		}
	}

	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}

		setalertshow({
			show: false,
			title: '',
			msg: '',
			color: 'error',
		})
	}
}

export default VehiclesNotAllow
