import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import { Box } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(2),
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

export default function CustomPagination(props) {
	const classes = useStyles()

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				backgroundColor: '#fff',
				padding: '10px 15px',
				// borderRadius: '5px',
				boxShadow: '0 10px 7px -2px #000000a0',
				width: '100%',
				borderTop: props?.noBoarded ? '' : '2px dashed grey',
			}}
		>
			<Pagination
				count={props?.count}
				variant="outlined"
				color="primary"
				page={props?.pageNumber}
				onChange={(e, p) => {
					props.setPageNumber(p)
				}}
				style={{
					width: '80%',
				}}
			/>
			{!props?.onPageSize ? (
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel id="demo-simple-select-outlined-label">
						حجم الصفحة
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={props?.pageSize}
						onChange={(e) => {
							props.setPageSize(e.target.value)
						}}
						label="حجم الصفحة"
					>
						{props?.pages && props?.pages?.length > 0
							? props?.pages?.map((itm) => (
									<MenuItem value={itm} key={itm}>{itm}</MenuItem>
							  ))
							: [5, 10, 20]?.map((itm) => (
									<MenuItem value={itm} key={itm}>{itm}</MenuItem>
							  ))}
					</Select>
				</FormControl>
			) : null}
		</div>
	)
}
