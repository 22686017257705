import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import UserActivityPart from './UserActivity'
import StatisCards from '../../common/StatisCards'
import axios from 'axios'
import { getBaseURL, handleError } from '../../common/Utils'
import Cookies from 'js-cookie'

const url = getBaseURL()

// const Root = styled.div`
// display:flex;
// flex-direction:column;
// width:100%;
// height:100%;
// background-color:red;
// `;
const colors = require('../../../assets/files/env.json').theme.colors

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))
function Dashboard(props) {
	const [data, setData] = useState(null)
	const [isError, setIsError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [isLoading, setIsLoading] = useState(true)

	const classes = useStyles()

	useEffect(() => {
		const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
		if (
			userData.userInfo.role.name !== 'Admin' &&
			userData.userInfo.role.name !== 'Root'
		) {
			console.log('roile name is ' + userData.userInfo.role.name)
			window.location.href = '/'
		}

		const fetchData = async () => {
			setIsLoading(true)
			setIsError(false)
			let result = null
			try {
				result = await axios({
					url: url + `/accounts/dashboard`,
					method: 'GET',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						Authorization: 'Bearer ' + userData.token,
					},
					data: {},
				})

				setData(result.data.Data)
			} catch (error) {
				let errors = handleError(error)
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
				}

				setIsError(true)
				setErrorMsg(errors)
			}
			setIsLoading(false)
		}
		fetchData()
	}, [])
	let count = 0
	return (
		<div className={classes.root}>
			{
				<Grid direction="column" alignItems="flex-start" container spacing={3}>
					<UserActivityPart
						isLoading={isLoading}
						colors={colors}
						list={data && data.users ? data.users.last_activities : []}
					></UserActivityPart>

					{data && data.lists
						? Object.entries(data.lists).map(([key, value]) => {
								return (
									<React.Fragment key={key}>
										<h3
											key={key}
											style={{
												marginRight: '15px',
												marginTop: '15px',
											}}
										>
											{key}{' '}
										</h3>
										<StatisCards
											key={'sc_' + count++ + key}
											title=""
											list={value}
										></StatisCards>
									</React.Fragment>
								)
						  })
						: null}
				</Grid>
			}
		</div>
	)
}

export default Dashboard
