import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
 class MyTableCell extends React.Component {
     constructor(props)
     {
         super(props)
     }
    shouldComponentUpdate(nextProps, nextState)
    {
      
      if (this.props.value === nextProps.value) {
        return false;
      } else {
  
        return true;
      }
    }
    render() {
        return (
           
                <StyledTableCell align="right">{this.props.value}</StyledTableCell>
          
        )
    }
}

export default MyTableCell
