import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import UserStatus from '../../common/UserStatus'
import GuidMark from '../../common/GuidMark'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		color: theme.palette.text.secondary,
		maxHeight: '300px',
		overflow: 'auto',
	},
	header: {
		display: 'flex',
		width: '100%',
		height: '35px',
		backgroundColor: '#d4d4d4',
	},
	title: {
		color: 'black',
		fontWeight: 'bold',
		padding: '5px',
	},
	body: {},
	grid1: {
		order: 1,
		[theme.breakpoints.between('xs', 'md')]: {
			order: 2,
		},
	},
	grid2: {
		order: 2,
		[theme.breakpoints.between('xs', 'md')]: {
			order: 1,
		},
	},
	grid: {
		maxHeight: '35px',
		margin: '5px',
	},
}))

function UserActivity(props) {
	const classes = useStyles()

	return (
		<Grid container spacing={3}>
			<Grid className={classes.grid1} item xs={12} md={12} lg={9}>
				<div className={classes.root}>
					<Paper className={classes.paper}>
						<div className={classes.header}>
							<h4 className={classes.title}>نشاط المستخدم</h4>
						</div>
						<div className={classes.body}>
							<Grid direction={'column'} container spacing={0}>
								{props.list.map(function (item, index) {
									return (
										<Grid className={classes.grid} item xs={12} key={index}>
											<UserStatus
												isLoading={props.isLoading}
												data={item}
											></UserStatus>
										</Grid>
									)
								})}
							</Grid>
						</div>
					</Paper>
				</div>
			</Grid>
			<Grid className={classes.grid2} item xs={12} md={12} lg={3}>
				<Paper className={classes.paper}>
					<GuidMark
						bgColor={props.colors.secondary}
						text={'اخر زيارة للنظام'}
					></GuidMark>
					<GuidMark
						bgColor={props.colors.others.green}
						text={'اخر نشاط في النظام'}
					></GuidMark>
				</Paper>
			</Grid>
		</Grid>
	)
}

export default UserActivity
