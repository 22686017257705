import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import MyTableRow from '../../common/TableRow'
import MyTableCell from '../../common/CustomTableCell'

const headers = require('../../../assets/files/headers.json').acl_table

const crudColors = require('../../../assets/files/env.json').theme.colors.crud
const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	}
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		},
		borderBottom:'2px solid #c4ddec'

	}
}))(TableRow)

const useStyles = makeStyles({
	table: {
		minWidth: 700
	}
})

function CustomizedTables(props) {
	const classes = useStyles()
	let numbering = 0
	let prop = props

	console.log('props.requiredData',props.requiredData)
	const reqData = props.requiredData.filter((req)=>{
		return req.id === props.data[0].user_id
	})

	// console.log('itemclass',props.data)
	return (
		<TableContainer component={Paper}>
			<Table
				size="small"
				className={classes.table}
				aria-label="customized table"
			>
				<TableHead>
					<MyTableRow header={headers}></MyTableRow>
				</TableHead>
				<TableBody>
					{props.data.map(function(item, index) {
						
						const specificRequired = reqData[0].requiredRecord.filter(req=>{
							if(req.center === item.center_id && req.class === item.vehicle_class_id && req.type === item.vehicle_type_id){
								return req
							}
						})
						// console.log('reqdad',reqData,item)
						numbering++
						return (
							<TableRow key={index}>
								<MyTableCell
									key={'acl_numbering' + index}
									value={numbering}
								></MyTableCell>

								<MyTableCell
									key={'acl_dep_name' + item.department.id}
									value={item.department.name}
								></MyTableCell>
								<MyTableCell
									key={'acl_center_name' + item.center.id}
									value={item.center.name}
								></MyTableCell>
								<MyTableCell
									key={'acl_vehicle_type_name' + item.vehicle_type.id}
									value={item.vehicle_type.name}
								></MyTableCell>
								<MyTableCell
									key={'acl_vehicle_class_name' + item.vehicle_class.id}
									value={item.vehicle_class.name}
								></MyTableCell>
								<MyTableCell
									key={'acl_vehicle_vehicleCount' + specificRequired[0].vehicles}
									value={specificRequired[0].vehicles}
								></MyTableCell>
								<MyTableCell
									key={'acl_vehicle_shiftCount' + specificRequired[0].shifts}
									value={specificRequired[0].shifts}
								></MyTableCell>
								
								

								<StyledTableCell key={'acl_delete' + item.id} align="right">
									<IconButton
										onClick={() =>
											prop.showDeleteDialogFun({
												udc: item,
												index: index
											})
										}
									>
										<DeleteForeverIcon style={{ color: crudColors.d }} />
									</IconButton>
								</StyledTableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default CustomizedTables
