import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
width:100%;
height:100%;
border:solid 1px #A7A7A7;
border-radius:15px;
background-color:white;
padding:10px;
`;
const Header = styled.div`
height:25px;
width:100%;
background-color:#007AC4;
border-radius:15px 15px 0px 0px;
`;
const Title = styled.h4`
padding-top:2px;
text-align:center;
color:white;
`;

const Body = styled.div`
width:100%;
height:calc(100% - 25px);
`;
function CrudFrame(props) {
    return (
        <Root className="test">
            <Header>
                <Title >{props.title}</Title>
            </Header>
            <Body>
                {props.children}
            </Body>
        </Root>
    )
}

export default CrudFrame
