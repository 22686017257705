import React, { useState, useEffect } from 'react'
import Root from '../../common/BodyMRoot'
import CrudControl from '../../common/CrudControl'
import Loading from '../../common/Loading'
import axios from 'axios'
import Cookies from 'js-cookie'
import Snackbar from '../../common/SnackBar'
import {
	formatErrorMgs2,
	getBaseURL,
	getStatusCode,
	handleErr,
} from '../../common/Utils'
import { getGivenItems } from '../../common/ApiHelper'
import NoDataDiv from '../../common/NoDataDiv'
import CentersTable from './CentersTable'
import AddCenterModal from './AddCenterModal'
import YesNoDialogDelete from '../../common/YesNoDialog'
import EditModal from './EdtiModal'
import CustomPagination from '../../common/CustomPagination'
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core'
import BackDropLoading from '../../common/BackDropLoading'
const url = getBaseURL()
let userData = null
try {
	userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
} catch (error) {
	// window.location.href = '/'
}
function Centers(props) {
	const [centers, setCenters] = useState([])
	const [filteredCenters, setFilteredCenters] = useState([])
	const [fieldsData, setFieldsData] = useState({
		name: '',
		descr: '',
		selectedDepartment: null,
		order: 0,
		manager: '',
		job_title: '',
	})
	const [search, setSearch] = useState('')
	const [departments, setDepartments] = useState([])

	const [bigError, setBigError] = useState(false)
	const [errCode, setErrCode] = useState({
		status: 0,
		title: '',
		server_errors: '',
		how_to_fix: '',
	})
	const [alert, setAlert] = useState({
		show: false,
		color: 'error',
		title: '',
		msg: '',
	})
	const [isLoading, setIsLoading] = useState(true)
	const [isRequesting, setIsRequesting] = useState(false)
	const [updateCenters, setUpdateCenters] = useState(false)
	const [pageSize, setPageSize] = useState(5)
	const [pageNumber, setPageNumber] = useState(1)
	const [totalRows, setTotalRows] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const [showAddCenterModal, setShowAddCenterModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [centerToDelete, setCenterToDelete] = useState(null)
	const [deleteLoading, setDeleteLoading] = useState(false)
	const [deleteStatus, setDeleteStatus] = useState(false)
	const [deleteProcessComplete, setDeleteProcessComplete] = useState(false)

	const [yesNoDialogDelete, setYesNoDialogDelete] = useState({
		open: false,
		title: '',
		msg: '',
	})
	const fetchCenters = async () => {
		console.log('CENTER_DATA=======>1')
		setIsLoading(true)
		setIsRequesting(true)
		console.log('CENTER_DATA=======>2')
		let userData = null
		try {
			userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
		} catch (error) {
			window.location.href = '/'
		}

		try {
			console.log('CENTER_DATA=======>3')
			let result = await axios({
				url: url + `/centers`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData.token,
				},
				params: {
					page_size: pageSize,
					page: pageNumber,
					search: search ? search?.toLowerCase() : null,
				},
			})
			console.log('CENTER_DATA=======>4')

			if (result?.data?.Data?.data) {
				setCenters(result.data.Data?.data)
				setFilteredCenters(result.data.Data?.data)
			}
			setPageCount(result.data.Data?.last_page)
			setTotalRows(result.data.Data?.total)
			console.log(result.data.Data)
			setIsLoading(false)
			setIsRequesting(false)
			setUpdateCenters(false)
		} catch (error) {
			let code = getStatusCode(error)
			let errCodeObj = handleErr(code, error)
			setErrCode(errCodeObj)

			setIsRequesting(false)
			setIsLoading(false)
		}
	}
	const fetchResources = async () => {
		let result = await getGivenItems(['departments'])
		if (!result.data && !result.data.Data) {
			setBigError(true)
			return
		}
		let arrays = result.data.Data.returned_items
		setDepartments(arrays.departments)
		setFieldsData({
			...fieldsData,
			selectedDepartment: arrays.departments[0],
		})
	}

	useEffect(() => {
		// fetchCenters()
		fetchResources()
	}, [updateCenters])
	useEffect(() => {
		fetchCenters()
	}, [pageNumber, pageSize, updateCenters, props?.showCenter])
	
	const handleEnteredEvent = (e) => {
		if (e.key === 'Enter') {
			deleteFunction()
		} else if (e.key === 'Escape') {
			setDeleteLoading(false)
			setDeleteProcessComplete(false)
			setDeleteStatus(false)
			setUpdateCenters(true)
		}
	}
	return (
		<Root>
			{isLoading ? <BackDropLoading open={isLoading} /> : null}
			<CrudControl
				showSearch={true}
				openModal={openAddModal}
				onSearchChange={onSearchChange}
				onSearchEnter={onSearchEnter}
				searchValue={search}
			/>
			{!isLoading && centers.length === 0 ? (
				<NoDataDiv />
			) : (
				<>
					<CentersTable
						data={centers}
						showEditModalFun={showEditModalFun}
						showDeleteModalFun={showDeleteModalFun}
					></CentersTable>
					<CustomPagination
						count={pageCount}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						pageSize={pageSize}
						setPageSize={setPageSize}
					/>
					<br />
					<br />
					<br />
				</>
			)}

			{showAddCenterModal ? (
				<AddCenterModal
					closeAddModal={closeAddModal}
					open={showAddCenterModal}
					changeField={changeField}
					fieldsData={fieldsData}
					isRequesting={isRequesting}
					save={save}
					onDepartmentChange={onDepartmentChange}
					selectedDepartment={fieldsData.selectedDepartment}
					departments={departments}
				/>
			) : null}
			{showEditModal ? (
				<EditModal
					closeEditModal={closeEditModal}
					open={showEditModal}
					changeField={changeField}
					fieldsData={fieldsData}
					isRequesting={isRequesting}
					edit={edit}
					onDepartmentChange={onDepartmentChange}
					selectedDepartment={fieldsData.selectedDepartment}
					departments={departments}
				/>
			) : null}
			{alert.show ? (
				<Snackbar
					title={alert.title}
					msg={alert.msg}
					show={alert.show}
					period={6000}
					severity={alert.color}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
			{yesNoDialogDelete.open ? (
				<YesNoDialogDelete
					open={yesNoDialogDelete.open}
					title={yesNoDialogDelete.title}
					msg={yesNoDialogDelete.msg}
					onYes={onYes}
					cancel={cancel}
					isRequesting={isRequesting}
				/>
			) : null}
			<Dialog
				open={deleteLoading}
				// onClose={()=>{}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onKeyDown={handleEnteredEvent}
			>
				<DialogTitle id="alert-dialog-title">حذف المركز</DialogTitle>
				<DialogContent
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					<DialogContentText id="alert-dialog-description">
						{!deleteStatus && !deleteProcessComplete
							? 'يرجى الانتظار بينما يتم حذف جميع ارتباطات المركز'
							: deleteStatus && deleteProcessComplete
								? 'تم حذف المركز وجميع ارتباطاته بنجاح'
								: 'فشلت عملية حذف المركز'}
					</DialogContentText>
					{!deleteProcessComplete ? (
						<CircularProgress style={{ margin: '0 10px' }} />
					) : null}
				</DialogContent>
				<DialogActions>
					{!deleteStatus && deleteProcessComplete ? (
						<Button
							onClick={() => {
								deleteFunction()
							}}
							color="primary"
						>
							أعد المحاولة
						</Button>
					) : null}
					<Button
						onClick={() => {
							setDeleteLoading(false)
							setDeleteProcessComplete(false)
							setDeleteStatus(false)
							// setCenters([])
							// fetchCenters()
							setUpdateCenters(true)
						}}
						color="primary"
					>
						أغلاق
					</Button>
				</DialogActions>
			</Dialog>
		</Root>
	)
	function edit(e) {
		if (centerToDelete) {
			let errors = validate()
			if (errors.length > 0) {
				setAlert({
					show: true,
					color: 'error',
					title: 'خطأ في المدخلات',
					msg: formatErrorMgs2(errors),
				})
				return
			}
			let data = {
				name: fieldsData.name,
				descr: fieldsData.descr, //we gonna use the descr field in DB for the department manager instead.
				department_id: fieldsData.selectedDepartment.id,
				_method: 'PUT',
				with_vehicles: '0',
				order: fieldsData.order,
				manager: fieldsData.manager,
				job_title: fieldsData.job_title,
			}
			editRequest(centerToDelete, data)
		}
	}
	function editRequest(centerObj, data) {
		setIsLoading(true)
		setIsRequesting(true)
		axios({
			url: url + `/centers/` + centerObj.center.id,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				console.log('test response')
				let centersTemp = centers
				// console.log(centersTemp)
				// console.log(response.data.Data)
				centersTemp[centerObj.index] = response.data.Data
				// console.log(centersTemp)
				setCenters(centersTemp)
				setFilteredCenters(centersTemp)
				setIsLoading(false)
				setIsRequesting(false)
				setCenterToDelete(null)
				setAlert({
					show: true,
					color: 'success',
					title: 'تم التعديل بنجاح',
					msg: 'لقد تم تعديل المركز بنجاح',
				})
				setShowEditModal(false)
				setSearch('')
			})
			.catch((error) => {
				console.log('test catch')
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setAlert({
					show: true,
					color: 'error',
					title: 'هنالك خطأ ما',
					msg: errCodeObj.how_to_fix,
				})
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	function closeEditModal(e) {
		setCenterToDelete(null)
		setShowEditModal(false)
	}
	function onYes(e) {
		if (centerToDelete) {
			//deleteCenterRequest(centerToDelete.center.id);
			deleteFunction()
		}
	}
	function cancel(e) {
		setCenterToDelete(null)
		setYesNoDialogDelete({
			open: false,
			title: '',
			msg: '',
		})
	}
	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlert({
			show: false,
			title: '',
			msg: '',
			color: 'error',
		})
		setErrCode({
			status: 0,
			title: '',
			server_errors: [],
			how_to_fix: '',
		})
	}

	function onDepartmentChange(e, value) {
		setFieldsData({
			...fieldsData,
			selectedDepartment: value,
		})
	}
	function save(e) {
		console.log('sadadasdasdas====>', fieldsData)
		let errors = validate()
		if (errors.length > 0) {
			setAlert({
				show: true,
				color: 'warning',
				msg: formatErrorMgs2(errors),
				title: 'تأكد من المدخلات',
			})
			return
		}
		let data = {
			name: fieldsData.name,
			department_id: fieldsData.selectedDepartment.id,
			with_vehicles: '0',
		}
		if (fieldsData.descr && fieldsData.descr.length > 0) {
			data = {
				...data,
				descr: fieldsData.descr, //we gonna use the descr field in DB for the department manager instead.
			}
		}
		if (fieldsData.order)
			data = {
				...data,
				order: fieldsData.order,
			}

		if (fieldsData.manager)
			data = {
				...data,
				manager: fieldsData.manager,
			}

		if (fieldsData.job_title)
			data = {
				...data,
				job_title: fieldsData.job_title,
			}

		saveRequest(data)
	}

	function validate() {
		console.log('sadadasdasdas====>', fieldsData)
		let errors = []
		if (!fieldsData.name || fieldsData.name.length < 4) {
			errors.push('يجب ان لا يقل اسم المركز عن 4 حروف')
		}

		if (!fieldsData.selectedDepartment) {
			errors.push('يجب اختيار القسم اولاً')
		}
		return errors
	}

	function saveRequest(data) {
		setIsLoading(true)
		setIsRequesting(true)
		axios({
			url: url + `/centers`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let msg = 'تم اضافة ألمركز بنجاح'

				let centersList = centers
				centersList.push(response.data.Data)

				setCenters(centersList)
				setUpdateCenters(!updateCenters)
				// clear inputs
				setFieldsData({
					name: '',
					descr: '',
					selectedDepartment: departments[0],
					order: 0,
					manager: '',
					job_title: '',
				})

				setShowAddCenterModal(false)
				setSearch('')
				setIsLoading(false)
				setIsRequesting(false)
				setAlert({
					show: true,
					color: 'success',
					title: msg,
					msg: msg,
				})
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setAlert({
					show: true,
					color: 'error',
					title: 'هنالك خطأ ما',
					msg: errCodeObj.how_to_fix,
				})
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	function changeField(e) {
		setFieldsData({
			...fieldsData,
			[e.target.name]: e.target.value,
		})
	}
	function closeAddModal(e) {
		setShowAddCenterModal(false)
	}
	function openAddModal(e) {
		if (departments.length === 0) {
			setAlert({
				show: true,
				color: 'warning',
				title: ['لم يتم تحميل جميع المصادر بنجاح'],
				msg: 'يرجى اعادة تحميل الصفحة',
			})
			return
		}
		setFieldsData({
			...fieldsData,
			name: '',
			descr: '',
			order: 0,
			manager: '',
			job_title: '',
		})
		setShowAddCenterModal(true)
	}

	function onSearchChange(e) {
		setSearch(e.target.value)
	}
	function onSearchEnter(e) {
		if (e.key === 'Enter') {
			if (pageNumber == 1) fetchCenters()
			else setPageNumber(2)
		}
	}
	function showDeleteModalFun(centerObj) {
		setCenterToDelete(centerObj)
		setYesNoDialogDelete({
			open: true,
			title: 'حذف مركز',
			msg: 'يرجى الانتباه، عند حذفك لهذا المركز سيتم حذف جميع الشحنات الرئيسية والشحنات الفرعية بالإضافة إلى كافة ارتباطات هذا المركز.',
		})
	}
	function showEditModalFun(centerObj) {
		setCenterToDelete(centerObj)
		let index = departments.findIndex(function (item) {
			return item.id === centerObj.center.department_id
		})
		let selectedDepartmentTemp = null
		if (index > -1) {
			selectedDepartmentTemp = departments[index]
		}
		setFieldsData({
			name: centerObj.center.name,
			descr: centerObj.center.descr,
			selectedDepartment: selectedDepartmentTemp,
			order: centerObj.center.order ? centerObj.center.order : 0,
			manager: centerObj.center.manager ? centerObj.center.manager : '',
			job_title: centerObj.center.job_title ? centerObj.center.job_title : '',
		})
		setShowEditModal(true)
	}
	async function deleteFunction() {
		if (!centerToDelete?.center || !centerToDelete?.center?.id) {
			setAlert({
				show: true,
				color: 'warning',
				title: 'تحذير!',
				msg: 'يرجى اختيار المركز',
			})
			return
		}
		setYesNoDialogDelete({
			open: false,
			title: '',
			msg: '',
		})
		setDeleteLoading(true)
		setDeleteProcessComplete(false)

		try {
			let data = await axios({
				url: url + '/centers/' + centerToDelete?.center?.id,
				method: 'delete',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
			})
			setDeleteProcessComplete(true)
			if (data && data?.status && data?.data?.Code == 200) {
				setDeleteStatus(true)
				setCenterToDelete(null)
			} else {
				setDeleteStatus(false)
			}
		} catch (err) {
			setDeleteProcessComplete(true)
			setDeleteStatus(false)
			console.log(err?.message)
		}
	}
}

export default Centers
