import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}))

export default function CollapseItem(props) {
	const classes = useStyles()
	const [open, setOpen] = useState(false)
	return (
		<div
			style={{
				width: '100%',
				overflow: 'none',
			}}
		>
			<div
				style={{
					width: '100%',
					height: '50px',
					backgroundColor: '#007ac461',
					borderRadius: '10px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '0 20px',
					cursor: 'pointer',
					borderBottomRightRadius: open ? '0' : '10px',
					borderBottomLeftRadius: open ? '0' : '10px',
					borderTopRightRadius: '10px',
					borderTopLeftRadius: '10px',
				}}
				onClick={() => {
					setOpen(!open)
				}}
			>
				<span style={{ color: '#fff', fontWeight: 'bold' }}>{props?.title}</span>
				{open ? (
					<KeyboardArrowUpIcon style={{ color: '#fff' }} />
				) : (
					<KeyboardArrowDownIcon style={{ color: '#fff' }} />
				)}
			</div>
			{open ? (
				<div
					style={{
						width: '100%',
						height: 'fit-content',
						backgroundColor: '#fff',
						borderBottomRightRadius: '10px',
						borderBottomLeftRadius: '10px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '30px 20px',
						cursor: 'pointer',
					}}
				>
					{props?.children}
				</div>
			) : null}
		</div>
	)
}
