import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import StatisticCard from '../common/StatisticCard'
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		textAlign: 'center',
		color: theme.palette.text.secondary,
		borderRadius: '15px'
	}
}))

function StatisCards(props) {
	const classes = useStyles()
	return (
		<Fragment>
			<Grid item xs={12} container spacing={3}>
				{Object.entries(props.list).map(([key, value]) => {
					return (
						<Grid key={key} item xs={12} sm={6} md={4} lg={4} xl={2}>
							<Paper className={classes.paper}>
								<StatisticCard data={value}></StatisticCard>
							</Paper>
						</Grid>
					)
				})}
			</Grid>
		</Fragment>
	)
}

export default StatisCards
