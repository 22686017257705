import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
display:flex;
align-items:center;
justify-content:center;
`;
const Img = styled.img`
max-width:400px;
max-height:400px;
`;
function NoData(props) {
    return (
        <Root>
            <Img src={require('../../assets/images/noData.jpg')}/>
        </Root>
    )
}

export default NoData
