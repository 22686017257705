import React from 'react'

export default function NotFount() {
  return (
    <div
      style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        width:'100%',
        height:'100%'
      }}
    >
      <p
         style={{
            fontSize:'55px',
            fontWeight:'bold',
            fontFamily:'Cairo'
         }}
      >404</p>
      <span
        style={{
            fontFamily:'Cairo'
        }}
      >
        هذه الصفحة غير متاحة
      </span>

    </div>
  )
}
