import React, {
	Fragment,
	PureComponent,
	Component,
	useEffect,
	useState,
	useLayoutEffect,
	useRef,
} from 'react'
import MaterialTable from 'material-table'
import { ThemeProvider } from '@material-ui/styles'
import { create } from 'jss'
import {
	StylesProvider,
	jssPreset,
	createMuiTheme,
} from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import IconButton from '@material-ui/core/IconButton'
import SubFreightsTable from './SubFreightsTableNew'
import {
	getGivenItemsJson,
	setFilterdata,
	setFilterSearch,
} from '../../common/ApiHelper'
import axios from 'axios'
import Cookies from 'js-cookie'
// import $ from 'jquery';

import { handleError, getBaseURL } from '../../common/Utils'
import { render } from '@testing-library/react'
import CustomPagination from '../../common/CustomPagination'
import { debounce } from 'debounce'
import { Box, TextField } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
const url = getBaseURL()

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

class FreightsTableNew extends PureComponent {

	constructor(props) {
		super(props)
		this.state = {
			search_data: '',
			data_from: null,
			data_to: null,
			checked_box: [null],
			idALlcehckbox: [null],
			title_filter: [
				'numbering',
				'day_date',
				'plate_number',
				'driver',
				'department',
				'center',
				'shift',
				'is_present',
				'beg_time',
				'eng_time',
				'trip_beg_location',
				'trip_beg_location',
				'freights_count',
				'weight',
				'engine_hours',
				'working_hours',
				'creator',
				'absence_reason',
				'status',
			],
			disabel_column: false,
			filter_text: ' أنتظر قليلا لتحميل البيانات',
			pageSize: this.props.pageSize,
		}
		this.findDriverFromList = this.findDriverFromList.bind(this)
		this.checktheclickcheckbox = this.checktheclickcheckbox.bind(this)
		this.setsearchChange = this.setsearchChange.bind(this)
		this.restbuttonClicked = this.restbuttonClicked.bind(this)
		this.formatAMPM = this.formatAMPM.bind(this)
	}



	async componentWillMount() {
		// get search  filter here.
		let data_search = {
			items: ['filter_daily_activity_search_get'],
		}
		let result_search = await getGivenItemsJson(data_search)
		let geted_searched_data = result_search.data.Data.returned_items
			.filter_daily_activity_search_get
			? result_search.data.Data.returned_items.filter_daily_activity_search_get
					.search_text
			: ''
		localStorage.setItem('search_data', geted_searched_data)
	}

	async componentDidMount() {
		console.log('this.props.datathis.props.data==>',this.props.data)
		console.log(this.state.title_filter.length)

		// get checkbox of columns filter here.
		let data = {
			items: ['filter_daily_activity_get'],
		}
		let result = await getGivenItemsJson(data)
		let geted_data = result.data.Data.returned_items.filter_daily_activity_get
			? result.data.Data.returned_items.filter_daily_activity_get.filter
			: []

		let data_search = {
			items: ['filter_daily_activity_search_get'],
		}
		let result_search = await getGivenItemsJson(data_search)
		let geted_searched_data = result_search.data.Data.returned_items
			.filter_daily_activity_search_get
			? result_search.data.Data.returned_items.filter_daily_activity_search_get
					.search_text
			: ''

		localStorage.setItem('search_data', geted_searched_data)

		//initialize localstorage with data of colums.
		for (let i = 0; i < 19; i++) {
			let message = this.state.title_filter[i]
			if (!localStorage.getItem(message)) {
				localStorage.setItem(message, false)
			}
		}

		//get data from database after initilazed the localstorage.

		if (geted_data.length > 0) {
			for (let i = 0; i < 19; i++) {
				let message = this.state.title_filter[i]
				if (message === geted_data[i].title) {
					localStorage.setItem(message, geted_data[i].boolean)
				}

				if (i == 18) {
					this.setState({
						...this.state,
						disabel_column: true,
						filter_text: 'Add or remove columns',
					})
				}
			}
		} else {
			this.setState({
				...this.state,
				disabel_column: true,
				filter_text: 'Add or remove columns',
			})
		}

		let arr_data = []
		for (let i = 0; i < 19; i++) {
			let message = this.state.title_filter[i]
			if (localStorage.getItem(message) === 'true') {
				arr_data.push(true)
			} else {
				arr_data.push(false)
			}
		}
		this.setState({
			...this.state,
			checked_box: arr_data,
		})
	}

	componentDidUpdate(prevProps) {
		console.log('this.props.datathis.props.data==>',this.props.data)

		let restsearch = document.querySelector('.MuiIconButton-root-326')
		console.log('clicked')
		if (restsearch) {
			restsearch.addEventListener('click', () => {
				this.restbuttonClicked()
			})
		}

		if (prevProps?.pageSize != this.props.pageSize) {
			console.log('pageSize==>changedddd ', this.props.pageSize)
			this.setState({
				...this.state,
				pageSize: this.props.pageSize,
			})
		}
	}

	//set column changed here;
	async checktheclickcheckbox(column, hidden) {
		let data = []

		for (let i = 0; i < 19; i++) {
			if (this.state.title_filter[i] === column.field) {
				this.state.checked_box[i] = hidden
				let message = this.state.title_filter[i]
				if (hidden == true) localStorage.setItem(message, true)
				else localStorage.setItem(message, false)
			}
		}

		for (let j = 0; j < 19; j++) {
			let message = this.state.title_filter[j]
			data.push({
				title: message,
				boolean: localStorage.getItem(message),
			})
		}
		// console.log(data);

		let result = await setFilterdata('filter/freights', data)
	}

	//set search changed here.
	async setsearchChange(text) {
		if (text) {
			localStorage.setItem('search_data', text)

			let data = {
				search_text: text,
			}

			let result = await setFilterSearch('filter/freights/search', data)
		} else {
			this.restbuttonClicked()
		}
	}

	async restbuttonClicked() {
		this.props.setSearchFieldFilter('')
		localStorage.removeItem('search_data')

		let data = {
			search_text: '',
		}

		let result = await setFilterSearch('filter/freights/search', '')
		if (result && result.data.status == 200) {
			this.props.alertRestsearchhere()
		}
	}
	findDriverFromList(drivers, term) {
		let isFound = false
		for (let i = 0; i < drivers.length; i++) {
			isFound |= drivers[i].name.toLowerCase().includes(term)
		}
		return isFound
	}
	convertArrayToObject(array, key) {
		const initialValue = {}
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item.name,
			}
		}, initialValue)
	}

	formatVehicleName(rowData) {
		let pn = rowData.vehicle.plate_number
		let brand = rowData.vehicle.brand ? rowData.vehicle.brand : ''
		pn = pn + (brand ? ' - ' + brand : '')
		return pn
	}

	formatAMPM(time, day_date) {
		let date = new Date(`${day_date} ${time}`)
		let hours = date.getHours()
		let minutes = date.getMinutes()
		const ampm = hours >= 12 ? 'pm' : 'am'

		hours %= 12
		hours = hours || 12
		minutes = minutes < 10 ? `0${minutes}` : minutes

		const strTime = `${hours}:${minutes} ${ampm}`

		return strTime
	}

	render() {
		console.log('pageSizepageSize==>', this.state.pageSize)

		return (
			<ThemeProvider theme={theme}>
				<StylesProvider jss={jss}>
					<div style={{ maxWidth: '100%' }} id="dayActivitytable">
						<MaterialTable
							key={this.props.data?.length}
							onChangeColumnHidden={(column, hidden) => {
								console.log(column)
								this.checktheclickcheckbox(column, hidden)
							}}
							onSearchChange={(e) => {
								this.setsearchChange(e)
							}}
							columns={[
								{
									title: '#',
									field: 'numbering',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									hidden: this.state.checked_box[0],
									// filtering: false
									// onClick:setclicleddata(0)
								},
								{
									title: 'اليوم',
									field: 'day_date',
									truncateSyncdefaultGroupOrder: 0,
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									hidden: this.state.checked_box[1],
									// filtering: false,

									customFilterAndSearch: (term, rowData) => {
										console.log('SEARCHHERE===>', term)
										this.props.setSearchFieldFilter(term)
										// return rowData.vehicle
										// 	? rowData.vehicle.plate_number
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
								},
								{
									title: 'الالية ونوعها',
									field: 'plate_number',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									hidden: this.state.checked_box[2],
									// filtering: false,

									render: (rowData) =>
										rowData.vehicle
											? this.formatVehicleName(rowData)
											: ' ---- ',
									customFilterAndSearch: (term, rowData) => {
										console.log('SEARCHHERE===>', term)
										this.props.setSearchFieldFilter(term)
										// return rowData.vehicle
										// 	? rowData.vehicle.plate_number
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
								},
								{
									title: 'السائق',
									field: 'driver',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData.driver ? rowData.driver.name : 'لا يوجد',
									customFilterAndSearch: (term, rowData) => {
										this.props.setSearchFieldFilter(term)
										// return rowData.driver
										// 	? rowData.driver.name
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[3],
								},
								{
									title: 'القسم',
									field: 'department',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData.center ? rowData.center.department.name : '----',
									customFilterAndSearch: (term, rowData) => {
										this.props.setSearchFieldFilter(term)
										// return rowData.center
										// 	? rowData.center.department.name
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[4],
								},
								{
									title: 'المركز',
									field: 'center',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData.center ? rowData.center.name : '----',
									customFilterAndSearch: (term, rowData) => {
										this.props.setSearchFieldFilter(term)
										// return rowData.center
										// 	? rowData.center.name
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[5],
								},
								{
									title: 'الوجبة',
									field: 'shift',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData.shift ? rowData.shift.name : '----',
									customFilterAndSearch: (term, rowData) => {
										this.props.setSearchFieldFilter(term)
										// return rowData.shift
										// 	? rowData.shift.name
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[6],
								},
								{
									title: 'ألحضور',
									field: 'is_present',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData.is_present === '1' ? (
											<IconButton
											// onClick={() => this.props.changeStatus(rowData)}
											>
												<AssignmentTurnedInIcon style={{ color: 'green' }} />
											</IconButton>
										) : (
											<NotInterestedIcon style={{ color: 'red' }} />
										),
									hidden: this.state.checked_box[7],
								},
								{
									title: 'وقت الانطلاق',
									field: 'beg_time',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData?.beg_time
											? this.formatAMPM(rowData?.beg_time, rowData?.day_date)
											: '*',
									hidden: this.state.checked_box[8],
								},
								{
									title: 'وقت العودة',
									field: 'eng_time',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData?.end_time
											? this.formatAMPM(rowData.end_time, rowData?.day_date)
											: '*',
									hidden: this.state.checked_box[9],
								},
								{
									title: 'موقع الانطلاق',
									field: 'trip_beg_location',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData.trip_beg_location ? rowData.trip_beg_location : '*',
									customFilterAndSearch: (term, rowData) => {
										this.props.setSearchFieldFilter(term)
										// return rowData.trip_beg_location
										// 	? rowData.trip_beg_location
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[10],
								},
								{
									title: 'مجموع التوقفات',
									field: 'trip_beg_location',
									hiddenByColumnsButton: true,
									removable: this.state.disabel_column,
									// filtering: false,

									render: (rowData) =>
										rowData.total_parking_time
											? rowData.total_parking_time
											: '*',
									customFilterAndSearch: (term, rowData) => {
										console.log('SEARCHHERE===>', term)
										this.props.setSearchFieldFilter(term)
										// return rowData.vehicle
										// 	? rowData.vehicle.plate_number
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[11],
								},
								{
									title: 'عدد الشحنات',
									field: 'freights_count',
									hiddenByColumnsButton: true,
									// filtering: false,

									render: (rowData) =>
										rowData.sub_freights ? rowData.sub_freights.length : '*',
									hidden: this.state.checked_box[12],
									removable: this.state.disabel_column,
								},
								{
									title: 'الوزن الكلي',
									field: 'weight',
									hiddenByColumnsButton: true,
									// filtering: false,

									render: (rowData) => (rowData.weight ? rowData.weight : '0'),
									hidden: this.state.checked_box[13],
									removable: this.state.disabel_column,
									customFilterAndSearch: (term, rowData) => {
										console.log('SEARCHHERE===>', term)
										this.props.setSearchFieldFilter(term)
										// return rowData.vehicle
										// 	? rowData.vehicle.plate_number
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
								},
								{
									title: 'المحرك',
									field: 'engine_hours',
									hiddenByColumnsButton: true,
									// filtering: false,

									render: (rowData) =>
										rowData.engine_hours ? rowData.engine_hours : '*',
									hidden: this.state.checked_box[14],
									removable: this.state.disabel_column,
									customFilterAndSearch: (term, rowData) => {
										console.log('SEARCHHERE===>', term)
										this.props.setSearchFieldFilter(term)
										// return rowData.vehicle
										// 	? rowData.vehicle.plate_number
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
								},
								{
									title: 'الحركة',
									field: 'working_hours',
									hiddenByColumnsButton: true,
									// filtering: false,

									render: (rowData) =>
										rowData.working_hours ? rowData.working_hours : '*',
									hidden: this.state.checked_box[15],
									removable: this.state.disabel_column,
									customFilterAndSearch: (term, rowData) => {
										console.log('SEARCHHERE===>', term)
										this.props.setSearchFieldFilter(term)
										// return rowData.vehicle
										// 	? rowData.vehicle.plate_number
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
								},
								{
									title: 'المستخدم',
									field: 'creator',
									hiddenByColumnsButton: true,
									// filtering: false,

									render: (rowData) => (rowData.user ? rowData.user.name : '*'),
									customFilterAndSearch: (term, rowData) => {
										this.props.setSearchFieldFilter(term)

										// return rowData.user
										// 	? rowData.user.name
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[16],
									removable: this.state.disabel_column,
								},
								{
									title: 'سبب الغياب',
									field: 'absence_reason',
									hiddenByColumnsButton: true,
									// filtering: false,

									render: (rowData) =>
										rowData.absence_reason ? rowData.absence_reason.name : '*',
									customFilterAndSearch: (term, rowData) => {
										this.props.setSearchFieldFilter(term)

										// return rowData.absence_reason
										// 	? rowData.absence_reason.name
										// 			.toLowerCase()
										// 			.includes(term.toLowerCase())
										// 	: false
									},
									hidden: this.state.checked_box[17],
									removable: this.state.disabel_column,
								},
								{
									title: 'حالة السجل',
									field: 'status',
									hiddenByColumnsButton: true,
									// filtering: false,

									render: (rowData) =>
										rowData.status === '1' ? (
											<IconButton
												onClick={() => this.props.changeStatus(rowData)}
											>
												<AssignmentTurnedInIcon style={{ color: 'green' }} />
											</IconButton>
										) : (
											<IconButton
												onClick={() => this.props.changeStatus(rowData)}
											>
												<NotInterestedIcon style={{ color: 'red' }} />
											</IconButton>
										),
									hidden: this.state.checked_box[18],
									removable: this.state.disabel_column,
								},
							]}
							actions={[
								{
									icon: 'delete',
									tooltip: 'حذف سجل يومي',
									onClick: (event, rowData) =>
										this.props.deleteFreight(rowData),
								},
								{
									icon: 'edit',
									tooltip: 'تعديل السجل اليومي',
									onClick: (event, rowData) =>
										this.props.showEditModalFunc(rowData),
								},
								{
									icon: 'add',
									tooltip: 'اضافة شحنة',
									onClick: (event, rowData) =>
										this.props.addNewSubFreight(rowData),
								},
							]}
							options={{
								onTableChange: (action, tablestate) => {
									console.log('action', action)
								},
								toolbar: true,
								columnsButton: true,
								maxBodyHeight: 'auto',
								grouping: true,
								actionsColumnIndex: 17,
								pageSize: this.state.pageSize,
								pageSizeOptions: [5, 10, 20, 50, 100],
								sorting: true,
								filtering: false,
								search: false,
								// searchText:
								// 	localStorage.getItem('search_data') &&
								// 	localStorage.getItem('search_data') !== null &&
								// 	localStorage.getItem('search_data') !== '' &&
								// 	localStorage.getItem('search_data') !== 'null'
								// 		? localStorage.getItem('search_data')
								// 		: null,
								rowStyle: (rowData) => ({
									backgroundColor:
										rowData.is_present == '1' ? '#fff' : '#ff0000',
									color: rowData.is_present == '1' ? '#000' : '#fff',
								}),
							}}
							title={this.props.title}
							data={this.props.data ? this.props.data : []}
							detailPanel={(rowData) => {
								return (
									<Fragment>
										{rowData.sub_freights && rowData.sub_freights.length > 0 ? (
											<SubFreightsTable
												deleteSubFreight={this.props.deleteSubFreight}
												editSubFreight={this.props.editSubFreight}
												data={rowData.sub_freights}
											></SubFreightsTable>
										) : (
											<p>لا توجد شحنات</p>
										)}
									</Fragment>
								)
							}}
							localization={{
								grouping: {
									groupedBy: 'الفرز حسب',
								},
								header: {
									actions: 'الاجراءات',
								},
								toolbar: {
									addRemoveColumns: this.state.filter_text
										? this.state.filter_text
										: '',
									searchPlaceholder: 'search here',
								},
								pagination: {
									labelDisplayedRows: '{count}',
								},
							}}
							// totalCount={this.props?.totalRows}
							// onChangePage={(page) => this.props?.fetchData(page + 1, 10)} // Fetch data when the page changes
							// onChangeRowsPerPage={(pageSize) =>
							// 	this.props?.fetchData(1, pageSize)
							// }
							components={{
								Pagination: (props) => (
									<CustomPagination
										count={this.props?.pageCount}
										pageNumber={this.props.pageNumber}
										setPageNumber={(value) => {
											if (this.props?.pageNumber != value)
												this.props.handlePageNumberOrPageSizeChgange(value, 1)
										}}
										noBoarded={true}
										onPageSize={false}
										pages={[5, 10, 20, 50, 100]}
										pageSize={this.props?.pageSize}
										setPageSize={(value) => {
											if (this.props?.pageSize != value)
												this.props.handlePageNumberOrPageSizeChgange(value, 2)
										}}
									/>
								),
							}}
						></MaterialTable>
						<br />
						<br />
						<br />
					</div>
				</StylesProvider>
			</ThemeProvider>
		)
	}
}

export default FreightsTableNew


