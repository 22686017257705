import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers'

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import rtl from 'jss-rtl';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createMuiTheme({
    direction: 'rtl',
    typography: {
        fontFamily: [
            'Cairo',
            'Arial',
            'sans-serif'
        ].join(','),
    },

});

;

export default function MaterialUIPickers(props) {


  return (
    <ThemeProvider theme={theme}>
    <StylesProvider jss={jss}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
  
        {
          props.withSeconds && props.withSeconds?
          <KeyboardTimePicker
          ampm={props?.ampm}
          margin="normal"
          id={"time-picker"+props?.title}
          label={props?.title}
          value={props?.selectedDate}
          onChange={(date)=>{
            console.log("selected date 1: " , date);
            props.handleDateChange(date)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          views={["hours", "minutes", "seconds"]}
          format="HH:mm:ss"
        />
          :
          <KeyboardTimePicker
          ampm={props?.ampm}
          margin="normal"
          id={"time-picker"+props?.title}
          label={props?.title}
          value={props?.selectedDate}
          onChange={(date)=>{
            console.log(props?.selectedDate+" selected date "+props?.title+"-"+props?.ampm+": " , date);
            props.handleDateChange(date)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          
        />
          
        }
        
       
    </MuiPickersUtilsProvider>
    </StylesProvider>
    </ThemeProvider>
  );
}
