import React, { Fragment } from 'react'
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import TimerIcon from '@material-ui/icons/Timer'
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '170px',
		minHeight: '170px',
	},
	paper: {
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

const RightSide = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 40px;
	background-color: #007ac4;
`
const SpanTitle = styled.span`
	color: white;
`
const Circle = styled.div`
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`

function StatisticCard(props) {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<RightSide>
				<SpanTitle>{props.data.title}</SpanTitle>
				{/* <TimerIcon fontSize="large"></TimerIcon> */}
			</RightSide>

			<div
				style={{
					height: '130px',
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Circle>
					<span
						style={{
							color: '#000',
							fontSize: '1.5rem',
						}}
					>
						{props.data.count}
					</span>
				</Circle>
			</div>
		</div>
	)
}

export default StatisticCard
