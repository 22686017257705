import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import FaceIcon from '@material-ui/icons/Face'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import Skeleton from '@material-ui/lab/Skeleton'
import { Redirect, NavLink, useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
		zIndex: '1000',
	},
}))
const severities = ['error', 'warning', 'info', 'success']

const StatRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 280px;
	margin-right: 5px;
`
const AlertContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px;
	margin: 0px;
`
export default function SimpleAlerts(props) {
	const classes = useStyles()
	const history = useHistory()

	return (
		<div className={classes.root}>
			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography
						style={{
							minWidth: '280px',
						}}
						className={classes.heading}
					>
						{/* {' '}
						عدد القيود المدخلة: {`${props.total}`} */}
						ادخالات المستخدمين
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<AlertContainer>
						<Alert icon={<PeopleAltIcon />} severity="warning">
							<StatRow>
								<p
									style={{
										width: '120px',
										fontWeight: 'bold',
									}}
								>
									اسم المستخدم
								</p>
								<p
									style={{
										fontWeight: 'bold',
									}}
								>
									المطلوب
								</p>
								<p
									style={{
										fontWeight: 'bold',
									}}
								>
									المُدخل
								</p>
							</StatRow>
						</Alert>

						{props.usersData.map(function (item, index) {
							let is_active = false
							props.usersInfo.map((user) => {
								if (
									item.id === user.id &&
									user.is_active === '1' &&
									user.deleted_at === null
								) {
									is_active = 1
								}
							})
							if (is_active)
								return (
									<Alert
										key={index}
										style={{
											margin: '3px',
										}}
										icon={<FaceIcon />}
										severity={
											severities[Math.floor(Math.random() * severities.length)]
										}
									>
										{props.isStatsLoading ? (
											<Skeleton variant="rect" width={120} />
										) : (
											<StatRow
												key={index}
												onClick={redirecttospecificpage.bind(this, item.name)}
												style={{ cursor: 'pointer' }}
											>
												<p
													style={{
														width: '120px',
													}}
												>
													{item.name}
												</p>
												<p>{item.required}</p>
												<p>{item.entered}</p>
											</StatRow>
										)}
									</Alert>
								)
						})}
					</AlertContainer>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	)
	function redirecttospecificpage(name) {
		localStorage.setItem('searchNotCompleteFreightsd', name)
		history.push('/VehiclesNotAllow')
	}
}
