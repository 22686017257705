import React, { useState, useEffect } from 'react'
import Root from '../../common/BodyMRoot'
import CrudControl from '../../common/CrudControl'
import Loading from '../../common/Loading'
import HandleError from '../../common/HandleError'
import NoDataDiv from '../../common/NoDataDiv'
import Table from './DriversTable'
import axios from 'axios'
import {
	handleErr,
	handleError,
	getStatusCode,
	getBaseURL,
} from '../../common/Utils'
import Cookies from 'js-cookie'
import AddDriverModal from './AddDriverModal'
import Snackbar from '../../common/SnackBar'
import EditDriverModal from './EditDriverModal'
import AssignDriverToVehicleModal from './AssignDriverToVehicle'
import { getGivenItems } from '../../common/ApiHelper'
import ShowVehiclesModal from './ShowVehiclesModal'
import BackDropLoading from '../../common/BackDropLoading'
import CustomPagination from '../../common/CustomPagination'

const url = getBaseURL()

function Drivers(props) {
	const [drivers, setDrivers] = useState([])

	const [driverFormData, setDriverFormData] = useState({
		name: '',
		phone: '',
		notes: '',
	})
	const [driverEditFormData, setDriverEditFormData] = useState({
		name: '',
		phone: '',
		notes: '',
	})
	const [errCode, setErrCode] = useState({
		status: 0,
		title: '',
		server_errors: '',
		how_to_fix: '',
	})
	const [errors, setErrors] = useState([])
	const [search, setSearch] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [filteredDrivers, setFilteredDrivers] = useState([])
	const [showBoundingLogModal, setShowBoundingLogModal] = useState(false)
	const [driverToShow, setDriverToShow] = useState(null)
	const [updateDrivers, setUpdateDrivers] = useState(false)
	const [showAddDriverModal, setShowAddDriverModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showAssignDriverModal, setShowAssignDriverModal] = useState(false)
	// alert errors
	const [alertError, setAlertError] = useState(false)
	const [alertErrorMsg, setAlertErrorMsg] = useState([])
	const [alertColor, setAlertColor] = useState('error')
	// fors errors
	const [formErrors, setFormErrors] = useState([])

	// for assign
	const [vehicles, setVehicles] = useState([])
	const [selectedVehicle, setSelectedVehicle] = useState(null)

	const [centers, setCenters] = useState([])
	const [selectedCenter, setSelectedCenter] = useState(null)

	const [shifts, setShifts] = useState([])
	const [selectedShift, setSelectedShift] = useState(null)
	const [shiftsData, setShiftsData] = useState([])
	const [filteredShifts, setFilteredShifts] = useState([])

	// for request process progress bar
	const [isRequesting, setIsRequesting] = useState(false)
	const [selectedDriver, setSelectedDriver] = useState(null)

	const [showVehiclesModal, setShowVehiclesModal] = useState(false)
	const [vehiclesToShow, setVehiclesToShow] = useState([])

	const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	const [pageSize, setPageSize] = useState(5)
	const [pageNumber, setPageNumber] = useState(1)
	const [totalRows, setTotalRows] = useState(0)
	const [pageCount, setPageCount] = useState(0)

	if (
		userData.userInfo.role.name !== 'Admin' &&
		userData.userInfo.role.name !== 'Root'
	)
		window.location.href = '/'

	const fetchDrivers = async () => {
		setIsLoading(true)
		setErrors([])
		let result = null
		try {
			result = await axios({
				url: url + `/drivers?filter_flag=b`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData.token,
				},
				params:{
					page:pageNumber,
					page_size:pageSize,
					search:search?search?.toLowerCase():null
				}
			})

			setFilteredDrivers(result?.data?.Data?.data)
			setDrivers(result?.data?.Data?.data)
			setPageCount(result?.data?.Data?.last_page)
			setTotalRows(result?.data?.Data?.total)
		} catch (error) {
			let errors = handleError(error)
			if (errors === 401) {
				window.location.href = '/'
				Cookies.remove('SDIMS_USER_DATA')
			}

			setErrors([errors])
		}
		setIsLoading(false)
	}

	const fetchResources = async () => {
		let result = await getGivenItems(['shifts', 'vehicles', 'centers'])

		let arrays = result.data.Data.returned_items

		// setShifts(arrays.shifts)
		// setCenters(arrays.centers)
		setVehicles(arrays.vehicles)
		// setSelectedShift(
		// 	arrays.vehicles.length > 0 ? arrays.vehicles[0].shifts[0] : null
		// )
		// setShifts(arrays.shifts.length > 0 ? arrays.shifts : null)
		// setFilteredShifts(
		// 	arrays.shifts.filter(function (objFromA) {
		// 		return arrays.vehicles[0].shifts.find(function (objFromB) {
		// 			return objFromA.id === objFromB.shift_id
		// 		})
		// 	})
		// )
		setSelectedVehicle(arrays.vehicles.length > 0 ? arrays.vehicles[0] : null)
		// setSelectedCenter(arrays.centers.length > 0 ? arrays.centers[0] : null)
	}
	useEffect(() => {
		fetchDrivers()
		fetchResources()
	}, [updateDrivers])
	useEffect(()=>{
		fetchDrivers()
	},[pageSize,pageNumber])

	useEffect(()=>{
      if(selectedVehicle&&selectedVehicle?.id){
		getChvsData(selectedVehicle?.id)
	  }
	},[selectedVehicle])
	const getChvsData=async (id)=>{
		let data=null;
		try{
			data=await axios({
				url:url+'/chvs',
				method:'get',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
				params:{
					vehicle_id:id,
				}
			})
			if(data&&data?.status){
				let arr = []
				let arr2=[]
				if (data.data) {
					data.data.map((item) => {
						if (item?.center) arr.push(item?.center)
						if (item?.shift) arr2.push(item?.shift)
					})
				}
				
				const uniqueArray = arr.filter(
					(object, index, self) =>
						index ===
						self.findIndex(
							(o) => o.id === object.id
						)
				)
				const uniqueArray2 = arr2.filter(
					(object, index, self) =>
						index ===
						self.findIndex(
							(o) => o.id === object.id
						)
				)
				setCenters(uniqueArray)
				setShifts(uniqueArray2)
				setFilteredShifts(uniqueArray2)
				setSelectedCenter(uniqueArray&&uniqueArray?.length>0?uniqueArray[0]:null)
				setSelectedShift(uniqueArray2&&uniqueArray2?.length>0?uniqueArray2[0]:null)

				// props.setListOfChvs(uniqueArray)
				// props.setListOfChvsShift(uniqueArray2)

			}
		}catch(error){
           console.log(error?.message)
		}
	}

	return (
		<Root>
			{isLoading ? <BackDropLoading open={isLoading} /> : null}

			<CrudControl
				showSearch={true}
				openModal={openAddDriverModal}
				onSearchChange={onSearchChange}
				onSearchEnter={onSearchEnter}
				searchValue={search}
			/>
			{!isLoading && errors && errors.length > 0 ? (
				<HandleError errors={errors} />
			) : !isLoading && drivers.length === 0 ? (
				<NoDataDiv />
			) : (
				<>
					<Table
						data={filteredDrivers}
						openBoundingLogModal={openBoundingLogModal}
						showEditModalFun={showEditModalFun}
						assignDriverToVehicle={assignDriverToVehicle}
						unbindDriverFromVehicle={unbindDriverFromVehicle}
						showBoundedVehicles={showBoundedVehicles}
						pageNumber={pageNumber}
						pageSize={pageSize}
					></Table>
					<CustomPagination
						count={pageCount}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						pageSize={pageSize}
						setPageSize={setPageSize}
					/>
					<br/>
					<br/>
					<br/>

				</>
			)}
			{showAddDriverModal ? (
				<AddDriverModal
					show={showAddDriverModal}
					hideAddDriverModal={hideAddDriverModal}
					create={create}
					changeField={changeField}
					driverData={driverFormData}
					formErrors={formErrors}
					isLoading={isLoading}
				></AddDriverModal>
			) : null}
			{showEditModal ? (
				<EditDriverModal
					show={showEditModal}
					hideEditDriverModal={hideEditDriverModal}
					edit={edit}
					changeField={changeFieldForEdit}
					driverData={driverEditFormData}
					formErrors={formErrors}
					isLoading={isLoading}
				></EditDriverModal>
			) : null}
			{showAssignDriverModal ? (
				<AssignDriverToVehicleModal
					show={showAssignDriverModal}
					closeAssignModal={closeAssignModal}
					selectedVehicle={selectedVehicle}
					selectedCenter={selectedCenter}
					selectedShift={selectedShift}
					selectedDriver={selectedDriver}
					shiftsData={shiftsData}
					vehicles={vehicles}
					centers={centers}
					shifts={filteredShifts}
					onVehicleChange={onVehicleChange}
					onCenterChange={onCenterChange}
					onShiftChange={onShiftChange}
					assign={assign}
					isRequesting={isRequesting}
				></AssignDriverToVehicleModal>
			) : null}

			{alertError ? (
				<Snackbar
					title="خطأ"
					msg={alertErrorMsg}
					show={alertError}
					period={6000}
					severity={alertColor}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
			{errCode && errCode.status !== 0 ? (
				<Snackbar
					title={errCode.title}
					msg={errCode.server_errors}
					show={true}
					period="6000"
					severity="error"
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
			{showVehiclesModal ? (
				<ShowVehiclesModal
					open={true}
					data={vehiclesToShow}
					closeShowVehiclesModal={closeShowVehiclesModal}
					unbindDriverFromVehicle={unbindDriverFromVehicle}
				></ShowVehiclesModal>
			) : null}
		</Root>
	)

	function unbindDriverFromVehicle(driverObject) {
		let data = new FormData()
		data.append('driver_id', driverObject.driver.id)
		data.append('shift_id', driverObject.driver.current_bounding[0].shift.id)
		unbindRequest(data)
	}

	// unbind request
	function unbindRequest(data) {
		setIsRequesting(true)

		axios({
			url: url + `/drivers/unbind`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				setAlertError(true)
				let msg = 'تم الغاء اسناد السائق بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '

					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors.push(response.data.Error.details[i])
					}
				}

				setAlertErrorMsg(msg)
				setAlertColor('success')

				let driversList = drivers
				driversList.push(response.data.Data)

				setDrivers(driversList)
				setUpdateDrivers(!updateDrivers)
				// reset inputs
				setSelectedCenter(centers[0])
				setSelectedVehicle(vehicles[0])
				setFilteredShifts(
					shifts.filter(function (objFromA) {
						return vehicles[0].shifts.find(function (objFromB) {
							return objFromA.id === objFromB.shift_id
						})
					})
				)
				setSelectedShift(vehicles[0].shifts[0])

				setShowAssignDriverModal(false)
				setSearch('')
				setIsRequesting(false)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setErrCode(errCodeObj)
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	function closeShowVehiclesModal() {
		setShowVehiclesModal(false)
		setVehiclesToShow([])
	}
	function showBoundedVehicles(driverObj) {
		setShowVehiclesModal(true)
		setVehiclesToShow(driverObj)
	}

	// assign actions
	function onShiftChange(e, value) {
		setSelectedShift(value)
	}
	function onCenterChange(e, value) {
		setSelectedCenter(value)
	}
	function onVehicleChange(e, value) {
		setSelectedVehicle(value)
		setFilteredShifts(
			shifts.filter(function (objFromA) {
				return value.shifts.find(function (objFromB) {
					return objFromA.id === objFromB.shift_id
				})
			})
		)
		setSelectedShift(filteredShifts[0])
	}

	function closeAssignModal() {
		setFormErrors([])
		setShowAssignDriverModal(false)
	}

	function assign() {
		let result = validateAssign()
		if (result.length === 0) {
			let data = new FormData()
			data.append('vehicle_id', selectedVehicle.id)
			data.append('center_id', selectedCenter.id)
			data.append('shift_id', selectedShift.id)
			data.append('driver_id', selectedDriver.driver.id)

			// now make the request

			assignRequest(data)
		} else {
			setFormErrors(result)
		}
	}
	function validateAssign() {
		let errors = []
		if (
			!selectedCenter ||
			!selectedDriver ||
			!selectedShift ||
			!selectedVehicle
		) {
			errors.push('يرجى التأكد من المدخلات!')
		}

		return errors
	}

	function assignRequest(data) {
		setIsRequesting(true)

		axios({
			url: url + `/drivers/bind`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				setAlertError(true)
				let msg = 'تم اسناد السائق بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '

					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors.push(response.data.Error.details[i])
					}
				}

				setAlertErrorMsg(msg)
				setAlertColor('success')

				let driversList = drivers
				driversList.push(response.data.Data)

				setDrivers(driversList)
				setUpdateDrivers(!updateDrivers)
				// reset inputs
				setSelectedCenter(centers[0])
				setSelectedVehicle(vehicles[0])
				setFilteredShifts(
					shifts.filter(function (objFromA) {
						return vehicles[0].shifts.find(function (objFromB) {
							return objFromA.id === objFromB.shift_id
						})
					})
				)
				setSelectedShift(vehicles[0].shifts[0])

				setShowAssignDriverModal(false)
				setSearch('')
				setIsRequesting(false)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setErrCode(errCodeObj)
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	// edit actions
	function showEditModalFun(driverObj) {
		setDriverEditFormData({
			...driverEditFormData,
			index: driverObj.index,
			id: driverObj.driver.id,
			name: driverObj.driver.name,
			phone: driverObj.driver.phone || '',
			notes: driverObj.driver.notes || '',
		})

		setShowEditModal(true)
	}
	function assignDriverToVehicle(driverObject) {
		if (!vehicles || !centers || !shifts) {
			setAlertError(true)
			setAlertErrorMsg('لم يتم تحميل المصادر بشكل كامل')
			setAlertColor('warning')
			return
		}
		setShiftsData(
			driverObject.driver.current_bounding.map((item) => item.shift)
		)
		setSelectedDriver(driverObject) //
		setSelectedShift(filteredShifts[0])
		setFormErrors([])
		setTimeout(() => {
			setShowAssignDriverModal(true)
		}, 1200)
	}

	function hideEditDriverModal(e) {
		setShowEditModal(false)
		setFormErrors([])
	}
	function edit() {
		let result = validateEdit()

		if (result.length === 0) {
			let data = {
				name: driverEditFormData.name,
				_method: 'PUT',
			}

			if (driverEditFormData.phone.length >= 0) {
				data = {
					...data,
					phone: driverEditFormData.phone,
				}
			}
			if (driverEditFormData.notes.length >= 0) {
				data = {
					...data,
					notes: driverEditFormData.notes,
				}
			}

			editDriverRequest(driverEditFormData, data)
		} else {
			setIsLoading(false)
			setFormErrors(result)
		}
	}
	function validateEdit() {
		let errors = []

		if (!driverEditFormData.name) errors.push('يرجى كتابة اسم السائق الثلاثي')

		if (
			driverEditFormData.phone.length > 0 &&
			(driverEditFormData.phone.length < 4 ||
				!driverEditFormData.phone.startsWith('964'))
		) {
			errors.push('يرجى كتابة رقم الهاتف بصورة صحيحة')
		}
		if (
			driverEditFormData.notes.length > 0 &&
			driverEditFormData.notes.length < 4
		) {
			errors.push('يجب ان تقوم بكتابة ملاحظات باحرف لا تقل عن 4')
		}

		return errors
	}
	// edit request
	function editDriverRequest(driverObj, data) {
		setIsLoading(true)

		setFormErrors([])
		axios({
			url: url + `/drivers/` + driverObj.id,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let driversList = drivers
				driversList[driverObj.index] = response.data.Data
				setDrivers(driversList)
				setUpdateDrivers(!updateDrivers)
				setDriverEditFormData({
					...driverEditFormData,
					id: null,
					name: '',
					phone: '',
					notes: '',
				})
				setIsLoading(false)

				setFormErrors([])
				setShowEditModal(false)
				setAlertError(true)
				setAlertErrorMsg('تم تعديل بيانات السائق بنجاح')
				setAlertColor('success')
				setSearch('')
			})
			.catch((error) => {
				let errors = handleError(error)
				let errorsArr = [errors]
				setFormErrors(errorsArr)
				setIsLoading(false)

				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}

	function changeFieldForEdit(e) {
		console.log(e.target.name)
		setDriverEditFormData({
			...driverEditFormData,
			[e.target.name]: e.target.value,
		})
	}

	function resetErrCode() {
		setErrCode({
			status: 0,
			title: '',
			server_errors: '',
			how_to_fix: '',
		})
	}
	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlertError(false)
		resetErrCode()
	}
	function openAddDriverModal(e) {
		setShowAddDriverModal(true)
	}
	function hideAddDriverModal(e) {
		setShowAddDriverModal(false)
	}
	function changeField(e) {
		console.log('sdasd')
		setDriverFormData({
			...driverFormData,

			[e.target.name]: e.target.value,
		})
	}
	function create(e) {
		setIsLoading(true)
		let result = validate()

		if (result.length === 0) {
			let data = new FormData()
			data.append('name', driverFormData.name)
			if (driverFormData.phone.length > 0)
				data.append('phone', driverFormData.phone)

			if (driverFormData.notes.length > 0) {
				if (('notes', driverFormData.notes));
			}

			createDriverRequest(data)
		} else {
			setIsLoading(false)
			setFormErrors(result)
		}
	}

	function validate() {
		let errors = []

		if (!driverFormData.name) errors.push('يرجى كتابة اسم السائق الثلاثي')

		if (
			driverFormData.phone.length > 0 &&
			(driverFormData.phone.length < 4 ||
				!driverFormData.phone.startsWith('964'))
		) {
			errors.push('يرجى كتابة رقم الهاتف بصورة صحيحة')
		}
		if (driverFormData.notes.length > 0 && driverFormData.notes.length < 4) {
			errors.push('يجب ان تقوم بكتابة ملاحظات باحرف لا تقل عن 4')
		}

		return errors
	}
	function createDriverRequest(data) {
		setIsLoading(true)
		setFormErrors([])
		axios({
			url: url + `/drivers`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				setAlertError(true)
				let msg = 'تم اضافة السائق بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '
					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors.push(response.data.Error.details[i])
					}
					if (errors) setFormErrors(errors)
				} else {
					setFormErrors([])
				}
				setAlertErrorMsg(msg)
				setAlertColor('success')

				let driversList = drivers
				driversList.push(response.data.Data)

				setDrivers(driversList)
				setUpdateDrivers(!updateDrivers)
				// clear inputs
				setDriverFormData({
					...driverFormData,
					name: '',
					phone: '',
					notes: '',
				})
				setFormErrors([])
				setShowAddDriverModal(false)
				setSearch('')
				setIsLoading(false)
			})
			.catch((error) => {
				let errors = handleError(error)
				let errorsArr = []

				if (errors === 409)
					// conflict
					errors.push('المركبة مضافة فعلا في النظام')
				else if (errors === 400 || errors === 500) {
					errorsArr.push(errors)
				}

				setFormErrors(errorsArr)

				setIsLoading(false)

				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}

	function openBoundingLogModal(driverObject) {
		setDriverToShow(driverObject)
		setShowBoundingLogModal(true)
	}

	function onSearchChange(e) {
		setSearch(e.target.value)
	}

	function onSearchEnter(e) {
		if (e.key === 'Enter') {
			if(pageNumber==1)
			fetchDrivers()
			else 
			setPageNumber(1)
		}
	}
}

export default Drivers
