import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
	root: {
		margin: 'auto'
	},
	cardHeader: {
		padding: theme.spacing(1, 2)
	},
	list: {
		width: 200,
		height: 230,
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto'
	},
	button: {
		margin: theme.spacing(0.5, 0)
	}
}))

export default function TransferList(props) {
	const classes = useStyles()

	// console.log(props.right)
	const customList = (title, items) => (
		<Card>
			<CardHeader
				className={classes.cardHeader}
				avatar={
					<Checkbox
						onClick={() => props.handleToggleAll(items)}
						checked={
							props.numberOfChecked(items) === items.length &&
							items.length !== 0
						}
						indeterminate={
							props.numberOfChecked(items) !== items.length &&
							props.numberOfChecked(items) !== 0
						}
						disabled={items.length === 0}
						inputProps={{ 'aria-label': 'جميع المراكز المحددة' }}
					/>
				}
				title={title}
				subheader={`${props.numberOfChecked(items)}/${items.length} تم تحديدها`}
			/>
			<Divider />
			<List className={classes.list} dense component="div" role="list">
				{items.map(value => {
					const labelId = `transfer-list-all-item-${value}-label`
					// console.log(value)
					return (
						<ListItem
							key={value.id}
							role="listitem"
							button
							onClick={() => props.handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									checked={
										props.checked.findIndex(function(v) {
											return v.id == value.id
										}) !== -1
									}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value.name} />
						</ListItem>
					)
				})}
				<ListItem />
			</List>
		</Card>
	)

	return (
		<Grid
			container
			spacing={2}
			justify="center"
			alignItems="center"
			className={classes.root}
		>
			<Grid item xs={12}>
				<Autocomplete
					size="small"
					className={classes.autocomplete}
					id="departments"
					// value={
					// 	props.selectedDepartment
					// 		? props.selectedDepartment
					// 		: props.departments[0]
					// }
					options={props.departments}
					onChange={props.onDepartmentChange}
					getOptionLabel={option => option.name}
					style={{ width: 300 }}
					renderInput={params => (
						<TextField {...params} label="الاقسام" variant="outlined" />
					)}
				/>
			</Grid>
			<Grid item>{customList('المراكز الغير المسموح رؤيتها', props.left)}</Grid>
			<Grid item>
				<Grid container direction="column" alignItems="center">
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={props.handleCheckedRight}
						disabled={props.leftChecked.length === 0}
						aria-label="move selected right"
					>
						&gt;
					</Button>
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={props.handleCheckedLeft}
						disabled={props.rightChecked.length === 0}
						aria-label="move selected left"
					>
						&lt;
					</Button>
				</Grid>
			</Grid>
			<Grid item>{customList('المراكز مسموح رؤيتها', props.right)}</Grid>
		</Grid>
	)
}
