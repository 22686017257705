import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { deepOrange, deepPurple } from '@material-ui/core/colors'
import env from '../../assets/files/env.json'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1)
		}
	},
	orange: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: `${env.theme.colors.accent}`
	},
	purple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500]
	}
}))

export default function LetterAvatars(props) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			{/* <Avatar>H</Avatar> */}
			<Avatar
				onClick={e => {
					props.showProfileMenu(e)
				}}
				className={classes.orange}
			>
				{props.username.charAt(0).toUpperCase()}
			</Avatar>
			{/* <Avatar className={classes.purple}>OP</Avatar> */}
		</div>
	)
}
