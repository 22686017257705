import React from 'react'
import logo from './logo.svg'
import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Main from './components/pages/Main/Main.js'
import Login from './components/pages/login/Login'
import Context from './components/common/Context'
import Cookies from 'js-cookie'
import { withAppContext } from './context/Context'

import { getBaseURL } from './components/common/Utils'
import NotFount from './components/pages/NotFount'

const url = getBaseURL()

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isAuthorized: false,
		}
		this.setAuth = this.setAuth.bind(this)
	}
	componentDidMount() {
		let data = null
		if (Cookies.get('SDIMS_USER_DATA')) {
			try {
				data = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
				if (data) this.setState({ isAuthorized: true })
			} catch (error) {}
		}
	}
	render() {
		// console.log(this.props)
		return (
			<BrowserRouter basename="/">
				<Switch>
					<Route exact path="/" component={Login} />
					<Route path="/dashboard" component={Main} />
					<Route path="/reports" component={Main} />
					<Route path="/users" component={Main} />
					<Route path="/vehicles" component={Main} />
					<Route path="/drivers" component={Main} />
					<Route path="/freights" component={Main} />
					<Route path="/centers" component={Main} />
					{/* <Route path="/notes" component={Main} /> */}
					{/* <Route path="/WeightNotes" component={Main} /> */}
					<Route path="/VehiclesNotAllow" component={Main} />
					<Route path="/Vehicle-classes" component={Main} />
					<Route path="/name-of-officials" component={Main} />


					<Route path='*' component={NotFount}/>
				</Switch>
			</BrowserRouter>
		)
	}

	setAuth(newState) {
		this.setState({ isAuthorized: newState })
	}
}

export default withAppContext(App)
