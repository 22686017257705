import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { getColor } from '../../common/Utils'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import CheckBox from '../../common/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Alert from '../../common/Alert'
import Grid from '@material-ui/core/Grid'
import Acl from './Acl'
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(',')
	}
})

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
		backgroundColor: getColor('primary').dark
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	textField: {
		width: '100%',
		marginTop: '10px'
	}
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const Root2 = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
`
export default function FullScreenDialog(props) {
	const classes = useStyles()
    const handleEnteredEvent = (e) => {
		if (!props?.isRequesting) {
			if (e.key === 'Enter') {
				props.create()
			} else if (e.key === 'Escape') {
				props.hideModal()
			}
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullScreen
					open={props.show}
					onClose={props.hideModal}
					TransitionComponent={Transition}
					onKeyDown={handleEnteredEvent}
				>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton
								disabled={props.isRequesting}
								edge="end"
								color="inherit"
								onClick={props.hideModal}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={classes.title}>
								اضافة مستخدم جديد
							</Typography>
							<Button
								disabled={props.isRequesting}
								autoFocus
								color="inherit"
								onClick={props.create}
							>
								حفظ
							</Button>
						</Toolbar>
					</AppBar>
					{props.formErrors.length > 0 ? (
						<Alert
							title="مدخلات غير صحيحة"
							msg={props.formErrors}
							severity="error"
						></Alert>
					) : null}
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Root2>
								<TextField
									className={classes.textField}
									required
									id="fullname"
									label="الاسم الحقيقي"
									name="fullname"
									variant="outlined"
									onChange={props.changeField}
									value={props.userData['fullname']}
								/>

								<TextField
									className={classes.textField}
									required
									id="username"
									name="username"
									label="اسم المستخدم"
									variant="outlined"
									onChange={props.changeField}
									value={props.userData['username']}
								/>
								<TextField
									className={classes.textField}
									required
									id="password"
									name="password"
									label="كلمة المرور"
									variant="outlined"
									type="password"
									onChange={props.changeField}
									value={props.userData['password']}
								/>
								<TextField
									className={classes.textField}
									required
									id="password_conf"
									name="c_password"
									label="تأكيد كلمة المرور"
									variant="outlined"
									type="password"
									onChange={props.changeField}
									value={props.userData['c_password']}
								/>
								<TextField
									className={classes.textField}
									id="notes"
									name="notes"
									label="ملاحظات"
									variant="outlined"
									onChange={props.changeField}
									value={props.userData['notes']}
								/>
								<CheckBox
									name="cb-state"
									label="تفعيل المستخدم؟"
									changeState={props.changeState}
								></CheckBox>
								<Autocomplete
									id="roles"
									options={props.roles}
									onChange={props.changeRole}
									getOptionLabel={option => option.name}
									style={{ width: 300 }}
									renderInput={params => (
										<TextField
											{...params}
											label="الصلاحيات"
											variant="outlined"
										/>
									)}
								/>
							</Root2>
						</Grid>

						<Grid item xs={6}>
							{/* {console.log(props.selectedRole)} */}
							{props.selectedRole ? (
								props.selectedRole.name != 'Admin' &&
								props.selectedRole.name != 'Root' ? (
									<Root2>
										{
											<Acl
												departments={props.departments}
												selectedDepartment={props.selectedDepartment}
												onDepartmentChange={props.onDepartmentChange}
												selectedRole={props.role}
												numberOfChecked={props.numberOfChecked}
												handleToggleAll={props.handleToggleAll}
												handleToggle={props.handleToggle}
												checked={props.checked}
												handleCheckedRight={props.handleCheckedRight}
												handleCheckedLeft={props.handleCheckedLeft}
												rightChecked={props.rightChecked}
												leftChecked={props.leftChecked}
												right={props.right}
												left={props.left}
											/>
										}
									</Root2>
								) : null
							) : (
								<Root2>
									{
										<Acl
											departments={props.departments}
											selectedDepartment={props.selectedDepartment}
											onDepartmentChange={props.onDepartmentChange}
											selectedRole={props.role}
											numberOfChecked={props.numberOfChecked}
											handleToggleAll={props.handleToggleAll}
											handleToggle={props.handleToggle}
											checked={props.checked}
											handleCheckedRight={props.handleCheckedRight}
											handleCheckedLeft={props.handleCheckedLeft}
											rightChecked={props.rightChecked}
											leftChecked={props.leftChecked}
											right={props.right}
											left={props.left}
										/>
									}
								</Root2>
							)}
						</Grid>
					</Grid>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
