import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import CheckBox from '../../common/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ShareCard from './ShareCard'
import { FormControlLabel, Switch } from '@material-ui/core'

const Root = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
`

const RootStep2 = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 40px;
`
const RootStep2Lists = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
`

const BtnsDiv = styled.div`
	display: flex;
	flex-direction: row;
`
const DivRow = styled.div`
	display: flex;
	flex-direction: row;
  width:'100%';
`
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	textField: {
		width: '100%',
		marginTop: '10px',
	},
}))

function getSteps() {
	return ['المعلومات الاساسية', 'الوجبات والسائقين', 'حفظ البيانات']
}

function getStepContent(stepIndex) {
	switch (stepIndex) {
		case 0:
			return 'أدخل معلومات المركبة الاساسية'
		case 1:
			return 'ادخل الوجبات مع السائقين'
		case 2:
			return 'اضغط لحفظ البيانات'
		default:
			return 'Unknown stepIndex'
	}
}

export default function HorizontalLabelPositionBelowStepper(props) {
	const classes = useStyles()
	const steps = getSteps()
	const getCheck = (id) => {
		if (props?.data && props?.data?.attendPeerShift?.length) {
			return props?.data?.attendPeerShift?.includes(id);
		} else return false
	}
	const attendSelect = (id) => {
		if (props?.data && props?.data?.attendPeerShift?.length) {
			let check = props?.data?.attendPeerShift?.find(item => item == id)
			if (!check) {
             let arr=[...props?.data?.attendPeerShift]
			  arr.push(id)
			  props?.setVehicleFormData({
				...props?.data,
				attendPeerShift: arr
			})
			} else {
				let data = props?.data?.attendPeerShift?.filter(item => item !== id)
				props?.setVehicleFormData({
					...props?.data,
					attendPeerShift: data
				})
			}
		} else {
			props?.setVehicleFormData({
				...props?.data,
				attendPeerShift: [id]
			})
		}
	}
	return (
		<div className={classes.root}>
			<Stepper activeStep={props.activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			{props.activeStep === 0 ? (
				<Root>
					<TextField
						className={classes.textField}
						required
						id="plate_number"
						label="رقم المركبة "
						name="plate_number"
						variant="outlined"
						onChange={props.changeField}
						value={props.data['plate_number']}
					/>

					<TextField
						className={classes.textField}
						id="brand"
						name="brand"
						label="ماركة المركبة"
						variant="outlined"
						onChange={props.changeField}
						value={props.data['brand'] ? props.data['brand'] : ''}
					/>
					<TextField
						className={classes.textField}
						id="model"
						name="model"
						label="الموديل"
						variant="outlined"
						onChange={props.changeField}
						value={props.data['model'] ? props.data['model'] : ''}
					/>
					<TextField
						className={classes.textField}
						required
						type="number"
						id="ration_for_stability"
						name="ration_for_stability"
						label="قيمة الادامة"
						variant="outlined"
						onChange={props.changeField}
						value={
							props.data['ration_for_stability']
								? props.data['ration_for_stability']
								: ''
						}
					/>
					<CheckBox
						name="vehicle_state"
						label="تفعيل نشاط المركبة؟"
						state={props.data['is_active']}
						changeState={props.changeState}
					></CheckBox>
					<CheckBox
						name="karaj"
						label="ادراج ضمن حضورية الكراج الشهرية ؟"
						state={props.data['karaj']}
						changeState={props.changeAttend}
					></CheckBox>
					{props.data['karaj'] ? <div
						style={{
							width: '100%',
							height: 'fit-content',
							backgroundColor: '#f3f3f3',
							padding:'10px 20px',
							marginBottom:'20px'
						}}
					>
						{/* <div style={{ fontFamily: 'Cairo', fontWeight: 'bold' }}>أختر الوجبات  لحضورية الكراج الشهري</div> */}
						<div>
							{props.shifts && props.shifts?.length && props.shifts?.map(item => (
								<CheckBox
									name={item?.name}
									label={item?.name}
									state={getCheck(item?.id)}
									changeState={()=>attendSelect(item?.id)}
								></CheckBox>
							))}
						</div>
						<div></div>

					</div> : null}
					<Autocomplete
						id="classes"
						options={props.classes}
						value={props.selectedClass ? props.selectedClass : props.classes[0]}
						onChange={props.changeClass}
						getOptionLabel={(option) => option.name}
						style={{ width: 300, marginTop: '15px', marginBottom: '15px' }}
						renderInput={(params) => (
							<TextField {...params} label="التصنيفات" variant="outlined" />
						)}
					/>
					<Autocomplete
						id="vehicleTypes"
						value={
							props.selectedVehicleType
								? props.selectedVehicleType
								: props.vehiclesTypes[0]
						}
						options={props.vehiclesTypes}
						onChange={props.changeVehicleType}
						getOptionLabel={(option) => option.name}
						style={{ width: 300 }}
						renderInput={(params) => (
							<TextField
								{...params}
								label="عائدية المركبة"
								variant="outlined"
							/>
						)}
					/>
				</Root>
			) : null}
			{props.activeStep === 1 ? (
				<RootStep2>
					<RootStep2Lists>
						<Autocomplete
							id="departments"
							options={props.departments}
							value={
								props.selectedDepartment
									? props.selectedDepartment
									: props.departments[0]
							}
							onChange={props.onDepartmentChange}
							getOptionLabel={(option) => option.name}
							style={{ width: 300, paddingTop: '10px' }}
							renderInput={(params) => (
								<TextField {...params} label=" الاقسام " variant="outlined" />
							)}
						/>
						<Autocomplete
							id="centers"
							value={
								props.selectedCenter ? props.selectedCenter : props.centers[0]
							}
							options={props.centers}
							onChange={props.onCenterChange}
							getOptionLabel={(option) => option.name}
							style={{ width: 300, paddingTop: '10px' }}
							renderInput={(params) => (
								<TextField
									{...params}
									label="المراكز البلدية"
									variant="outlined"
								/>
							)}
						/>
						<Autocomplete
							id="shifts"
							value={
								props.selectedShift ? props.selectedShift : props.shifts[0]
							}
							options={props.shifts}
							onChange={props.onShiftChange}
							getOptionLabel={(option) => option.name}
							style={{ width: 300, paddingTop: '10px' }}
							renderInput={(params) => (
								<TextField {...params} label="الوجبات" variant="outlined" />
							)}
						/>
						<Autocomplete
							id="drivers"
							value={
								props.selectedDriver ? props.selectedDriver : props.drivers[0]
							}
							options={props.drivers}
							onChange={props.onDriverChange}
							getOptionLabel={(option) => option.name}
							style={{ width: 300, paddingTop: '10px' }}
							renderInput={(params) => (
								<TextField {...params} label="السائقين" variant="outlined" />
							)}
						/>
						<DivRow>
							<FormControlLabel
								control={
									<Switch
										checked={props?.isWeightOrNot}
										onChange={(e) => {
											props.setIsWeightOrNot(e?.target?.checked)
										}}
										color="primary"
									/>
								}
								label={
									props?.isWeightOrNot
										? 'معامل للمركبات ذات الوزن'
										: 'معامل للمركبات التي بدون وزن'
								}
								style={{
									width: '260px'
								}}
							/>
							<TextField
								className={classes.textField}
								id="weight_coefficient"
								name="weight_coefficient"
								label="المعامل"
								variant="outlined"
								style={{
									width: '200px'
								}}
								onChange={(e) => {
									console.log('jdfa;sdjfaksjfklasd=>', props.weightOrNotValue)
									if (!isNaN(e?.target?.value))
										props.setWeightOrNotValue(parseInt(e?.target?.value))
								}}
								value={props.weightOrNotValue ? props.weightOrNotValue : ''}
							/>
						</DivRow>
					</RootStep2Lists>
					<TextField
						className={classes.textField}
						required
						type="number"
						id="shareValue"
						name="shareValue"
						label="الحصة"
						variant="outlined"
						onChange={props.onShareValueChange}
						value={props.shareValue}
					/>
					<BtnsDiv>
						<IconButton onClick={props.addShare}>
							<AddCircleIcon color="primary"></AddCircleIcon>
						</IconButton>
						<IconButton onClick={props.removeShare}>
							<RemoveCircleIcon color="error"></RemoveCircleIcon>
						</IconButton>
					</BtnsDiv>

					{props.data.shares && props.data.shares.length > 0
						? props.data.shares.map(function (item, index) {
							return (
								<ShareCard
									share={item}
									vehicleName={props.data.plate_number}
								></ShareCard>
							)
						})
						: null}
				</RootStep2>
			) : null}
			<div>
				{props.activeStep === steps.length ? (
					<div>
						<Typography className={classes.instructions}>
							تم انهاء جميع الخطوات
						</Typography>
						<Button onClick={props.handleReset}>عودة من جديد</Button>
					</div>
				) : (
					<div>
						<Typography className={classes.instructions}>
							{getStepContent(props.activeStep)}
						</Typography>
						<div>
							<Button
								disabled={props.activeStep === 0}
								onClick={props.handleBack}
								className={classes.backButton}
							>
								رجوع
							</Button>
							<Button
								disabled={props.isRequesting}
								variant="contained"
								color="primary"
								onClick={props.handleNext}
							>
								{props.activeStep === steps.length - 1 ? 'انهاء' : 'التالي'}
							</Button>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
