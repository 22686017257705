import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import {getColor} from '../common/Utils'

const MyCheckbox = withStyles({
  root: {
    color: getColor('primary').dark,
    '&$checked': {
      color: getColor('secondary'),
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels(props) {

  return (
   
      <FormControlLabel
        control={
          <MyCheckbox
        
            checked={props.state}
            onChange={changeState}
            name={props.name}
            color="primary"
          />
        }
        label={props.label}
      />
     
  );

function changeState(e)
{
    props.changeState(e)
}
}
