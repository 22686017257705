import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import MyTableRow from '../../common/TableRow'
import MyTableCell from '../../common/CustomTableCell'

const crudColors = require('../../../assets/files/env.json').theme.colors.crud

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	}
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		}
	}
}))(TableRow)

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein }
}

const useStyles = makeStyles({
	table: {
		minWidth: 700
	}
})
const styles = theme => ({
	table: {
		minWidth: 700
	}
})

class CustomizedTables extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.data === nextProps.data) {
			return false
		} else {
			return true
		}
	}

	render() {
		const { classes } = this.props
		let prop = this.props
		let numbering = 0

		return (
			<TableContainer component={Paper}>
				<Table
					size="small"
					className={classes.table}
					aria-label="customized table"
				>
					<TableHead>
						<MyTableRow header={this.props.header}></MyTableRow>
					</TableHead>
					<TableBody>
						{this.props.list.map(function(item, index) {
							numbering++
							return (
								<StyledTableRow key={index}>
									<MyTableCell
										key={'chv_a' + index}
										value={numbering}
									></MyTableCell>
									<MyTableCell
										key={'chv_b' + index}
										value={item.center.name}
									></MyTableCell>
									<MyTableCell
										key={'chv_c' + index}
										value={item.shift.name}
									></MyTableCell>
									<MyTableCell
										key={'chv_d' + index}
										value={item.driver.name}
									></MyTableCell>
									<MyTableCell
										key={'chv_e' + index}
										value={item.bound_at ? item.bound_at : '-'}
									></MyTableCell>
									<MyTableCell
										key={'chv_f' + index}
										value={item.bound_by ? item.bound_by.name : '-'}
									></MyTableCell>
									<MyTableCell
										key={'chv_g' + index}
										value={item.unbound_at ? item.unbound_at : '-'}
									></MyTableCell>
									<MyTableCell
										key={'chv_h' + index}
										value={item.unbound_by ? item.unbound_by.name : '-'}
									></MyTableCell>
								</StyledTableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
}

export default withStyles(styles, { withTheme: true })(CustomizedTables)
