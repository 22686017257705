import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { getColor } from '../../common/Utils'
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import CheckBox from '../../common/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '../../common/Alert'
import Grid from '@material-ui/core/Grid';
import Pb from '../../common/ProgressBar'
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


const theme = createMuiTheme({
  direction: 'rtl',
  typography: {
    fontFamily: [
      'Cairo',
      'Arial',
      'sans-serif'
    ].join(','),
  },

});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: getColor('primary').dark
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    width: '100%',
    marginTop: '10px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Root2 = styled.div`

display:flex;
flex-direction:column;
padding:40px;
width:100%;
`;
export default function FullScreenDialog(props) {

  const classes = useStyles();
  const handleEnteredEvent = (e) => {
    if (!props.isLoading) {
      if (e.key === 'Enter') {
        props.edit()
      } else if (e.key === 'Escape') {
        props.hideEditDriverModal()
      }
    }
  }
  return (

    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Dialog
          fullScreen
          open={props.show}
          onClose={props.hideModal}
          TransitionComponent={Transition}
          onKeyDown={handleEnteredEvent}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="end" color="inherit" onClick={props.hideEditDriverModal} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                تعديل بيانات سائق
              </Typography>
              {
                props.isLoading ?
                  <Pb

                  ></Pb>
                  :
                  <Button disabled={props.isLoading} autoFocus color="inherit" onClick={props.edit}>
                    حفظ
                  </Button>
              }
            </Toolbar>
          </AppBar>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Root2>
                <TextField
                  className={classes.textField}
                  required
                  id="driver_name"
                  label="الاسم الحقيقي"
                  name="name"
                  variant="outlined"
                  onChange={props.changeField}
                  value={props.driverData['name']}
                />

                <TextField
                  className={classes.textField}

                  id="driver_phone"
                  name="phone"
                  label="رقم السائق - اختياري"
                  variant="outlined"
                  onChange={props.changeField}
                  value={props.driverData['phone']}
                />
                <TextField
                  className={classes.textField}

                  id="driver_notes"
                  name="notes"
                  label="ملاحظات - اختياري"
                  variant="outlined"
                  onChange={props.changeField}
                  value={props.driverData['notes']}
                />



              </Root2>
            </Grid>

            <Grid item xs={6}>
              {
                props.formErrors.length > 0 ?
                  <Alert
                    title='مدخلات غير صحيحة'
                    msg={props.formErrors}
                    severity='error'
                  ></Alert>
                  :
                  null
              }
            </Grid>
          </Grid>
        </Dialog>
      </StylesProvider>
    </ThemeProvider>


  );




}
