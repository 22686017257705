import React from 'react'
import styled from 'styled-components'

const Root1 = styled.div`
display:flex;
flex-direction:row;
align-items:center;
`;
const Root2 = styled.div`
min-width:25px;
min-height:25px;
max-width:25px;
max-height:25px;
border-radius:5px;
margin:5px;
margin-left:10px;
`;
function GuidMark(props) {
    return (
       <Root1>
            <Root2
        style={{

         backgroundColor:`${props.bgColor}`
        }}>
            
        </Root2>
        <p>{props.text}</p>
       </Root1>
    )
}

export default GuidMark
