import React from 'react'
import useContext from './AppContext'

const AppContext = React.createContext({})

export const AppProvider = props => {
    const context = useContext(props)
    return <AppContext.Provider value={context}>
        {props.children}
    </AppContext.Provider>
}

export const useAppContext = () => React.useContext(AppContext)

export const withAppContext = Component => {
    return class WithAppContext extends React.Component {
        render() {
            return (
                <AppContext.Consumer>
                    {context => <Component {...this.props} {...context} />}
                    </AppContext.Consumer>
            )
        }
    }
}
export default AppContext