import axios from 'axios'
import Cookies from 'js-cookie'
import { handleError, getBaseURL } from './Utils'

const url = getBaseURL()

export const getRoles = async () => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + `/roles`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + userData.token,
			},
		})
		return result
	} catch (error) {
		let errors = handleError(error)
		if (errors === 401) {
			window.location.href = '/'
			Cookies.remove('SDIMS_USER_DATA')
			return 401
		}
		return []
	}
}

export const getItems = async (route) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + `/${route}`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + userData.token,
			},
		})
		return result
	} catch (error) {
		let errors = handleError(error)
		if (errors === 401) {
			window.location.href = '/'
			Cookies.remove('SDIMS_USER_DATA')
			return 401
		}
		return []
	}
}

export const getGivenItems = async (items) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + `/resources`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
				Accept: 'application/json',
			},
			data: JSON.stringify({
				items: items,
			}),
		})
		return result
	} catch (error) {
		let errors = handleError(error)
		if (errors === 401) {
			window.location.href = '/'
			Cookies.remove('SDIMS_USER_DATA')
			return 401
		}
		return []
	}
}

export const getGivenItemsJson = async (jsonObj) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}
	let result = null
	try {
		result = await axios({
			url: url + `/resources`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
				Accept: 'application/json',
			},
			data: JSON.stringify(jsonObj),
		})
		return result
	} catch (error) {
		let errors = handleError(error)
		if (errors === 401) {
			window.location.href = '/'
			Cookies.remove('SDIMS_USER_DATA')
			return 401
		}
		return []
	}
}

export const createRequest = async (route, data) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + `/` + route,
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
		return result
	} catch (error) {}
}

export const setFilterdata = async (route, data) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + '/' + route,
			method: 'post',
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
		return result
	} catch (error) {}
}

export const setFilterSearch = async (route, data) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + '/' + route,
			method: 'post',
			data: data,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
		return result
	} catch (error) {}
}
//set clear selected location here
export const clearselectedLocation = async (route, data) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + '/' + route,
			method: 'post',
			data: data,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
		return result
	} catch (error) {}
}

export const getfreightsnotallow = async (route) => {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let result = null
	try {
		result = await axios({
			url: url + '/' + route,
			method: 'post',
			data: {},
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
		return result
	} catch (error) {}
}
