import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DayDate from '../../common/DatePicker'
import CheckBox from '../../common/CheckBox'
import BegTimePicker from '../../common/TimePicker'
import EndTimePicker from '../../common/TimePicker'
import EngineHoursPicker from '../../common/TimePicker'
import WorkingTimePicker from '../../common/TimePicker'
import TextField from '@material-ui/core/TextField'
import SubFreightCard from './SubFreightCard'
import SubControl from './SubControls'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import { TimePicker, KeyboardTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Cookies from 'js-cookie'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const colors = require('../../../assets/files/env.json').theme.colors

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
`
const SubFContainers = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	background-color: '#fff';
`

const DataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const DataDivRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '600px',
		backgroundColor: '#fff',
	},
	pickers: {
		backgroundColor: '#fff',
		marginRight: '15px',
	},
	textField: {
		backgroundColor: '#fff',
	},
}))

const getDisabledDepartmentName = (vehicle, shift) => {
	const neededShift = vehicle.shifts.filter((sh) => {
		return sh.shift_id === shift.id
	})
	if (neededShift && neededShift[0] && neededShift[0].department_name) {
		return neededShift[0].department_name
	} else {
		return ''
	}
}

const getDisabledCenterName = (vehicle, shift) => {
	const neededShift = vehicle.shifts.filter((sh) => {
		return sh.shift_id === shift.id
	})

	if (neededShift && neededShift[0] && neededShift[0].center_name) {
		return neededShift[0].center_name
	} else {
		return ''
	}
}

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	)
}

export default function DraggableDialog(props) {
	let data = null
	try {
		data = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	// const filteredShifts = props.filteredShifts.sort(function (a, b) {
	// 	return a.id - b.id
	// })
	const filteredShifts = props.filteredShifts.sort((a, b) => a.id - b.id);

	console.log('ldksldkfjg;lksdjfgl;k===>', filteredShifts)
	// const [deselectAll, setDeselectAll] = useState(false)
	const [showAddLocation, setShowAddLocation] = useState(false)
	// const [selecedDatanow, setselecedDatanow] = useState(null)

	// onselectiondatanowchange=(e)=>{
	// 	setselecedDatanow
	// }
	const [locationSelected, setLocationSelected] = useState(null)

	const [selectedDriverNow, setselectedDriverNow] = useState('')

	const [selectedVehiclesNow, setselectedVehiclesNow] = useState(null)
	const [selectedShiftNow, setselectedShiftNow] = useState(props.selectedShift)

	// useEffect(() => {
	// 	setselectedDriverNow(
	// 		props.selectedVehicle ? props.selectedVehicle : props.vehicles[0]
	// 	)
	// }, [props.selectedVehicle])

	// useEffect(() => {
	// 	// setselectedShiftNow(props.selectedShift ? props.selectedShift : null)
	// 	console.log('shift changed')
	// }, [props.selectedShift])

	const classes = useStyles()
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullWidth={true}
					maxWidth="xl"
					disableBackdropClick={true}
					open={props.open}
					onClose={props.closeAddModal}
					PaperComponent={PaperComponent}
					aria-labelledby="draggable-dialog-title"
					onKeyDown={(event) => {
						if (event.keyCode === 13) {
							props.save()
						}else if(event.key=='Escape'){
							props.closeAddModal()
						}
					}}
				>
					<DialogTitle
						style={{
							backgroundColor: '#007AC4',
							color: '#fff',
							cursor: 'move',
						}}
						id="draggable-dialog-title"
					>
						واجهة ادخال الشحنات
					</DialogTitle>
					<DialogContent
						style={{
							backgroundColor: '#DEF3FF',
						}}
					>
						<Grid container direction="row" spacing={3}>
							<Grid item xs={6}>
								<Root>
									<Autocomplete
										className={classes.autocomplete}
										id="vehicles"
										name="selectedVehicle"
										// defaultValue={props.vehicles?.find(
										// 	(v) =>
										// 		v.current_driver?.driver?.name ==
										// 		props.selectedVehicle?.current_driver?.driver?.name
										// )}
										value={
											props.selectedVehicle
												? props.selectedVehicle
												: props.vehicles[0]
										}
										// value={
										// 	selectedVehiclesNow
										// 		? selectedVehiclesNow
										// 		: props.vehicles[0]
										// }
										//options={props.vehicles}
										options={props.vehicles.filter(
											(item) => !(item.is_active == '0')
										)}
										getOptionDisabled={(option) => {
											return (
												(option.shifts && option.shifts.length === 0) ||
												option.is_active == '0'
											)
										}}
										onChange={(e, value) => {
											// setselectedVehiclesNow(value)
											props.onVehicleChange(e, value)
											// props.onShiftChange2(value)
										}}
										getOptionLabel={(option) => {
											return (
												option.plate_number +
												' - ' +
												(option.brand ? option.brand : '') +
												' - ' +
												(option.current_driver
													? option.current_driver.driver.name
													: '') + (option?.shift_name ? " ( " + option?.shift_name + ' ) ' : '')
											)
										}}
										style={{ width: 300 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="الاليات"
												variant="outlined"
											/>
										)}
									/>
									<div
										style={{
											width: 600,
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<Autocomplete
											className={classes.autocomplete}
											id="shifts"
											name="selectedShift"
											getOptionSelected={(option) => option.name}
											value={
												props.selectedShift
													? props.selectedShift
													: filteredShifts[0]
											}
											options={filteredShifts.length > 0 ? filteredShifts : []}
											onChange={(e, value) => {
												props.onVehicleChange2(value)
												props.onShiftChange(e, value)
											}}
											getOptionLabel={(option) => option.name}
											style={{ minWidth: 0, width: 160 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label="الوجبات"
													variant="outlined"
												/>
											)}
										/>
										{/* {console.log('props.selectedShift',props.selectedVehicle.shifts,props.selectedShift.id)} */}

										<TextField
											className={classes.autocomplete}
											disabled
											id="disabled-department"
											variant="outlined"
											label="القسم"
											value={
												props.selectedVehicle
													? props.selectedShift
														? getDisabledDepartmentName(
															props.selectedVehicle,
															props.selectedShift
														)
														: ''
													: ''
											}
											style={{
												minWidth: 0,
												width: 200,
												backgroundColor: '#ededed',
											}}
										/>
										<TextField
											className={classes.autocomplete}
											disabled
											variant="outlined"
											id="disabled-center"
											label="المركز والشعبة"
											value={
												props.selectedVehicle
													? props.selectedShift
														? getDisabledCenterName(
															props.selectedVehicle,
															props.selectedShift
														)
														: ''
													: ''
											}
											style={{
												minWidth: 0,
												width: 200,
												backgroundColor: '#ededed',
											}}
										/>
									</div>
									<DayDate
										className={classes.pickers}
										style={{
											padding: '15px',
										}}
										title="اليوم"
										selectedDate={props.selectedDayDate}
										handleDateChange={props.onDayDateChange}
									/>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<CheckBox
											name="is_present"
											label="حاضر؟"
											state={props.fieldsData.is_present}
											changeState={props.changeIsPresent}
										></CheckBox>

										<CheckBox
											name="is_present"
											label="الغاء الخيارات ادناه"
											state={props.fieldsData.unselectall}
											changeState={(e) => {
												// props.unselected__(!props.fieldsData.unselectall)

												if (props.fieldsData.unselectall) {
													props.selectAllOptions(locationSelected)
												} else {
													props.deselectAllOptions()
												}
											}}
										></CheckBox>

										<Button
											disabled={props.isRequesting}
											onClick={() => setShowAddLocation(!showAddLocation)}
											color="primary"
										>
											اضافة موقع انطلاق جديد
										</Button>
									</div>
									{props.fieldsData.is_present ? (
										<DataDiv>
											{showAddLocation ? (
												<div
													style={{
														height: 80,
														width: '100%',
														backgroundColor: '#fff',
														display: 'flex',
														flexDirection: 'row',
														marginBottom: 5,
														borderRadius: 10,
													}}
												>
													<div>
														<TextField
															className={classes.textField}
															style={{
																margin: '10px',
															}}
															id="trip_beg_location"
															name="trip_beg_location"
															label="موقع الانطلاق"
															size="small"
															variant="outlined"
															onChange={props.onLocationTextChange}
															value={props.locationName}
														/>
														<Button
															disabled={props.isRequesting}
															onClick={props.addNewLocation}
															color="primary"
															style={{ marginTop: 10 }}
														>
															اضافة
														</Button>
													</div>
												</div>
											) : null}
											<DataDivRow>
												{/* {console.log('locations', props.locations)}
												{console.log('locations', props.filteredShifts)} */}

												<Autocomplete
													className={classes.autocomplete}
													id="locations"
													name="selectedLocation"
													// defaultValue={
													// 	props.fieldsData.showBegTime
													// 		? props.selectedLocation?.name
													// 		: null
													// }
													defaultValue={locationSelected?.name}
													options={props.locations ? props.locations : []}
													onChange={(e, value) => {
														// console.log("value",value);
														setLocationSelected(value)
														props.changeField(e, value)
													}}
													onInputChange={(event, newInputValue, reason) => {
														console.log(event, reason)
														if (
															document.getElementById('locations').value &&
															reason === 'clear'
														)
															props.clearSeletedLocation(reason)
														else
															document.getElementById('locations').value =
																newInputValue
													}}
													getOptionLabel={(option) => option.name}
													disabled={!props.fieldsData.showBegTime}
													style={{ minWidth: 0, width: 200 }}
													renderInput={(params) => {
														// console.log('params', params)
														if (params?.disabled)
															return (
																<TextField
																	disabled={true}
																	value={''}
																	label="موقع الانطلاق"
																	variant="outlined"
																/>
															)
														else
															return (
																<TextField
																	{...params}
																	value={
																		params?.disabled
																			? ''
																			: params?.inputProps?.value
																	}
																	label="موقع الانطلاق"
																	variant="outlined"
																/>
															)
													}}
												/>

												{/* <TextField
													className={classes.textField}
													style={{
														marginLeft: '15px'
													}}
													id="trip_beg_location"
													name="trip_beg_location"
													label="موقع الانطلاق"
													variant="outlined"
													onChange={props.changeField}
													value={props.fieldsData.trip_beg_location}
												/> */}
												<CheckBox
													name="showBegTime"
													label="وقت الانطلاق؟"
													state={props.fieldsData.showBegTime}
													changeState={(e) => {
														// props.clearSeletedLocation("")
														props.changeShowBegTime(e, locationSelected)
													}}
												/>
												{props.fieldsData.showBegTime ? (
													<BegTimePicker
														style={{
															padding: '15px',
															marginRight: '15px',
														}}
														className={classes.pickers}
														title="وقت الانطلاق"
														selectedDate={props.fieldsData.selectedBegTime}
														handleDateChange={props.onBegTimeChange}
													/>
												) : null}
											</DataDivRow>

											<DataDivRow>
												<CheckBox
													name="show_end_time"
													label="وقت العودة؟"
													state={props.fieldsData.showEndTime}
													changeState={props.changeShowEndTime}
												></CheckBox>
												{props.fieldsData.showEndTime ? (
													<EndTimePicker
														className={classes.pickers}
														style={{
															padding: '15px',
														}}
														title="وقت العودة"
														selectedDate={props.fieldsData.selectedEndTime}
														handleDateChange={props.onEndTimeChange}
													/>
												) : null}

												{/* <TotalParkingTime
              className={classes.pickers}

                      style={{
                        padding: '15px'
                      }}
                      withSeconds={true}
                      title="مجموع التوقفات"
                      selectedDate={props.fieldsData.selectedTotalParkingTime}
                      handleDateChange={props.onTotalParkingTimeChange}
                    />
                     */}
											</DataDivRow>

											<DataDivRow>
												<CheckBox
													name="showTPT"
													label="اضافة مجموع التوقفات؟"
													state={props.fieldsData.showTPT}
													changeState={props.changeTPT}
												/>
												{props.fieldsData.showTPT ? (
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<KeyboardTimePicker
															autoOk
															clearable
															ampm={false}
															label="مجموع التوقفات"
															value={props.fieldsData.selectedTotalParkingTime}
															onChange={props.onTotalParkingTimeChange}
														/>
													</MuiPickersUtilsProvider>
												) : null}
											</DataDivRow>
										</DataDiv>
									) : (
										<DataDiv>
											<Autocomplete
												className={classes.autocomplete}
												id="reasons"
												name="selectedReason"
												value={
													props.selectedReason
														? props.selectedReason
														: props.reasons[0]
												}
												options={props.reasons}
												onChange={props.onReasonChange}
												getOptionLabel={(option) => option.name}
												style={{ width: 300 }}
												renderInput={(params) => (
													<TextField
														{...params}
														label="الاسباب"
														variant="outlined"
													/>
												)}
											/>
										</DataDiv>
									)}

									{/* <CheckBox
										name="status"
										label="حالة السجل؟"
										state={props.fieldsData.status}
										changeState={props.changeFormStatus}
									></CheckBox> */}
								</Root>
							</Grid>
							{props.fieldsData.is_present || props.selectedReason.id == 5 ? (
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Switch
												checked={props.fieldsData.isFieldsFreights}
												onChange={props.onIsFieldsFreightsChange}
												name="isFieldsFreights"
												color="primary"
											/>
										}
										label={
											props.fieldsData.isFieldsFreights
												? 'الشحنات'
												: 'ساعات عمل المحرك والحركة'
										}
									/>
									{props.fieldsData.isFieldsFreights ? (
										<SubFContainers>
											<SubControl
												stations={props.stations}
												selectedStation={props.selectedStation}
												onStationChange={props.onStationChange}
												selectedAtTime={props.selectedAtTime}
												weight={props.weight}
												changeWeight={props.changeWeight}
												onAtTimeChange={props.onAtTimeChange}
												showAtTime={props.showAtTime}
												changeShowAtTime={props.changeShowAtTime}
												atTime={props.atTime}
												addSubFreightCard={props.addSubFreightCard}
												onOtherStationChange={props.onOtherStationChange}
												otherStation={props.otherStation}
												notes={props.notes}
												onNotesChange={props.onNotesChange}
												onIsFieldsFreightsChange={
													props.onIsFieldsFreightsChange
												}
												isFieldsFreights={props.fieldsData.isFieldsFreights}
											/>

											<SubFreightCard
												data={{
													numbering: '#',
													station: {
														id: -1,
														caption: 'الموقع',
													},

													weight: 'الوزن',
													at_time: 'وقت الزيارة',
													notes: '',
													otherStation: '',
												}}
												bg={colors.secondary}
												deleteSubFreightCard={props.deleteSubFreightCard}
											></SubFreightCard>
											{props.subFreights && props.subFreights.length > 0
												? props.subFreights.map(function (item, index) {
													return (
														<SubFreightCard
															key={index}
															numbering={index + 1}
															data={{
																station: item.station,
																weight: item.weight,
																at_time: item.at_time,
																notes: item.notes,
																otherStation: item.otherStation,
															}}
															bg="#fff"
															deleteSubFreightCard={
																props.deleteSubFreightCard
															}
														></SubFreightCard>
													)
												})
												: null}
										</SubFContainers>
									) : (
										<DataDiv>
											<DataDivRow>
												<CheckBox
													name="showEH"
													label="ساعات عمل المحرك"
													state={props.fieldsData.showEH}
													changeState={props.changeShowEH}
												/>
												{props.fieldsData.showEH ? (
													<EngineHoursPicker
														className={classes.pickers}
														ampm={false}
														style={{
															padding: '15px',
														}}
														title="ساعات عمل المحرك"
														selectedDate={props.fieldsData.engineHours}
														handleDateChange={props.onEngineHoursChange}
													/>
												) : null}
											</DataDivRow>
											<DataDivRow>
												<CheckBox
													name="showWT"
													label="ساعات الحركة"
													state={props.fieldsData.showWT}
													changeState={props.changeShowWT}
												/>
												{props.fieldsData.showWT ? (
													<WorkingTimePicker
														className={classes.pickers}
														ampm={false}
														style={{
															padding: '15px',
														}}
														title="الحركة"
														selectedDate={props.fieldsData.workingTime}
														handleDateChange={props.onWorkingTimeChange}
													/>
												) : null}
											</DataDivRow>
										</DataDiv>
									)}
								</Grid>
							) : <Grid xs={6}>
								<TextField
									className={classes.textField}
									style={{
										margin: '10px',
										position:'relative',
										top:'30px',
										width:'90%',
									}}
									id="trip_beg_location"
									name="trip_beg_location"
									label="ملاحظات خاصة بتقرير الحضورية غير الرسمية"
									size="small"
									variant="outlined"
									onChange={props.onNotesOfInformal}
									value={props.notesForAbseance}
								/>
							</Grid>}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={props.closeAddModal} color="primary">
							الغاء
						</Button>
						<Button
							disabled={props.isRequesting}
							onClick={props.save}
							color="primary"
						>
							حفظ
						</Button>
					</DialogActions>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
