import React, { useState, useEffect } from 'react'
import Table from './VehiclesTable'
import NoData from '../../common/NoData'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import axios from 'axios'
import {
	getBaseURL,
	handleError,
	handleErr,
	getStatusCode,
} from '../../common/Utils'
import ProgressBar from '../../common/ProgressBar'
import CrudControl from '../../common/CrudControl'
import Modal from './AddVehicle'
import EditModal from './editVehicleModal'
import Snackbar from '../../common/SnackBar'
import { getGivenItems } from '../../common/ApiHelper'
import DetailsModal from './DetailsModal'
import AssignedDriversModal from './AssignedDriversModal'
import AssignDriverToVehicleModal from './AssignDriverToVehicle'
import BackDropLoading from '../../common/BackDropLoading'
import CustomPagination from '../../common/CustomPagination'

const url = getBaseURL()
const colsTitles = [
	'#',
	'رقم المركبة',
	// 'النوع',
	// 'الموديل',
	'السائق',
	'الادامة',
	'عائدية المركبة',
	'عدد الشحنات',
	'الحالة',
	'الكراج',
	'التفاصيل',
	'تصدير شحنات المركبة',
	'تعديل',
]

const NoDataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-items: center;
`
const Root = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`

const PBDiv = styled.div`
	position: absolute;
	top: 50%;
	right: 50%;
`

function Vehicles(props) {
	const [vehicles, setVehicles] = useState([])
	const [filterVehicles, setFilteredVehicles] = useState([])
	const [selectedVehicle, setSelectedVehicle] = useState(null)

	const [updateVehicles, setUpdateVehicles] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isError, setIsError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [showModal, setShowModal] = useState(false) // for create
	const [showEditModal, setShowEditModal] = useState(false) // for edit modal
	const [isRequesting, setIsRequesting] = useState(false)
	const [alertError, setAlertError] = useState(false)
	const [alertErrorMsg, setAlertErrorMsg] = useState([])
	const [alertColor, setAlertColor] = useState('error')
	const [isWeightOrNot, setIsWeightOrNot] = useState(true)
	const [weightOrNotValue, setWeightOrNotValue] = useState(null)

	const [vehicleFormData, setVehicleFormData] = useState({
		plate_number: '',
		brand: '',
		model: '',
		class: null,
		ration_for_stability: null,
		is_active: true,
		karaj: true,
		vehicleType: null,
		shares: [],
		attendPeerShift: []
	})
	// for edit
	const [vehicleEditFormData, setVehicleEditFormData] = useState({
		plate_number: '',
		brand: '',
		model: '',
		class: null,
		ration_for_stability: null,
		// is_active: true,
		// karaj: true,
		vehicleType: null,
		attendPeerShift: []
	})
	// resources
	const [classes, setClasses] = useState([])
	const [vehiclesTypes, setVehiclesTypes] = useState([])
	const [selectedClass, setSelectedClass] = useState(null)
	const [selectedVehicleType, setSelectedVehicleType] = useState(null)

	const [departments, setDepartments] = useState([])
	const [centers, setCenters] = useState([])
	const [shifts, setShifts] = useState([])
	const [drivers, setDrivers] = useState([])
	const [shiftsData, setShiftsData] = useState([])

	const [selectedDepartment, setSelectedDepartment] = useState(null)
	const [selectedCenter, setSelectedCenter] = useState(null)
	const [selectedShift, setSelectedShift] = useState(null)
	const [selectedDriver, setSelectedDriver] = useState(null)
	const [shareValue, setShareValue] = useState(0)
	const [formErrors, setFormErrors] = useState([])
	const [pageSize, setPageSize] = useState(5)
	const [pageNumber, setPageNumber] = useState(1)
	const [totalRows, setTotalRows] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const [vehicleToUpdate, setVehicleToUpdate] = useState({
		vehicle: null,
		index: null,
	})
	const [listofChvs, setListOfChvs] = useState([])
	const [listOfChvsShift, setListOfChvsShift] = useState([])
	const [search, setSearch] = useState('')

	const [activeStep, setActiveStep] = React.useState(0)

	const [showDetailsModal, setShowDetailsModal] = React.useState(false)
	const [vehicleToShow, setVehicleToShow] = React.useState(null)

	const [vcsds, setVcsds] = useState([])
	const [showAssignedDriversModal, setShowAssignedDriversModal] =
		useState(false)
	const [showAssignDriverModal, setShowAssignDriverModal] = useState(false)

	const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	if (
		userData.userInfo.role.name !== 'Admin' &&
		userData.userInfo.role.name !== 'Root'
	)
		window.location.href = '/'

	const fetchVehicles = async () => {
		setIsLoading(true)
		setIsError(false)
		let result = null
		try {
			result = await axios({
				url: url + `/vehicles?filter_flag=b`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData.token,
				},
				data: {},
				params: {
					page: pageNumber,
					page_size: pageSize,
					search: search ? search?.toLowerCase() : null,
				},
			})
			setIsLoading(false)
			setFilteredVehicles(result?.data?.Data?.data)
			setVehicles(result?.data?.Data?.data)
			setPageCount(result?.data?.Data?.last_page)
			setTotalRows(result?.data?.Data?.total)
		} catch (error) {
			let errors = handleError(error)
			if (errors === 401) {
				window.location.href = '/'
				Cookies.remove('SDIMS_USER_DATA')
			}

			setIsError(true)
			setErrorMsg(errors)
		}
		setIsLoading(false)
	}

	const getVehicleDetails = async (vehicleID) => {
		await axios({
			url: url + `/vehicles/${vehicleID}?filter_flags=f`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
			.then((response) => {
				setShifts(response.data.Data.centers.map((item) => item.shift))
				setIsLoading(false)
			})
			.catch((error) => {
				console.log(error)
				setIsLoading(false)
			})
	}
	const fetchResources = async (Driver = null) => {
		let result = await getGivenItems([
			'classes',
			'vehicleTypes',
			'departments',
			'shifts',
			'drivers',
			'centers',
		])
		if (!result.data) return

		let arrays = result.data.Data.returned_items

		setClasses(arrays.classes)
		setDepartments(arrays.departments)
		// setCenters(arrays.departments.length ? arrays.departments[0].centers: []) // this is getting only مراكز
		setCenters(arrays.centers) // this will get all centers
		setShifts(arrays.shifts)
		setSelectedShift(arrays.shifts[0])
		setVehiclesTypes(arrays.vehicleTypes)
		setDrivers(arrays.drivers)
		setSelectedCenter(
			arrays.departments.length > 0
				? arrays.departments[0].centers.length > 0
					? arrays.departments[0].centers[0]
					: null
				: null
		)
		setSelectedDepartment(
			arrays.departments.length > 0 ? arrays.departments[0] : null
		)
		// setSelectedShift(arrays.shifts.length > 0 ? arrays.shifts[0] : null)
		if (Driver)
			setSelectedDriver(Driver)
		else
			setSelectedDriver(arrays.drivers.length > 0 ? arrays.drivers[0] : null)
	}
	useEffect(() => {


		// fetch users
		fetchVehicles()
		// fetch roles
		fetchResources()
	}, [updateVehicles])

	useEffect(() => {
		fetchVehicles()
	}, [pageSize, pageNumber])
	return (
		<Root>
			{isLoading ? <BackDropLoading open={isLoading} /> : null}

			<CrudControl
				showSearch={true}
				openModal={openModal}
				onSearchChange={onSearchChange}
				onSearchEnter={onSearchEnter}
				searchValue={search}
			/>
			{!isLoading && isError ? (
				<p>{errorMsg}</p>
			) : !isLoading && vehicles.length === 0 ? (
				<NoDataDiv>
					<NoData />
					<IconButton onClick={props.fetchVehicles}>
						<ReplayIcon />
					</IconButton>
				</NoDataDiv>
			) : (
				<>
					<Table
						header={colsTitles}
						data={filterVehicles}
						shifts={shifts}
						exportFreights={exportFreights}
						openDetailsModal={openDetailsModal}
						showEditModalFun={showEditModalFun}
						showAssignedDrivers={showAssignedDrivers}
						assignNewDriver={assignNewDriver}
						karajChange={karajChange}
						activeChange={activeChange}
						pageSize={pageSize}
						pageNumber={pageNumber}
					></Table>
					<CustomPagination
						count={pageCount}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						pageSize={pageSize}
						setPageSize={setPageSize}
					/>
					<br />
					<br />
					<br />
				</>
			)}
			<Modal
				show={showModal}
				hideModal={hideModal}
				data={vehicleFormData}
				setVehicleFormData={setVehicleFormData}
				create={create}
				classes={classes}
				selectedClass={selectedClass}
				vehiclesTypes={vehiclesTypes}
				selectedVehicleType={selectedVehicleType}
				departments={departments}
				shifts={shifts}
				centers={centers}
				drivers={drivers}
				onDepartmentChange={onDepartmentChange}
				onCenterChange={onCenterChange}
				onShiftChange={onShiftChange}
				onDriverChange={onDriverChange}
				selectedDepartment={selectedDepartment}
				selectedCenter={selectedCenter}
				selectedShift={selectedShift}
				selectedDriver={selectedDriver}
				changeField={changeField}
				changeClass={changeClass}
				changeVehicleType={changeVehicleType}
				formErrors={formErrors}
				changeState={changeState}
				changeAttend={changeAttend}
				activeStep={activeStep}
				handleReset={handleReset}
				handleNext={handleNext}
				handleBack={handleBack}
				addShare={addShare}
				removeShare={removeShare}
				shareValue={shareValue}
				onShareValueChange={onShareValueChange}
				isRequesting={isRequesting}
				setWeightOrNotValue={setWeightOrNotValue}
				weightOrNotValue={weightOrNotValue}
				setIsWeightOrNot={setIsWeightOrNot}
				isWeightOrNot={isWeightOrNot}
			></Modal>
			{alertError ? (
				<Snackbar
					title="خطأ"
					msg={alertErrorMsg}
					show={alertError}
					period={6000}
					severity={alertColor}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}

			{showEditModal ? (
				<EditModal
					show={showEditModal}
					hideEditModal={hideEditModal}
					data={vehicleEditFormData}
					vehicleToUpdate={vehicleToUpdate}
					changeEditState={changeEditState}
					changeEditAttend={changeEditAttend}
					edit={edit}
					classes={classes}
					vehiclesTypes={vehiclesTypes}
					changeFieldForEdit={changeFieldForEdit}
					changeClassForEdit={changeClassForEdit}
					changeVehicleTypeForEdit={changeVehicleTypeForEdit}
					formErrors={formErrors}
					setVehicleFormData={setVehicleEditFormData}
					shifts={shifts}

				></EditModal>
			) : null}
			{showDetailsModal ? (
				<DetailsModal
					data={vehicleToShow}
					show={showDetailsModal}
					shifts={shifts}
					closeDetailsModal={closeDetailsModal}
					unbindDriverFromVehicle={unbindDriverFromVehicle}
					assignNewDriver={assignNewDriver}
					fetchResources={fetchResources}
					listofChvs={listofChvs}
					setListOfChvs={setListOfChvs}
					setListOfChvsShift={setListOfChvsShift}
					listOfChvsShift={listOfChvsShift}
					setSelectedDriver={setSelectedDriver}
					selectedDriver={selectedDriver}
				></DetailsModal>
			) : null}
			{showAssignedDriversModal ? (
				<AssignedDriversModal
					closeAssignedDrivers={closeAssignedDrivers}
					vcsds={vcsds}
					unbindDriverFromVehicle={unbindDriverFromVehicle}
				/>
			) : null}
			{showAssignDriverModal ? (
				<AssignDriverToVehicleModal
					show={showAssignDriverModal}
					closeAssignModal={closeAssignModal}
					selectedDriver={selectedDriver}
					selectedVehicle={selectedVehicle}
					selectedCenter={selectedCenter}
					selectedShift={selectedShift}
					shiftsData={shiftsData}
					drivers={drivers}
					centers={centers}
					shifts={shifts}
					onDriverChange={onDriverChange}
					onCenterChange={onCenterChange}
					onShiftChange={onShiftChange}
					assign={assign}
					listofChvs={listofChvs}
					listOfChvsShift={listOfChvsShift}
					// setListOfChvs={setListOfChvs}
					isRequesting={isRequesting}
				></AssignDriverToVehicleModal>
			) : null}
		</Root>
	)
	function assign() {
		let result = validateAssign()
		if (result.length === 0) {
			let data = new FormData()

			data.append('vehicle_id', selectedVehicle.vehicle.id)
			data.append('center_id', selectedCenter.id)
			data.append('shift_id', selectedShift.id)
			data.append('driver_id', selectedDriver.id)

			// now make the request
			assignRequest(data)
		} else {
			setFormErrors(result)
		}
	}
	function validateAssign() {
		let errors = []
		if (
			!selectedCenter ||
			!selectedDriver ||
			!selectedShift ||
			!selectedVehicle
		) {
			errors && errors.push('يرجى التأكد من المدخلات!')
		}

		return errors
	}
	function onDriverChange(e, value) {
		setSelectedDriver(value)
	}
	function closeAssignModal() {
		setFormErrors([])
		setShowAssignDriverModal(false)
	}
	function assignNewDriver(vehicleObj) {
		getVehicleDetails(vehicleObj.vehicle.id)
		if (!drivers || !centers || !shifts) {
			setAlertError(true)
			setAlertErrorMsg('لم يتم تحميل المصادر بشكل كامل')
			setAlertColor('warning')
			return
		}
		setShiftsData(vehicleObj.vehicle.current_driver.map((item) => item.shift))
		setSelectedVehicle(vehicleObj)
		setFormErrors([])
		setTimeout(() => {
			setShowAssignDriverModal(true)
		}, 1200)
	}
	function assignRequest(data) {
		setIsRequesting(true)

		axios({
			url: url + `/drivers/bind`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				setAlertError(true)
				let msg = 'تم اسناد السائق بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '

					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors && errors.push(response.data.Error.details[i])
					}
				}

				setAlertErrorMsg(msg)
				setAlertColor('success')

				fetchVehicles()

				setSelectedDriver(drivers[0])
				setSelectedCenter(centers[0])
				setSelectedVehicle(vehicles[0])
				setSelectedShift(shifts[0])

				setShowAssignDriverModal(false)
				setSearch('')
				setIsRequesting(false)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setAlertColor('error')
				setAlertError(true)
				setAlertErrorMsg([errCodeObj.how_to_fix])
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	function unbindDriverFromVehicle(vcsd) {
		console.log('this is unbind', vcsd)
		if (vcsd) {
			let data = new FormData()
			data.append('driver_id', vcsd.driver.id)
			data.append('shift_id', vcsd.shift.id)
			unbindRequest(data)
		}
	}
	// unbind request
	function unbindRequest(data) {
		setIsRequesting(true)
		axios({
			url: url + `/drivers/unbind`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let msg = 'تم الغاء اسناد السائق بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '

					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors && errors.push(response.data.Error.details[i])
					}
				}

				setAlertErrorMsg(msg)
				setAlertColor('success')
				setShowAssignedDriversModal(false)
				setIsRequesting(false)
				fetchVehicles()
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setAlertError(true)
				setAlertErrorMsg([errCodeObj.how_to_fix])
				setAlertColor('error')
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	function showAssignedDrivers(vehicleObj) {
		setVcsds(vehicleObj.vehicle.current_driver)
		setShowAssignedDriversModal(true)
	}
	function closeAssignedDrivers() {
		setVcsds([])
		setShowAssignedDriversModal(false)
	}

	function openDetailsModal(vehicleObject) {
		getVehicleDetails(vehicleObject.vehicle.id)
		setVehicleToShow(vehicleObject)
		setShowDetailsModal(true)
	}

	function closeDetailsModal(e) {
		setShowDetailsModal(false)
		// fetchVehicles()
	}
	function onShareValueChange(e) {
		setShareValue(e.target.value)
	}
	function onDepartmentChange(e, value) {
		if (value) {
			setSelectedDepartment(value)
			setCenters(value.centers)
			if (value.centers.length > 0) setSelectedCenter(value.centers[0])
		}
	}

	function onCenterChange(e, value) {
		if (value) {
			setSelectedCenter(value)
		}
	}
	function onShiftChange(e, value) {
		setSelectedShift(value)
	}
	function onDriverChange(e, value) {
		setSelectedDriver(value)
	}
	function addShare(e) {
		if (!shareValue) {
			setFormErrors(['يرجى تحديد قيمة الحصة قبل الاضافة'])
			return
		}
		if (!weightOrNotValue) {
			setFormErrors(['يرجى تحديد قيمة معامل الوزن قبل الاضافة'])
			return
		}

		let sharesTemp = vehicleFormData.shares
		let object = {
			center_id: selectedCenter?.id,
			centerName: selectedCenter?.name,
			shift_id: selectedShift?.id,
			shiftName: selectedShift?.name,
			shareValue: shareValue,
			driver_id: selectedDriver?.id,
			driverName: selectedDriver?.name,
			coefficient_weight_or_not: weightOrNotValue,
			coefficient_weight_check: isWeightOrNot
		}
		let itemFound = sharesTemp && sharesTemp.find(function (item) {
			return (
				object?.shift_id === item?.shift_id && object?.driver_id === item?.driver_id
			)
		})
		if (itemFound) {
			setFormErrors(['لا يمكن اضافة معلومات متكررة'])
			return
		}

		sharesTemp && sharesTemp.push(object)
		// setIsWeightOrNot(true)
		// setWeightOrNotValue(null)
		setVehicleFormData({
			...vehicleFormData,
			shares: sharesTemp,
		})
	}
	function removeShare(e) {
		let sharesTemp = vehicleFormData.shares
		if (sharesTemp.length > 0) {
			sharesTemp.pop()
			setVehicleFormData({
				...vehicleFormData,
				shares: sharesTemp,
			})
		}
	}

	function handleNext() {
		if (activeStep === 0) {
			let result = validate()
			//    let result = [];
			if (result.length === 0) {
				setFormErrors([])
				setActiveStep((prevActiveStep) => prevActiveStep + 1)
			} else setFormErrors(result)
		} else if (activeStep === 1) {
			setFormErrors([])
			setActiveStep((prevActiveStep) => prevActiveStep + 1)
		} else {
			let data = {
				plate_number: vehicleFormData.plate_number,
				brand: vehicleFormData.brand,
				model: vehicleFormData.model,
				class_id: selectedClass.id,
				ration_for_stability: vehicleFormData.ration_for_stability,
				is_active: vehicleFormData.is_active ? '1' : '0',
				notes: vehicleFormData.karaj ? '1' : '0', // will use the notes field in DB for the karaj attendence instead of making a new field.
				vehicle_type_id: selectedVehicleType.id,
				attendPeerShift: vehicleFormData.karaj ? vehicleFormData?.attendPeerShift : []
			}
			if (vehicleFormData.shares && vehicleFormData.shares.length > 0) {
				data['shares'] = vehicleFormData.shares
			}

			makeRequest('vehicles', data)
		}
	}

	function karajChange(vehicleObj) {
		setIsLoading(true)
		let data = {
			plate_number: vehicleObj.vehicle.plate_number,
			class_id: vehicleObj.vehicle.class.id,
			is_active: vehicleObj.vehicle.is_active,
			notes: vehicleObj.vehicle.notes == '1' ? '0' : '1', // will use the notes field in DB for the karaj attendence instead of making a new field.
			vehicle_type_id: vehicleObj.vehicle.vehicle_type_id,
			ration_for_stability: vehicleObj.vehicle.ration_for_stability,
			_method: 'PUT',
		}
		editRequest(vehicleObj.vehicle, data)
	}

	function activeChange(vehicleObj) {
		setIsLoading(true)
		let data = {
			plate_number: vehicleObj.vehicle.plate_number,
			class_id: vehicleObj.vehicle.class.id,
			is_active: vehicleObj.vehicle.is_active == '1' ? '0' : '1',
			notes: vehicleObj.vehicle.notes, // will use the notes field in DB for the karaj attendence instead of making a new field.
			vehicle_type_id: vehicleObj.vehicle.vehicle_type_id,
			ration_for_stability: vehicleObj.vehicle.ration_for_stability,
			_method: 'PUT',
		}
		editRequest(vehicleObj.vehicle, data)
	}

	function handleBack() {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	function handleReset() {
		setActiveStep(0)
	}

	function onSearchChange(e) {
		setSearch(e.target.value)
	}
	function onSearchEnter(e) {
		if (e.key === 'Enter') {
			if (pageNumber == 1)
				fetchVehicles()
			else
				setPageNumber(1)
		}
	}

	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlertError(false)
	}
	async function openModal(e) {
		if (classes.length > 0 && vehiclesTypes.length > 0) {
			setSelectedClass(classes[0])
			setSelectedVehicleType(vehiclesTypes[0])
			setShowModal(true)
		} else alert('خلل في تحميل المصادر, يرجى اعادة تحميل الصفحة')
	}
	function hideModal(e) {
		setShowModal(false)
		handleReset()
		setVehicleFormData({
			...vehicleFormData,
			plate_number: '',
			brand: '',
			model: '',
			class: null,
			ration_for_stability: null,
			is_active: true,
			karaj: true,
			vehicleType: null,
			shares: [],
		})
		setFormErrors([])
	}
	function hideEditModal(e) {
		// reset data inputs
		setVehicleEditFormData({
			...vehicleEditFormData,
			id: 0,
			plate_number: '',
			brand: '',
			model: '',
			class: null,
			ration_for_stability: null,
			is_active: true,
			karaj: true,
			// notes: '',
			vehicleType: null,
		})
		setShowEditModal(false)
	}
	function changeState(e) {
		setVehicleFormData({
			...vehicleFormData,
			is_active: e.target.checked,
		})
	}
	function changeAttend(e) {
		setVehicleFormData({
			...vehicleFormData,
			karaj: e.target.checked,
		})
	}
	function changeEditState(e) {
		setVehicleEditFormData({
			...vehicleEditFormData,
			is_active: e.target.checked,
		})
	}
	function changeEditAttend(e) {
		setVehicleEditFormData({
			...vehicleEditFormData,
			karaj: e.target.checked,
		})
	}

	function changeField(e) {
		setVehicleFormData({
			...vehicleFormData,

			[e.target.name]: e.target.value,
		})
	}

	function changeClass(e, value) {
		setSelectedClass(value)
	}
	function changeVehicleType(e, value) {
		setSelectedVehicleType(value)
	}
	// when click on save button
	function create(e) {
		let result = validate()

		if (result.length === 0) {
			handleNext()
		} else setFormErrors(result)
	}

	function validate() {
		let errors = []

		if (!selectedClass) errors && errors.push('يجب اختيار فئة المركبة')

		if (!selectedVehicleType) errors && errors.push('يجب اختيار عائدية المركبة')

		if (vehicleFormData.plate_number.length < 4)
			errors && errors.push('يجب ان يكون رقم المركبة اكبر من 3 احرف')

		if (!vehicleFormData.ration_for_stability)
			errors && errors.push('يجب ادخال قيمة الادامة')

		if (vehicleFormData.karaj && vehicleFormData?.attendPeerShift?.length <= 0)
			errors && errors.push('يجب اختيار وجبة واحدة على الاقل')

		return errors
	}

	function makeRequest(route, data) {
		setIsRequesting(true)
		setFormErrors([])
		axios({
			url: url + `/` + route,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				setAlertError(true)
				let msg = 'تم اضافة المركبة بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '
					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors && errors.push(response.data.Error.details[i])
					}
					if (errors) setFormErrors(errors)
				} else {
					setFormErrors([])
				}
				setAlertErrorMsg(msg)
				setAlertColor('success')

				let vehiclesList = vehicles
				vehiclesList && vehiclesList.push(response.data.Data)

				setVehicles(vehiclesList)
				setUpdateVehicles(!updateVehicles)
				// clear inputs
				setVehicleFormData({
					...vehicleFormData,
					id: 0,
					plate_number: '',
					brand: '',
					model: '',
					class: null,
					ration_for_stability: null,
					is_active: true,
					karaj: true,
					notes: '',
					vehicleType: null,
					attendPeerShift: []
				})
				setActiveStep((prevActiveStep) => prevActiveStep + 1)
				setFormErrors([])
				setShowModal(false)
				setSearch('')
				setIsRequesting(false)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setFormErrors([errCodeObj.how_to_fix])
				setIsRequesting(false)
				setIsLoading(false)
			})
	}

	// edit actions
	function edit() {
		let result = editValidate()

		if (result?.length <= 0) {
			let data = {
				plate_number: vehicleEditFormData.plate_number,
				brand: vehicleEditFormData.brand,
				model: vehicleEditFormData.model,
				class_id: vehicleEditFormData.class.id,
				is_active: vehicleEditFormData.is_active ? '1' : '0',
				notes: vehicleEditFormData.karaj ? '1' : '0', // will use the notes field in DB for the karaj attendence instead of making a new field.
				vehicle_type_id: vehicleEditFormData.vehicleType.id,
				ration_for_stability: vehicleEditFormData.ration_for_stability,
				attendPeerShift: vehicleEditFormData.karaj ? vehicleEditFormData?.attendPeerShift ? vehicleEditFormData?.attendPeerShift : [] : [],
				_method: 'PUT',
			}
			editRequest(vehicleEditFormData, data)
		} else setFormErrors(result)
	}

	function editValidate() {
		let errors = []

		if (!vehicleEditFormData.class) errors && errors.push('يجب اختيار فئة المركبة')

		if (!vehicleEditFormData.vehicleType)
			errors && errors.push('يجب اختيار عائدية المركبة')

		if (vehicleEditFormData.plate_number.length < 3)
			errors && errors.push('يجب ان يكون رقم المركبة اكبر من 3 احرف')

		if (!vehicleEditFormData.ration_for_stability)
			errors && errors.push('يجب تحديد قيمة الادامة')
		console.log('asdasdsadsa----->check:', vehicleEditFormData.karaj, ',length:', vehicleEditFormData?.attendPeerShift?.length)
		if (vehicleEditFormData.karaj && (vehicleEditFormData?.attendPeerShift?.length <= 0 || vehicleEditFormData?.attendPeerShift?.length == null)) {
			errors.push('يجب اختيار وجبة واحدة على الاقل')
		}

		return errors
	}
	// edit request
	function editRequest(vehicleObj, data) {
		setFormErrors([])
		axios({
			url: url + `/vehicles/` + vehicleObj.id,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let vehicleList = vehicles
				vehicleList[vehicleObj.index] = response.data.Data
				setVehicles(vehicleList)
				setUpdateVehicles(!updateVehicles)
				setVehicleEditFormData({
					...vehicleFormData,
					id: 0,
					plate_number: '',
					brand: '',
					model: '',
					class: null,
					ration_for_stability: null,
					is_active: true,
					karaj: true,
					notes: '',
					vehicleType: null,
					attendPeerShift: []
				})
				setFormErrors([])
				setShowEditModal(false)
				setAlertError(true)
				setAlertErrorMsg('تم تعديل بيانات المركبة بنجاح')
				setAlertColor('success')
				setSearch('')
			})
			.catch((error) => {
				let errors = handleError(error)
				let errorsArr = [errors]
				setFormErrors(errorsArr)
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}
	function changeFieldForEdit(e) {
		setVehicleEditFormData({
			...vehicleEditFormData,

			[e.target.name]: e.target.value,
		})
	}
	function changeClassForEdit(e, value) {
		setVehicleEditFormData({
			...vehicleEditFormData,

			class: value,
		})
	}
	function changeVehicleTypeForEdit(e, value) {
		setVehicleEditFormData({
			...vehicleEditFormData,

			vehicleType: value,
		})
	}
	function showEditModalFun(vehicleObj) {
		let vehicleClass = classes && classes.find(function (item) {
			return item.id === vehicleObj.vehicle.class.id
		})
		let vehicleType = vehiclesTypes && vehiclesTypes.find(function (item) {
			return item.id === vehicleObj.vehicle.vehicle_type.id
		})
		console.log('asdasdasdasdas====>', vehicleObj)
		let shiftIds = vehicleObj?.vehicle && vehicleObj?.vehicle?.vehcile_shiftfor_month_attend?.length && vehicleObj?.vehicle?.vehcile_shiftfor_month_attend?.map(item => item?.id)

		setVehicleEditFormData({
			...vehicleEditFormData,
			index: vehicleObj.index,
			id: vehicleObj.vehicle.id,
			plate_number: vehicleObj.vehicle.plate_number,
			brand: vehicleObj.vehicle.brand,
			model: vehicleObj.vehicle.model,
			class: vehicleClass,
			is_active: vehicleObj.vehicle.is_active === '1' ? true : false,
			karaj: vehicleObj.vehicle.notes === '1' ? true : false, //using the notes field as karaj indicator
			vehicleType: vehicleType,
			ration_for_stability: vehicleObj.vehicle.ration_for_stability,
			attendPeerShift: shiftIds
		})
		setShowEditModal(true)
		// setUserToUpdate(userObj)
	}

	// end of edit actions

	function exportFreights(vehicleObj, index) {
		setFormErrors([])
		axios({
			url: url + `/vehicles/${vehicleObj.vehicle.id}/freights/export`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: {},
		})
			.then((response) => {
				window.open(response.data.Data)
				setAlertErrorMsg('تم تحميل ملف الشخنات')
				setAlertColor('success')
			})
			.catch((error) => {
				let errors = handleError(error)
				let errorsArr = [errors]
				setFormErrors(errorsArr)
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}
}

export default Vehicles
