import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Grow from '@material-ui/core/Grow';

const Root = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
border-radius:10px;
margin:10px;
width:100%;
padding:5px;
`;
function SubFreightCard(props) {
    return (
        <Grow in={true}>
            <Root
                style={{
                    backgroundColor: props.bg
                }}>
                <p>{props.numbering}</p>
                <p>{props.data.station.caption}</p>
                <p>{props.data.weight}</p>
                <p>{props.data.at_time}</p>
                <IconButton
                    onClick={() => props.deleteSubFreightCard(props.data.station.id, props.data.weight, props.data.at_time)}
                >
                    <RemoveCircleIcon style={{ color: 'red' }} />
                </IconButton>
            </Root>
        </Grow>

    )
}

export default SubFreightCard
