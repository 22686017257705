import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'
import MyTableRow from '../../common/TableRow'
import MyTableCell from './TableCell'
import GetAppIcon from '@material-ui/icons/GetApp'
import AssignmentIcon from '@material-ui/icons/Assignment'
import PlusOneIcon from '@material-ui/icons/PlusOne'
import AddCircleIcon from '@material-ui/icons/AddCircle'
const crudColors = require('../../../assets/files/env.json').theme.colors.crud

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		borderBottom:'2px solid #c4ddec'
	},
}))(TableRow)

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
})
const styles = (theme) => ({
	table: {
		minWidth: 700,
		maxHeight: '100%',
	},
})

class CustomizedTables extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.data === nextProps.data) {
			return false
		} else {
			return true
		}
	}

	render() {
		const { classes } = this.props
		let prop = this.props
		let numbering = 0
		let countResult = ((this.props?.pageNumber-1)*this.props?.pageSize)


		return (
			<TableContainer
				style={{
					minHeight: '300px',
					maxHeight: '100%',
				}}
				component={Paper}
			>
				<Table
					size="small"
					className={classes.table}
					aria-label="customized table"
				>
					<TableHead>
						<MyTableRow header={this.props.header}></MyTableRow>
					</TableHead>
					<TableBody>
						{this.props.data.map(function (item, index) {
							numbering++
							return (
								<StyledTableRow key={index}>
									<MyTableCell
										key={'veh_a' + index}
										value={numbering+countResult}
									></MyTableCell>
									<MyTableCell
										key={'veh_b' + index}
										value={item.plate_number}
									></MyTableCell>
									{/* <MyTableCell
										key={'veh_c' + index}
										value={item.brand}
									></MyTableCell> */}
									{/* <MyTableCell
										key={'veh_d' + index}
										value={item.model}
									></MyTableCell> */}
									{item.current_driver && item.current_driver.length > 0 ? (
										item.current_driver.length === 1 ? (
											<StyledTableCell
												key={'veh_drivers_name' + index}
												align="right"
											>
												<IconButton
													onClick={() =>
														prop.showAssignedDrivers({
															vehicle: item,
															index: index,
														})
													}
												>
													<p
														style={{
															fontSize: '16px',
															fontFamily: 'Cairo',
														}}
													>
														{' '}
														{item.current_driver
															? item.current_driver[0].driver.name
															: '--'}
													</p>
												</IconButton>
											</StyledTableCell>
										) : (
											<StyledTableCell
												key={'veh_drivers_show' + index}
												align="right"
											>
												<IconButton
													onClick={() =>
														prop.showAssignedDrivers({
															vehicle: item,
															index: index,
														})
													}
												>
													<PlusOneIcon style={{ color: crudColors.r }} />
												</IconButton>
											</StyledTableCell>
										)
									) : <StyledTableCell
												key={'veh_drivers_show' + index}
												align="right"
											></StyledTableCell>}
									<MyTableCell
										key={'veh_f' + index}
										value={item.ration_for_stability}
									></MyTableCell>

									<MyTableCell
										key={'veh_i' + index}
										value={item.vehicle_type.name}
									></MyTableCell>
									<MyTableCell
										key={'veh_j' + index}
										value={item.freights_count}
									></MyTableCell>

									<StyledTableCell key={'veh_g' + index} align="right">
										<IconButton
											onClick={() =>
												prop.activeChange({
													vehicle: item,
													index: index,
												})
											}
										>
											{item.is_active === '1' ? (
												<CheckCircleIcon style={{ color: crudColors.u }} />
											) : (
												<BlockIcon style={{ color: 'red' }} />
											)}
										</IconButton>
									</StyledTableCell>

									<StyledTableCell key={'veh_karaj' + index} align="right">
										<IconButton
											onClick={() =>
												prop.karajChange({
													vehicle: item,
													index: index,
												})
											}
										>
											{item.notes === '1' ? (
												<CheckCircleIcon style={{ color: crudColors.k }} />
											) : (
												<BlockIcon style={{ color: 'red' }} />
											)}
										</IconButton>
									</StyledTableCell>

									<StyledTableCell key={'veh_details' + index} align="right">
										<IconButton
											onClick={() =>
												prop.openDetailsModal({
													vehicle: item,
													index: index,
												})
											}
										>
											<AssignmentIcon style={{ color: crudColors.r }} />
										</IconButton>
									</StyledTableCell>

									<StyledTableCell key={'veh_k' + index} align="right">
										<IconButton
											onClick={() =>
												prop.exportFreights({
													vehicle: item,
													index: index,
												})
											}
										>
											<GetAppIcon style={{ color: crudColors.export }} />
										</IconButton>
									</StyledTableCell>
									<StyledTableCell key={'veh_l' + index} align="right">
										<IconButton
											onClick={() =>
												prop.showEditModalFun({
													vehicle: item,
													index: index,
												})
											}
										>
											<EditIcon style={{ color: crudColors.u }} />
										</IconButton>
									</StyledTableCell>
								</StyledTableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
}

export default withStyles(styles, { withTheme: true })(CustomizedTables)
