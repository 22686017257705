import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { getColor } from '../../common/Utils'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import CheckBox from '../../common/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Alert from '../../common/Alert'
import Grid from '@material-ui/core/Grid'
import AddVehicleStepper from './addVehicleStepper'
import { useEffect } from 'react'
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		backgroundColor: getColor('primary').dark,
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	textField: {
		width: '100%',
		marginTop: '10px',
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const Root2 = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
`
export default function FullScreenDialog(props) {
	const classes = useStyles()

	//  useEffect(()=>{
	//   document.addEventListener('keydown',(event)=>{
	//     if (event.keyCode === 13 || event.which === 13&&props.show) {
	//       // if(props.activeStep==3)
	//       // props.create();
	//       // else
	//       props.handleNext()
	//     }
	//   });
	//  },[props.show,props.activeStep,props?.formErrors])
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullScreen
					open={props.show}
					onClose={props.hideModal}
					TransitionComponent={Transition}
					onKeyDown={(event) => {
						if (event.keyCode === 13 || (event.which === 13 && props.show)) {
							props.handleNext()
						}else if (event.key=="Escape"){
                            props.hideModal()
						}
					}}
				>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton
								edge="end"
								color="inherit"
								onClick={props.hideModal}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={classes.title}>
								اضافة مركبة جديد
							</Typography>
							<Button autoFocus color="inherit" onClick={props.create}>
								حفظ
							</Button>
						</Toolbar>
					</AppBar>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Root2>
								<AddVehicleStepper
									vehiclesTypes={props.vehiclesTypes}
									changeField={props.changeField}
									setVehicleFormData={props?.setVehicleFormData}
									data={props.data}
									changeState={props.changeState}
									changeAttend={props.changeAttend}
									classes={props.classes}
									selectedClass={props.selectedClass}
									changeClass={props.changeClass}
									changeVehicleType={props.changeVehicleType}
									selectedVehicleType={props.selectedVehicleType}
									activeStep={props.activeStep}
									handleReset={props.handleReset}
									handleNext={props.handleNext}
									handleBack={props.handleBack}
									departments={props.departments}
									shifts={props.shifts}
									centers={props.centers}
									drivers={props.drivers}
									addShare={props.addShare}
									removeShare={props.removeShare}
									onDepartmentChange={props.onDepartmentChange}
									onCenterChange={props.onCenterChange}
									onShiftChange={props.onShiftChange}
									onDriverChange={props.onDriverChange}
									selectedDepartment={props.selectedDepartment}
									selectedCenter={props.selectedCenter}
									selectedShift={props.selectedShift}
									selectedDriver={props.selectedDriver}
									onShareValueChange={props.onShareValueChange}
									shareValue={props.shareValue}
									isRequesting={props.isRequesting}
									setWeightOrNotValue={props.setWeightOrNotValue}
									weightOrNotValue={props.weightOrNotValue}
									setIsWeightOrNot={props.setIsWeightOrNot}
									isWeightOrNot={props.isWeightOrNot}
								></AddVehicleStepper>
							</Root2>
						</Grid>

						<Grid item xs={6}>
							{props.formErrors.length > 0 ? (
								<Alert
									title="مدخلات غير صحيحة"
									msg={props.formErrors}
									severity="error"
								></Alert>
							) : null}
						</Grid>
					</Grid>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
