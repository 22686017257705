import React from 'react'

function Reasons() {
    return (
        <div>
            Reasons
        </div>
    )
}

export default Reasons
