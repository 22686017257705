import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Cookies from 'js-cookie'

export default function SimpleMenu(props) {
	//   const [anchorEl, setAnchorEl] = React.useState(null);

	return (
		<div>
			<Menu
				id="profile_menu"
				anchorEl={props.anchorEl}
				keepMounted
				open={Boolean(props.showMenu)}
				onClose={props.handleCloseProfileMenu}
			>
				<MenuItem onClick={e => logout(e)}>تسجيل خروج</MenuItem>
			</Menu>
		</div>
	)

	function logout(e) {
		Cookies.remove('SDIMS_USER_DATA')
		window.location.href = '/'
	}
}
