import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DayDate from '../../common/DatePicker'
import CheckBox from '../../common/CheckBox'
import BegTimePicker from '../../common/TimePicker'
import EndTimePicker from '../../common/TimePicker'
import TotalParkingTime from '../../common/TimePicker'
import TextField from '@material-ui/core/TextField'
import SubFreightCard from './SubFreightCard'
import SubControl from './SubControls'
import Grid from '@material-ui/core/Grid'
import { TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import EngineHoursPicker from '../../common/TimePicker'
import WorkingTimePicker from '../../common/TimePicker'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import { useState } from 'react'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const colors = require('../../../assets/files/env.json').theme.colors

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
`
const SubFContainers = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	background-color: '#fff';
`

const DataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const DataDivRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '600px',
		backgroundColor: '#fff',
	},
	pickers: {
		backgroundColor: '#fff',
	},
	textField: {
		backgroundColor: '#fff',
	},
}))

const getDisabledDepartmentName = (vehicle, shift) => {
	const neededShift = vehicle.shifts.filter((sh) => {
		return sh.shift_id === shift.id
	})
	if (neededShift && neededShift[0] && neededShift[0].department_name) {
		return neededShift[0].department_name
	} else {
		return ''
	}
}

const getDisabledCenterName = (vehicle, shift) => {
	const neededShift = vehicle.shifts.filter((sh) => {
		return sh.shift_id === shift.id
	})

	if (neededShift && neededShift[0] && neededShift[0].center_name) {
		return neededShift[0].center_name
	} else {
		return ''
	}
}

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	)
}

export default function DraggableDialog(props) {
	const classes = useStyles()

	const [deselectAll, setDeselectAll] = useState(false)

	const deselectAllOptions = () => {
		props.fieldsData.showTPT = false
		props.fieldsData.showBegTime = false
		props.fieldsData.showEndTime = false
	}

	const selectAllOptions = () => {
		props.fieldsData.showTPT = true
		props.fieldsData.showBegTime = true
		props.fieldsData.showEndTime = true
	}

	const [locationSelected, setLocationSelected] = useState(
		props.fieldsData.selectedLocation
	)
	useEffect(() => {
		// console.log(locationSelected.name)
		if (!props.selectedReason && !props.fieldsData.is_present) {
			let e = null
			if (props.reasons.length > 0) {
				props.onReasonChange(e, props.reasons[0])
			}
		}
	}, [props.fieldsData.is_present])
	const handleEnteredEvent = (e) => {
		if (!props.isRequesting) {
			if (e.key === 'Enter') {
				props.edit()
			} else if (e.key === 'Escape') {
				props.closeEditModal()
			}
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullWidth={true}
					maxWidth="lg"
					disableBackdropClick={true}
					open={props.open}
					onClose={props.closeEditModal}
					PaperComponent={PaperComponent}
					aria-labelledby="draggable-dialog-title"
					onKeyDown={handleEnteredEvent}
				>
					<DialogTitle
						style={{
							backgroundColor: '#007AC4',
							color: '#fff',
							cursor: 'move',
						}}
						id="draggable-dialog-title"
					>
						واجهة تعديل سجل المركبة اليومي
					</DialogTitle>

					<DialogContent
						style={{
							backgroundColor: '#DEF3FF',
						}}
					>
						<Grid container direction="row" spacing={3}>
							<Grid item xs={6}>
								<Root>
									<Autocomplete
										className={classes.autocomplete}
										id="vehicles"
										name="selectedVehicle"
										value={
											props.selectedVehicle
												? props.selectedVehicle
												: props.vehicles[0]
										}
										options={props.vehicles.filter(
											(item) => !(item.is_active == '0')
										)}
										onChange={props.onVehicleChange}
										getOptionDisabled={(option) => {
											return (
												(option.shifts && option.shifts.length === 0) ||
												option.is_active == '0'
											)
										}}
										getOptionLabel={(option) =>
											option.plate_number +
											' - ' +
											(option.brand ? option.brand : '') +
											' - ' +
											(option.current_driver
												? option.current_driver.driver.name
												: '')
										}
										style={{ width: 300 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="الاليات"
												variant="outlined"
											/>
										)}
									/>
									<div
										style={{
											width: 600,
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<Autocomplete
											className={classes.autocomplete}
											id="shifts"
											name="selectedShift"
											value={
												props.selectedShift
													? props.selectedShift
													: props.shifts[0]
											}
											options={props.filteredShifts}
											onChange={(e, value) => {
												props.onVehicleChange2(value)
												props.onShiftChange(e, value)
											}}
											getOptionLabel={(option) => option.name}
											style={{ minWidth: 0, width: 160 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label="الوجبات"
													variant="outlined"
												/>
											)}
										/>
										<TextField
											className={classes.autocomplete}
											disabled
											id="disabled-department"
											variant="outlined"
											label="القسم"
											value={
												props.selectedVehicle
													? props.selectedShift
														? getDisabledDepartmentName(
															props.selectedVehicle,
															props.selectedShift
														)
														: ''
													: ''
											}
											style={{
												minWidth: 0,
												width: 200,
												backgroundColor: '#ededed',
											}}
										/>
										<TextField
											className={classes.autocomplete}
											disabled
											variant="outlined"
											id="disabled-center"
											label="المركز والشعبة"
											value={
												props.selectedVehicle
													? props.selectedShift
														? getDisabledCenterName(
															props.selectedVehicle,
															props.selectedShift
														)
														: ''
													: ''
											}
											style={{
												minWidth: 0,
												width: 200,
												backgroundColor: '#ededed',
											}}
										/>
									</div>
									<DayDate
										className={classes.pickers}
										style={{
											padding: '15px',
										}}
										title="اليوم"
										selectedDate={props.selectedDayDate}
										handleDateChange={props.onDayDateChange}
									/>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<CheckBox
											name="is_present"
											label="حاضر؟"
											state={props.fieldsData.is_present}
											changeState={props.changeIsPresent}
										></CheckBox>

										<CheckBox
											name="is_present"
											label="الغاء الخيارات ادناه"
											state={props.fieldsData.unselectall}
											changeState={(e) => {
												if (props.fieldsData.unselectall) {
													props.selectAllOptions(locationSelected)
												} else {
													props.deselectAllOptions()
												}
											}}
										></CheckBox>
									</div>

									{props.fieldsData.is_present ? (
										<DataDiv>
											<DataDivRow>
												<Autocomplete
													className={classes.autocomplete}
													id="locations"
													name="selectedLocation"
													defaultValue={props.locations?.find(
														(v) => v.name == locationSelected?.name
													)}
													options={props.locations ? props.locations : []}
													onChange={(e, value) => {
														setLocationSelected(value)
														props.onLocationChange(e, value)
													}}
													onInputChange={(event, newInputValue, reason) => {
														// console.log(event, reason)
														if (locationSelected && reason === 'clear')
															props.clearSeletedLocation(reason)
														else setLocationSelected(newInputValue)
													}}
													getOptionLabel={(option) => option.name}
													disabled={!props.fieldsData.showBegTime}
													style={{ minWidth: 0, width: 200 }}
													renderInput={(params) => {
														if (params?.disabled)
															return (
																<TextField
																	disabled={true}
																	value={''}
																	label="موقع الانطلاق"
																	variant="outlined"
																/>
															)
														else
															return (
																<TextField
																	{...params}
																	value={
																		params?.disabled
																			? ''
																			: params?.inputProps?.value
																	}
																	label="موقع الانطلاق"
																	variant="outlined"
																/>
															)
													}}
												/>
												{/* <TextField
													className={classes.textField}
													id="trip_beg_location"
													name="trip_beg_location"
													label="موقع الانطلاق"
													variant="outlined"
													onChange={props.changeField}
													value={props.fieldsData.trip_beg_location}
												/> */}
												<CheckBox
													name="showBegTime"
													label="وقت الانطلاق؟"
													state={props.fieldsData.showBegTime}
													changeState={(e) => {
														props.changeShowBegTime(e, locationSelected)
													}}
												/>
												{props.fieldsData.showBegTime ? (
													<BegTimePicker
														style={{
															padding: '15px',
															marginRight: '15px',
														}}
														className={classes.pickers}
														title="وقت الانطلاق"
														selectedDate={props.fieldsData.selectedBegTime}
														handleDateChange={props.onBegTimeChange}
													/>
												) : null}
											</DataDivRow>

											<DataDivRow>
												<CheckBox
													name="show_end_time"
													label="وقت العودة؟"
													state={props.fieldsData.showEndTime}
													changeState={props.changeShowEndTime}
												/>
												{props.fieldsData.showEndTime ? (
													<EndTimePicker
														className={classes.pickers}
														style={{
															padding: '15px',
														}}
														title="وقت العودة"
														selectedDate={props.fieldsData.selectedEndTime}
														handleDateChange={props.onEndTimeChange}
													/>
												) : null}
											</DataDivRow>

											<DataDivRow>
												<CheckBox
													name="showTPT"
													label="اضافة مجموعة التوقفات؟"
													state={props.fieldsData.showTPT}
													changeState={props.changeTPT}
												/>
												{props.fieldsData.showTPT ? (
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<TimePicker
															clearable
															ampm={false}
															label="مجموع التوقفات"
															value={props.fieldsData.selectedTotalParkingTime}
															onChange={props.onTotalParkingTimeChange}
														/>
													</MuiPickersUtilsProvider>
												) : null}
											</DataDivRow>
										</DataDiv>
									) : (
										<DataDiv>
											<Autocomplete
												className={classes.autocomplete}
												id="reasons"
												name="selectedReason"
												defaultValue={() =>
													props.selectedReason?.reasons?.find((r) => r)
												}
												value={
													props.selectedReason
														? props.selectedReason
														: props.reasons[0]
												}
												options={props.reasons}
												onChange={props.onReasonChange}
												getOptionLabel={(option) => option.name}
												style={{ width: 300 }}
												renderInput={(params) => (
													<TextField
														{...params}
														label="الاسباب"
														variant="outlined"
													/>
												)}
											/>
										</DataDiv>
									)}

									{/*               
              <CheckBox
                name='status'
                label='حالة السجل؟'
                state={props.fieldsData.status}
                changeState={props.changeFormStatus}
              >

              </CheckBox> */}
								</Root>
							</Grid>
							<Grid item xs={6}>
								<DataDiv>
									<DataDivRow>
										<CheckBox
											name="showEH"
											label="ساعات عمل المحرك"
											state={props.fieldsData.showEH}
											changeState={props.changeShowEH}
										/>
										{props.fieldsData.showEH ? (
											<EngineHoursPicker
												className={classes.pickers}
												ampm={false}
												style={{
													padding: '15px',
												}}
												title="ساعات عمل المحرك"
												selectedDate={props.fieldsData.engineHours}
												handleDateChange={props.onEngineHoursChange}
											/>
										) : null}
									</DataDivRow>
									<DataDivRow>
										<CheckBox
											name="showWT"
											label="ساعات الحركة"
											state={props.fieldsData.showWT}
											changeState={props.changeShowWT}
										/>
										{props.fieldsData.showWT ? (
											<WorkingTimePicker
												className={classes.pickers}
												ampm={false}
												style={{
													padding: '15px',
												}}
												title="الحركة"
												selectedDate={props.fieldsData.workingTime}
												handleDateChange={props.onWorkingTimeChange}
											/>
										) : null}
									</DataDivRow>
								</DataDiv>
							</Grid>
						</Grid>
					</DialogContent>

					<DialogActions>
						<Button autoFocus onClick={props.closeEditModal} color="primary">
							الغاء
						</Button>
						<Button
							disabled={props.isRequesting}
							onClick={props.edit}
							color="primary"
						>
							حفظ
						</Button>
					</DialogActions>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
