import Cookies from 'universal-cookie'
const cookies = new Cookies()

export const handleError = err => {
	if (!err) {
		return 'Unknown Response'
	}
	if (err.response === undefined) {
		return 'Network, Request or server side error!'
	}
	let errCode = err.response.status

	if (errCode) {
		if (errCode === 400) {
			let msg = ''
			for (let item in err.response.data.Error) {
				msg = item + ',' + '\n'
			}

			return msg
		}
		if (errCode === 409)
			// conflict
			return 'المعلومات مضافة فعلا في النظام ومكررة'
		if (errCode === 422) {
			return 'يجب على الاقل تحديث معلومة واحدة على الاقل'
		}
		if (errCode === 401)
			return 'خطأ في معلومات الدخول او قد يكون الحساب غير مخول او محظور'
		if (errCode === 500) return 'تعذر الاجراء, يوجد مشكلة مؤقتة في الخادم'
		if (errCode === 422)
			return 'يجب تحديث قيمة معلومة واحدة على الاقل من المعلومات من اجل التحديث'

		return errCode
	} else {
		return 0
	}
}

export const getStatusCode = err => {
	if (!err) {
		return -1 // 'Unknown Response';
	}
	if (err.response === undefined) {
		return -2 //'Network, Request or server side error!';
	}
	let errCode = err.response.status

	return errCode
}

export const formatErrorMgs = errors => {
	if (!errors) return 'خطأ غير معروف'
	if (!errors.response) return 'Network, request or server issue!'

	let msg = ''
	let errs = errors.response.data.Error || errors.response.data.error

	if (typeof errs === 'string') msg = errs + ', ' + '\n' + msg

	if (typeof errs === 'object') {
		for (let [k, v] of Object.entries(errs)) {
			msg = v + ' , ' + '\n' + msg
		}
	}

	return msg
}

export const handleErr = (code, error) => {
	let errCodeObj = {
		status: 0,
		title: '',
		server_errors: '',
		how_to_fix: ''
	}

	switch (code) {
		case 400:
			errCodeObj.status = code
			errCodeObj.title = 'فشل الطلب'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = 'تأكد من المدخلات واعد المحاولة'
			break

		case 401:
			window.location.href = '/'
			Cookies.remove('SDIMS_USER_DATA')
			break

		case 409:
			errCodeObj.status = code
			errCodeObj.title = 'لم يتم اكمال العملية.'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix =
				'ان المعلومات المدخلة متكررة, يرجى التأكد من ادخال معلومات جديدة'
			break

		case 403:
			errCodeObj.status = code
			errCodeObj.title = 'عملية غير مخولة!'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = 'لا تمتلك صلاحيات للقيان بهذا الطلب'
			break
		case 404:
			errCodeObj.status = code
			errCodeObj.title = 'لا توجد بيانات'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = formatErrorMgs(error)
			break

		case 405:
			errCodeObj.status = code
			errCodeObj.title = 'خطأ في الطلب او الخادم'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = 'يرحى التواصل مع الدعم الفني'
			break

		case 422:
			errCodeObj.status = code
			errCodeObj.title = 'لم يتم التعديل'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = 'يرجى تحديث على الاقل قيمة واحدة'
			break
		case 500:
			errCodeObj.status = code
			errCodeObj.title = 'خطأ في الخادم'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = 'يرحى التواصل مع الدعم الفني'
			break

		case 204:
			errCodeObj.status = code
			errCodeObj.title = 'لا توجد بيانات'
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = 'لا توجد بيانات طبقا للمعطيات المرسلة'
			break

		default:
			errCodeObj.status = code
			errCodeObj.title = 'خطأ غير معروف ' + code
			errCodeObj.server_errors = formatErrorMgs(error)
			errCodeObj.how_to_fix = 'يرحى التواصل مع الدعم الفني'
			break
	}
	return errCodeObj
}

export const formatDate = date => {
	let d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear()

	if (month.length < 2) month = '0' + month
	if (day.length < 2) day = '0' + day

	return [year, month, day].join('-')
}
export const checkObj = obj => {
	if (Object.entries(obj).length === 0 && obj.constructor === Object) {
		return false
	}
	return true
}

export const formatTime = d => {
	let h = d.getHours()
	let m = d.getMinutes()
	let s = d.getSeconds()
	return h + ':' + m + ':' + s
}

export const getColor = name => {
	let colors = require('../../assets/files/env.json').theme.colors
	return colors[name]
}

export const getBaseURL = () => {
	const settings = require('../../assets/files/settings.json')
	const mode = settings['dev_mode']

	const http = settings[mode].http

	const hostname = settings[mode].hostname
	const prefix = settings[mode].api_prefix

	const base_url = http + '://' + hostname + prefix
	return base_url
}

export const getSettings = () => {
	return require('../../assets/files/settings.json')
}

export const configureDate = date => {
	let y = date.getFullYear()

	let m = date.getMonth() + 1
	if (m < 10) m = '0' + m

	let d = date.getDate()
	if (d < 10) d = '0' + d

	let result = y + '-' + m + '-' + d
	return result
}

export const configureMonth = date => {
	let m = date.getMonth() + 1

	if (m < 10) {
		m = '0' + m
	}
	let y = date.getFullYear()

	return y + '-' + m
}

export const createTimeFromStr = str => {
	if (!str) return new Date()

	let arr = str.split(':')

	let d = new Date()
	d.setHours(arr[0])
	d.setMinutes(arr[1])
	if (arr.length > 2) d.setSeconds(arr[2])

	// console.log('date is ', d)
	return d
}
export const createTimeFromStrAmPm = (str,day_date) => {
	if (!str) return new Date(day_date)

	var parts = str
		.replace(/am|pm/, '')
		.trim()
		.split(':')
	var hours = parseInt(parts[0])
	console.log('hours:',hours);
	hours = (parseInt(parts[0]) +(parseInt(parts[0])<12?(str.indexOf('pm')!=-1? 12:0):0))
	console.log('hours:',hours);
	var minutes = parts[1]

	var date = new Date(day_date)
	date.setHours(hours)
	date.setMinutes(minutes)

	return date
}
export const createTimeFromStrAmPm2=(str,day_date)=>{
	if (!str) return new Date(day_date)
   console.log(str);
	var parts = str
		.replace(/am|pm/, '')
		.trim()
		.split(':')
	var hours = parseInt(parts[0]) + (str.indexOf('pm') !== -1 ? 12 : 0)
	var minutes = parts[1]

	var date = new Date(day_date)
	date.setHours(hours)
	date.setMinutes(minutes)
	console.log(date);

	return date


}

export const configureTime = date => {
	if(!date){
		return '00:00:00'
	}
	let h = date.getHours()
	if (h < 10) h = '0' + h

	let m = date.getMinutes()
	if (m < 10) m = '0' + m

	let s = date.getSeconds()
	if (s < 10) s = '0' + s

	let result = h + ':' + m + ':' + s
	return result
}

export const formatErrorMgs2 = errorsArr => {
	let msg = ''
	for (let i = 0; i < errorsArr.length; i++) {
		msg = msg + ' || ' + errorsArr[i]
	}
	return msg
}
export const formatDrivers = drivers => {
	let msg = ''
	for (let i = 0; i < drivers.length; i++) {
		msg = msg + ' || ' + drivers[i].name
	}
	return msg
}
// export const getCookies = () =>{

//     return cookies.get('userData');
// }

// export  const setCookies = async  (data) =>{
//     await cookies.set("userData", JSON.stringify(data), {

//         path: "/",
//         expires: new Date(Date.now() + (24 * 3 * 60 * 60 * 1000)) // 3 days

//     });
//     return await cookies.get('userData');
// }
