import React from 'react'
import styled from 'styled-components'
import Avatar from './avatar'
const Root = styled.div`
	display: flex;
	flex-direction: row;

	margin: 5px;
	padding: 5px;
`

const Name = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
function ProfileSection(props) {
	return (
		<Root>
			<Avatar
				showProfileMenu={props.showProfileMenu}
				username={props.data.userInfo.username}
			></Avatar>
			<Name>{props.data.userInfo.name}</Name>
		</Root>
	)
}

export default ProfileSection
