import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { getColor } from '../../common/Utils'
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import CheckBox from '../../common/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '../../common/Alert'
import Grid from '@material-ui/core/Grid';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


const theme = createMuiTheme({
  direction: 'rtl',
  typography: {
    fontFamily: [
      'Cairo',
      'Arial',
      'sans-serif'
    ].join(','),
  },

});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: getColor('primary').dark
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    width: '100%',
    marginTop: '10px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Root2 = styled.div`

display:flex;
flex-direction:column;
padding:40px;
width:100%;
`;
export default function FullScreenDialog(props) {

  const classes = useStyles();
  const getCheck = (id) => {
    if (props?.data && props?.data?.attendPeerShift?.length) {
      return props?.data?.attendPeerShift?.includes(id);
    } else return false
  }
  const attendSelect = (id) => {
    if (props?.data && props?.data?.attendPeerShift?.length) {
      let check = props?.data?.attendPeerShift?.find(item => item == id)
      if (!check) {
        let arr = [...props?.data?.attendPeerShift]
        arr.push(id)
        props?.setVehicleFormData({
          ...props?.data,
          attendPeerShift: arr
        })
      } else {
        let data = props?.data?.attendPeerShift?.filter(item => item !== id)
        props?.setVehicleFormData({
          ...props?.data,
          attendPeerShift: data
        })
      }
    } else {
      props?.setVehicleFormData({
        ...props?.data,
        attendPeerShift: [id]
      })
    }
  }
  const handleEnteredEvent = (e) => {
    if (e.key === 'Enter') {
      props.edit()
    } else if (e.key === 'Escape') {
      props.hideEditModal()
    }
  }
  return (

    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Dialog 
        fullScreen 
        open={props.show} 
        onClose={props.hideEditModal} 
        TransitionComponent={Transition}
        onKeyDown={handleEnteredEvent}
      >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="end" color="inherit" onClick={props.hideEditModal} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                تعديل بيانات المركبة
              </Typography>
              <Button autoFocus color="inherit" onClick={props.edit}>
                حفظ
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Root2>
                <TextField
                  className={classes.textField}
                  required
                  id="plate_number"
                  label="رقم المركبة"
                  name="plate_number"

                  variant="outlined"
                  onChange={props.changeFieldForEdit}
                  value={props.data['plate_number']}
                />

                <TextField
                  className={classes.textField}
                  required
                  id="brand"
                  name="brand"
                  label="نوع المركبة"

                  variant="outlined"
                  onChange={props.changeFieldForEdit}
                  value={props.data['brand']}
                />
                <TextField
                  className={classes.textField}
                  required
                  id="model"
                  name="model"
                  label="الموديل "

                  variant="outlined"
                  onChange={props.changeFieldForEdit}
                  value={props.data['model']}
                />

                <TextField
                  className={classes.textField}
                  required
                  id="ration_for_stability"
                  name="ration_for_stability"
                  label="قيمة الادامة "

                  variant="outlined"
                  onChange={props.changeFieldForEdit}
                  value={props.data['ration_for_stability']}
                />
                <CheckBox
                  name='vehicle_state'
                  label='تفعيل نشاط المركبة؟'
                  state={props.data.is_active}
                  changeState={props.changeEditState}
                >

                </CheckBox>
                <CheckBox
                  name='karaj'
                  label='ادراج ضمن حضورية الكراج الشهرية ؟'
                  state={props.data.karaj}
                  changeState={props.changeEditAttend}
                ></CheckBox>
                {props.data.karaj ? <div
                  style={{
                    width: '100%',
                    height: 'fit-content',
                    backgroundColor: '#f3f3f3',
                    padding: '10px 20px',
                    marginBottom: '20px'
                  }}
                >
                  {/* <div style={{ fontFamily: 'Cairo', fontWeight: 'bold' }}>أختر الوجبات  لحضورية الكراج الشهري</div> */}
                  <div>
                    {props.shifts && props.shifts?.length && props.shifts?.map(item => (
                      <CheckBox
                        name={item?.name}
                        label={item?.name}
                        state={getCheck(item?.id)}
                        changeState={() => attendSelect(item?.id)}
                      ></CheckBox>
                    ))}
                  </div>
                  <div></div>

                </div> : null}
                <Autocomplete
                  id="classes"
                  options={props.classes}
                  value={props.data.class ? props.data.class : props.classes[0]}
                  onChange={props.changeClassForEdit}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="التصنيفات" variant="outlined" />}
                />
                <Autocomplete
                  id="vehicleTypes"
                  options={props.vehiclesTypes}
                  value={props.data.vehicleType ? props.data.vehicleType : props.vehiclesTypes[0]}
                  onChange={props.changeVehicleTypeForEdit}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="عائدية المركبة" variant="outlined" />}
                />
              </Root2>
            </Grid>

            <Grid item xs={6}>
              {
                props.formErrors.length > 0 ?
                  <Alert
                    title='مدخلات غير صحيحة'
                    msg={props.formErrors}
                    severity='error'
                  ></Alert>
                  :
                  null
              }
            </Grid>
          </Grid>
        </Dialog>
      </StylesProvider>
    </ThemeProvider>


  );




}
