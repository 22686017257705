import React, { Component, PureComponent } from 'react'
import Root from '../../common/BodyMRoot'
import CrudControl from '../../common/CrudControl'
import Loading from '../../common/Loading'
import NoDataDiv from '../../common/NoDataDiv'
import axios from 'axios'
import moment from 'moment'
import {
	createTimeFromStr,
	createTimeFromStrAmPm,
	createTimeFromStrAmPm2,
	formatErrorMgs2,
	getBaseURL,
	getStatusCode,
	handleErr,
	configureDate,
	configureTime,
} from '../../common/Utils'
import {
	getGivenItemsJson,
	clearselectedLocation,
} from '../../common/ApiHelper'
import Cookies from 'js-cookie'
import FromDatePicker from '../../common/DatePicker'
import ToDatePicker from '../../common/DatePicker'
import styled from 'styled-components'
import FindReplaceIcon from '@material-ui/icons/FindReplace'
import Button from '@material-ui/core/Button'
import Snackbar from '../../common/SnackBar'
import AddFreightModal from './AddFreightModal'
import EditModal from './EditModal'
import YesNoDialog from '../../common/YesNoDialog'
import YesNoDialogSubFreight from '../../common/YesNoDialog'
import EditSubFreightModal from './EditSubFreight'
import FreightsTableNew from './FreightsTableNew'
import AddSubFreightDialog from './AddSubFreights'
import UsersStats from './UsersFreightsStats'
import Grid from '@material-ui/core/Grid'
import BackDropLoading from '../../common/BackDropLoading'
import { debounce } from 'debounce'
import FilterPoPup from './FilterPoPup'
import DayDate from '../../common/DatePicker'
import { makeStyles } from '@material-ui/core/styles'

const url = getBaseURL()
const INTERVAL_FETCHING_TIME = 600000 // 1 minute
let userData = null

const DatesContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`

const AbsStatsDiv = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 1px;
	left: 1px;
	max-height: 25px;
`
const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '600px',
		backgroundColor: '#fff',
	},
	pickers: {
		backgroundColor: '#fff',
		marginRight: '15px',
	},
	textField: {
		backgroundColor: '#fff',
	},
}))
class Freights extends Component {
	constructor(props) {
		super(props)
		this.state = {
			lockFetchForUpdate: false,
			firstLoading: true,
			stopListening: false,
			isStatsLoading: true,
			search: '',
			isRequesting: false,
			isLoading: false,
			isThereError: false,
			errCode: {
				status: 0,
				title: '',
				server_errors: [],
				how_to_fix: '',
			},
			freights: null,
			filteredFreights: null,
			from: this.getInitFromDate(),
			to: this.getInitFromDate(),
			alert: {
				show: false,
				color: 'error',
				title: '',
				msg: '',
			},
			openAddFreightModal: false,
			resources: {
				departments: [],
				shifts: [],
				locations: [],
				filteredShifts: [],
				vehicles: [],
				reasons: [],
				stations: [],
			},
			locationName: '',
			//activeVehicles:[],
			freightFormData: {
				selectedShift: null,
				selectedVehicle: null,
				selectedReason: null,
				selectedStation: null,
				selectedLocation: null,
				selectedDayDate: new Date(),
				is_present: true,
				status: true,
				selectedBegTime: new Date(),
				selectedEndTime: new Date(),
				total_parking_time: new Date(),
				trip_beg_location: '',
				selectedTotalParkingTime: new Date(),
				subFreights: [],
				showTPT: true,
				showEndTime: true,
				isFieldsFreights: true,
				showEH: true,
				showWT: true,
				engineHours: new Date(),
				workingTime: new Date(),
				showBegTime: true,
				unselectall: false,
				notesForAbseance: ''
			},
			atTime: new Date(),
			weight: 0,
			otherStation: '',
			notes: '',
			showAtTime: true,

			showEditModal: false,
			yesNoDialog: {
				open: false,
				title: '',
				msg: '',
			},

			freightToDelete: null,

			yesNoDialogSubFreight: {
				open: false,
				title: '',
				msg: '',
			},
			subFreightToDelete: null,
			showSubFreightDialog: false,
			search: '',
			showAddSubFreightDialog: false,
			freightIDToAddSubFreight: null,
			usersData: [],
			usersInfo: [],
			openStats: false,
			pageNumber: 1,
			pageSize: 10,
			totalRows: 0,
			pageCount: 0,
			searchFieldFilter:
				localStorage.getItem('search_data') &&
					localStorage.getItem('search_data') !== null &&
					localStorage.getItem('search_data') !== '' &&
					localStorage.getItem('search_data') !== 'null'
					? localStorage.getItem('search_data')
					: '',
			searchTextArray: [],
			showFilteringPopup: false,
			beginTime: null,
			endTime: null,
			selectedVehicleInUpdate: null,
			selectedShiftsInUpdate: null,
			filteredShiftsInUpdate: [],
		}
		// this.onSearchChange = this.onSearchChange.bind(this)
		// this.onSearchEnter = this.onSearchEnter.bind(this)

		this.onFromDateChange = this.onFromDateChange.bind(this)
		this.onToDateChange = this.onToDateChange.bind(this)
		this.fetchFreights = this.fetchFreights.bind(this)
		this.changeStatusRequest = this.changeStatusRequest.bind(this)
		this.changeStatus = this.changeStatus.bind(this)
		this.closeSnackbar = this.closeSnackbar.bind(this)
		this.openAddModel = this.openAddModel.bind(this)
		this.closeAddModal = this.closeAddModal.bind(this)
		this.save = this.save.bind(this)

		//
		this.onVehicleChange = this.onVehicleChange.bind(this)
		this.onVehicleChange2 = this.onVehicleChange2.bind(this)
		this.onShiftChange = this.onShiftChange.bind(this)
		this.onShiftChange2 = this.onShiftChange2.bind(this)
		this.onReasonChange = this.onReasonChange.bind(this)
		this.onStationChange = this.onStationChange.bind(this)
		this.onDayDateChange = this.onDayDateChange.bind(this)
		this.changeIsPresent = this.changeIsPresent.bind(this)
		this.changeField = this.changeField.bind(this)
		this.onBegTimeChange = this.onBegTimeChange.bind(this)
		this.onEndTimeChange = this.onEndTimeChange.bind(this)
		this.onTotalParkingTimeChange = this.onTotalParkingTimeChange.bind(this)
		this.changeFormStatus = this.changeFormStatus.bind(this)
		this.onAtTimeChange = this.onAtTimeChange.bind(this)
		this.deleteSubFreightCard = this.deleteSubFreightCard.bind(this)
		this.addSubFreightCard = this.addSubFreightCard.bind(this)
		this.changeWeight = this.changeWeight.bind(this)
		this.onOtherStationChange = this.onOtherStationChange.bind(this)
		this.onNotesChange = this.onNotesChange.bind(this)
		this.addFreightRequest = this.addFreightRequest.bind(this)

		// for edit
		this.closeEditModal = this.closeEditModal.bind(this)
		this.showEditModalFunc = this.showEditModalFunc.bind(this)
		this.edit = this.edit.bind(this)

		//delete
		this.deleteFreight = this.deleteFreight.bind(this)

		this.onYes = this.onYes.bind(this)
		this.cancel = this.cancel.bind(this)

		// delete subfreights
		this.deleteSubFreight = this.deleteSubFreight.bind(this)
		this.editSubFreight = this.editSubFreight.bind(this)
		this.onYesSubFreight = this.onYesSubFreight.bind(this)
		this.cancelSubfreight = this.cancelSubfreight.bind(this)

		this.closeSubFreightEditModal = this.closeSubFreightEditModal.bind(this)
		this.editSubFreight = this.editSubFreight.bind(this)
		this.editSubFreightAction = this.editSubFreightAction.bind(this)
		this.changeTPT = this.changeTPT.bind(this)
		this.changeShowEndTime = this.changeShowEndTime.bind(this)
		// this.onSearchChange = this.onSearchChange.bind(this)

		this.changeShowAtTime = this.changeShowAtTime.bind(this)
		this.onIsFieldsFreightsChange = this.onIsFieldsFreightsChange.bind(this)

		this.changeShowEH = this.changeShowEH.bind(this)
		this.changeShowWT = this.changeShowWT.bind(this)

		this.onEngineHoursChange = this.onEngineHoursChange.bind(this)
		this.onWorkingTimeChange = this.onWorkingTimeChange.bind(this)
		this.changeShowBegTime = this.changeShowBegTime.bind(this)

		this.hideAddSubFreightDialog = this.hideAddSubFreightDialog.bind(this)
		this.saveNewSubFreight = this.saveNewSubFreight.bind(this)

		this.openStats = this.openStats.bind(this)
		this.closeStats = this.closeStats.bind(this)
		this.fetchStatsFunction = this.fetchStatsFunction.bind(this)
		this.fetchStats = this.fetchStats.bind(this)

		this.addNewSubFreight = this.addNewSubFreight.bind(this)
		this.addNewLocation = this.addNewLocation.bind(this)
		this.clearSeletedLocation = this.clearSeletedLocation.bind(this)
		this.alertRestsearchhere = this.alertRestsearchhere.bind(this)
		this.onLocationChange = this.onLocationChange.bind(this)
		this.selectAllOptions = this.selectAllOptions.bind(this)
		this.deselectAllOptions = this.deselectAllOptions.bind(this)
		this.unselected__ = this.unselected__.bind(this)
		this.restSelectionnow = this.restSelectionnow.bind(this)
		this.handlePageNumberOrPageSizeChgange =
			this.handlePageNumberOrPageSizeChgange.bind(this)
		this.getPageSize = this.getPageSize.bind(this)
		this.setSearchFieldFilter = this.setSearchFieldFilter.bind(this)
		this.setSearchFieldFilterPerColumn =
			this.setSearchFieldFilterPerColumn.bind(this)
		this.handleSearchChange = this.handleSearchChange.bind(this)
		this.executeFilter = this.executeFilter.bind(this)
		this.sethandleSearchFilterChange =
			this.sethandleSearchFilterChange.bind(this)
		this.gethandleSearchFilter = this.gethandleSearchFilter.bind(this)
		this.getSearchFieldFilterPerColumn =
			this.getSearchFieldFilterPerColumn.bind(this)
		this.getDatesState = this.getDatesState.bind(this)
		this.setDatesState = this.setDatesState.bind(this)
		this.getSearchFieldFilterPerColumnDate =
			this.getSearchFieldFilterPerColumnDate.bind(this)
		this.setFilterSearch = this.setFilterSearch.bind(this)
		this.restbuttonClicked = this.restbuttonClicked.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
		this.fetchVehiclesDataForSelectedDayDate =
			this.fetchVehiclesDataForSelectedDayDate.bind(this)
		this.handleRemoveKeysFromLocalStorage =
			this.handleRemoveKeysFromLocalStorage.bind(this)
		this.onNotesOfInformal = this.onNotesOfInformal.bind(this)
	}
	onNotesOfInformal(e) {
		this.setState({
			...this.state,
			freightFormData: {
				...this.state.freightFormData,
				notesForAbseance: e?.target?.value,
			}
		})

	}
	getDatesState(num) {
		if (num == 1) {
			return this.state.beginTime
		} else if (num == 2) {
			return this.state.endTime
		}
	}
	setDatesState(num, date) {
		if (num == 1) {
			this.setState({
				...this.state,
				beginTime: date,
			})
		} else if (num == 2) {
			this.setState({
				...this.state,
				endTime: date,
			})
		}
	}
	async componentDidMount() {
		this.handleRemoveKeysFromLocalStorage()
		document.addEventListener('keydown', this.handleKeyPress)

		try {
			userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
			if (userData.userInfo.role.name === 'Reporter') window.location.href = '/'
		} catch (error) {
			window.location.href = '/'
		}
		// fetch data from the server
		await this.fetchFreights()
		await this.fetchResources()
		this.startTimer()

		if (this.state.resources.locations.length > 0) {
			let data = {
				username: userData ? userData.userInfo.role.name : '',
				location: this.state.resources.locations[0].name,
			}
			localStorage.setItem('selectedLocation', JSON.stringify(data))
		}
	}

	componentWillUnmount() {
		clearInterval(this.timerID)
		document.removeEventListener('keydown', this.handleKeyPress)
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.to !== this.state.to) {
			this.fetchFreights()
		}
		if (
			prevState.pageSize != this.state.pageSize ||
			prevState.pageNumber != this.state.pageNumber
		) {
			this.fetchFreights()
		}
		if (
			prevState?.searchFieldFilter != this.state.searchFieldFilter ||
			prevState?.searchTextArray != this.state.searchTextArray
		) {
			this.handleSearchChange()
		}
		if (this.state.stopListening) {
			document.removeEventListener('keydown', this.handleKeyPress)
		} else {
			document.addEventListener('keydown', this.handleKeyPress)
		}

		// if (
		// 	this.state.freightFormData.selectedDayDate !=
		// 		prevState.freightFormData.selectedDayDate &&
		// 	!this.state.firstLoading
		// ) {
		// 	this.fetchResources()
		// }
	}
	getPageSize() {
		return this.state.pageSize
	}
	startTimer() {
		this.timerID = setInterval(
			() => this.fetchStatsFunction(),
			INTERVAL_FETCHING_TIME,
			INTERVAL_FETCHING_TIME
		)
	}
	fetchStatsFunction() {
		if (
			this.props &&
			this.props.match &&
			this.props.match.path &&
			this.props.match.path === '/freights'
		) {
			this.fetchStats()
		}
	}
	async fetchStats() {
		this.setState({
			...this.state,
			isStatsLoading: true,
		})
		let data = {
			items: ['users_stats'],
			from: configureDate(this.state.from),
			to: configureDate(this.state.to),
		}
		let result = await getGivenItemsJson(data)

		if (!result.data || !result.data.Data) {
			return
		}

		//get users info to know which user is active
		let usersInfo = await axios({
			url: url + `/users`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + userData.token,
			},
			data: {},
		})

		let arrays = result.data.Data.returned_items
		console.log('this.state.usersInfo', usersInfo.data.Data)

		this.setState({
			...this.state,
			usersInfo: usersInfo.data.Data,
			usersData: arrays.users_stats,
			isStatsLoading: false,
		})
	}
	setSearchFieldFilter(text) {
		console.log('aasdasdasdasdasdas===>', text)
		this.setState({
			...this.state,
			searchFieldFilter: text,
		})
	}
	setSearchFieldFilterPerColumn(arrayText) {
		let arr = []
		let count = 0
		this.state.searchTextArray &&
			this.state.searchTextArray?.length > 0 &&
			this.state.searchTextArray.map((itm) => {
				if (itm?.id == arrayText?.id) {
					arr.push(arrayText)
					count = 1
				} else {
					arr.push(itm)
				}
			})
		if (count == 0) {
			arr.push(arrayText)
		}
		this.setState({
			...this.state,
			searchTextArray: arr,
		})
	}
	getSearchFieldFilterPerColumn(number) {
		let name = ''
		this.state.searchTextArray &&
			this.state.searchTextArray?.length > 0 &&
			this.state.searchTextArray.map((itm) => {
				if (itm?.id == number) {
					name = itm?.name
				}
			})
		return name
	}
	getSearchFieldFilterPerColumnDate(number) {
		let date = null
		this.state.searchTextArray &&
			this.state.searchTextArray?.length > 0 &&
			this.state.searchTextArray.map((itm) => {
				if (itm?.id == number) {
					date = itm?.date
				}
			})
		return date
	}
	handleSearchChange = debounce(() => {
		// this.fetchFreights(this.searchFieldFilter, this.searchTextArray)
	}, 500)
	executeFilter() {
		this.setState({
			...this.state,
			// showFilteringPopup: false,
			...(this.state.pageNumber == 1 ? {} : { pageNumber: 1 }),
		})
		if (this.state.pageNumber == 1) this.fetchFreights()
	}
	sethandleSearchFilterChange = (search, number) => {
		if (number == 1) {
			this.setState({
				...this.state,
				searchFieldFilter: search,
			})
		}
	}
	gethandleSearchFilter = (number) => {
		if (number == 1) {
			return this.state.searchFieldFilter
		}
	}
	restbuttonClicked = async () => {
		localStorage.removeItem('search_data')
		let result = await this.setFilterSearch('filter/freights/search', '')
		if (result && result.data.status == 200) {
			this.alertRestsearchhere()
		}
		setTimeout(() => {
			this.fetchFreights()
		}, 500)
	}
	setFilterSearch = async (route, data) => {
		const url = getBaseURL()

		let userData = null
		try {
			userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
		} catch (error) {
			window.location.href = '/'
		}

		let result = null
		try {
			result = await axios({
				url: url + '/' + route,
				method: 'post',
				data: data,
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
			})
			return result
		} catch (error) { }
	}
	handleKeyPress = (event) => {
		if (event.key === 'Enter' && !this.state.stopListening) {
			event.preventDefault()
			this.fetchFreights()
		}
	}

	render() {
		//   const classes = useStyles()

		return (
			<Root>
				{this.state.showFilteringPopup ? (
					<FilterPoPup
						open={this.state.showFilteringPopup}
						setOpen={(value) => {
							this.setState({
								...this.state,
								showFilteringPopup: value,
								stopListening: value,
							})
						}}
						executeFilter={this.executeFilter}
						alertRestsearchhere={this.alertRestsearchhere}
						sethandleSearchFilterChange={this.sethandleSearchFilterChange}
						gethandleSearchFilter={this.gethandleSearchFilter}
						setSearchFieldFilterPerColumn={this.setSearchFieldFilterPerColumn}
						getSearchFieldFilterPerColumn={this.getSearchFieldFilterPerColumn}
						getSearchFieldFilterPerColumnDate={
							this.getSearchFieldFilterPerColumnDate
						}
						getDatesState={this.getDatesState}
						setDatesState={this.setDatesState}
					/>
				) : null}
				{this.state.isLoading ? (
					<BackDropLoading open={this.state.isLoading} />
				) : null}
				<Grid
					container
					onKeyDown={(event) => {
						if (event.keyCode === 13) {
							this.fetchFreights()
						}
					}}
				>
					<Grid item xs={12}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								gap: '10px',
								width: 'fit-content',
								minWidth: '300px'
							}}
						>
							<CrudControl
								openModal={this.openAddModel}
								showSearch={false}
								stylesh={{
									width: '130px'
								}}
							/>
							<DayDate
								stylesh={{
									// padding: '15px',
									// backgroundColor: '#fff',
									// marginRight: '15px',
									width: '200px'
								}}
								title="اليوم"
								selectedDate={this.state.freightFormData.selectedDayDate}
								handleDateChange={this.onDayDateChange}
							/>
						</div>
						<DatesContainer>
							<FromDatePicker
								style={{
									padding: '15px',
								}}
								title="من"
								selectedDate={this.state.from}
								handleDateChange={this.onFromDateChange}
							></FromDatePicker>

							<ToDatePicker
								style={{
									padding: '15px',
								}}
								title="الى"
								selectedDate={this.state.to}
								handleDateChange={this.onToDateChange}
							></ToDatePicker>
							<Button
								disabled={this.state.isLoading}
								onClick={this.fetchFreights}
								variant="contained"
								color="primary"
								startIcon={<FindReplaceIcon />}
							>
								<span
									style={{
										paddingRight: '10px',
									}}
								>
									بحث من جديد
								</span>
							</Button>
							<span
								style={{
									position: 'absolute',
									left: '50px',
								}}
							>
								عدد القيود المدخلة :
								{this.state.totalRows ? this.state.totalRows : 0}
							</span>
						</DatesContainer>
						<DatesContainer>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										gap: '10px',
										marginTop: 10,
										marginBottom: 10,
									}}
								>
									<Button
										onClick={() => {
											this.setState({
												...this.state,
												from: moment()._d,
												to: moment()._d,
												freightFormData: {
													...this.state.freightFormData,
													selectedDayDate: moment()._d
												}
											})
											this.fetchVehiclesDataForSelectedDayDate({
												day_date: moment(moment()._d).format('YYYY-MM-DD'),
											})
										}}
										variant="contained"
										color="secondary"
									>
										اليوم
									</Button>

									<Button
										onClick={() => {
											this.setState({
												...this.state,
												from: moment().add(-1, 'days')._d,
												to: moment().add(-1, 'days')._d,
												freightFormData: {
													...this.state.freightFormData,
													selectedDayDate: moment().add(-1, 'days')._d
												}
											})
											this.fetchVehiclesDataForSelectedDayDate({
												day_date: moment( moment().add(-1, 'days')._d).format('YYYY-MM-DD'),
											})
										}}
										variant="contained"
										color="secondary"
									>
										الامس
									</Button>

									<Button
										onClick={() => {
											this.setState({
												...this.state,
												from: moment().add(-1, 'weeks')._d,
												to: moment().add(-1, 'days')._d,
											})
										}}
										variant="contained"
										color="secondary"
									>
										قبل اسبوع
									</Button>

									<Button
										onClick={() => {
											this.setState({
												...this.state,
												from: moment().add(-1, 'months')._d,
												to: moment().add(-1, 'days')._d,
											})
										}}
										variant="contained"
										color="secondary"
									>
										قبل شهر
									</Button>
								</div>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-end',
										marginTop: 10,
										marginBottom: 10,
									}}
								>
									<Button
										onClick={() => {
											this.setState({
												...this.state,
												searchTextArray: [],
												searchFieldFilter: '',
											})
											this.restbuttonClicked()
										}}
										style={{
											margin: '0 10px',
											backgroundColor: '#2f6d03',
										}}
										variant="contained"
										color="primary"
									>
										مسح الفلتر
									</Button>
									<Button
										onClick={() => {
											this.setState({
												...this.state,
												showFilteringPopup: true,
											})
										}}
										variant="contained"
										color="primary"
									>
										فلترة البيانات
									</Button>
								</div>
							</div>
						</DatesContainer>
					</Grid>
					<Grid item xs={6}>
						<AbsStatsDiv>
							<UsersStats
								isOpen={this.state.openStats}
								close={this.closeStats}
								total={
									this.state.filteredFreights
										? this.state.filteredFreights.length
										: 0
								}
								usersData={this.state.usersData ? this.state.usersData : 0}
								usersInfo={this.state.usersInfo ? this.state.usersInfo : 0}
								isStatsLoading={this.state.isStatsLoading}
							/>
						</AbsStatsDiv>
					</Grid>
				</Grid>

				{!this.state.isLoading && !this.state.filteredFreights ? (
					<NoDataDiv />
				) : (
					<FreightsTableNew
						deleteSubFreight={this.deleteSubFreight}
						deleteFreight={this.deleteFreight}
						showEditModalFunc={this.showEditModalFunc}
						editSubFreight={this.editSubFreight}
						addNewSubFreight={this.addNewSubFreight}
						alertRestsearchhere={this.alertRestsearchhere}
						title="النشاط اليومي"
						departments={this.state.resources.departments}
						changeStatus={this.changeStatus}
						fetchData={this.fetchFreights}
						handlePageNumberOrPageSizeChgange={
							this.handlePageNumberOrPageSizeChgange
						}
						pageSize={this.state.pageSize}
						pageNumber={this.state.pageNumber}
						totalRows={this.state.totalRows}
						pageCount={this.state.pageCount}
						getPageSize={this.getPageSize}
						setSearchFieldFilter={this.setSearchFieldFilter}
						setSearchFieldFilterPerColumn={this.setSearchFieldFilterPerColumn}
						data={
							this.state.filteredFreights?.length > 0
								? this.state.filteredFreights
								: []
						}
					></FreightsTableNew>
				)}
				{this.state.alert.show ? (
					<Snackbar
						title={this.state.alert.title}
						msg={this.state.alert.msg}
						show={this.state.alert.show}
						period={6000}
						severity={this.state.alert.color}
						closeSnackbar={this.closeSnackbar}
					></Snackbar>
				) : null}
				{this.state.openAddFreightModal ? (
					//console.log('this is options'+ this.state.resources.vehicles),
					<AddFreightModal
						open={this.state.openAddFreightModal}
						closeAddModal={this.closeAddModal}
						save={this.save}
						vehicles={this.state.resources.vehicles}
						shifts={this.state.resources.shifts}
						locations={this.state.resources.locations}
						filteredShifts={this.state.resources.filteredShifts}
						reasons={this.state.resources.reasons}
						stations={this.state.resources.stations}
						selectedVehicle={this.state.freightFormData.selectedVehicle}
						selectedShift={this.state.freightFormData.selectedShift}
						selectedLocation={this.state.freightFormData.selectedLocation}
						selectedReason={this.state.freightFormData.selectedReason}
						selectedStation={this.state.freightFormData.selectedStation}
						selectedDayDate={this.state.freightFormData.selectedDayDate}
						fieldsData={this.state.freightFormData}
						changeIsPresent={this.changeIsPresent}
						changeFormStatus={this.changeFormStatus}
						onVehicleChange={this.onVehicleChange}
						onVehicleChange2={this.onVehicleChange2}
						onShiftChange={this.onShiftChange}
						onShiftChange2={this.onShiftChange2}
						addNewLocation={this.addNewLocation}
						onLocationTextChange={this.onLocationTextChange}
						onReasonChange={this.onReasonChange}
						onStationChange={this.onStationChange}
						onDayDateChange={this.onDayDateChange}
						changeField={this.changeField}
						changeTPT={this.changeTPT}
						changeShowEndTime={this.changeShowEndTime}
						onBegTimeChange={this.onBegTimeChange}
						onEndTimeChange={this.onEndTimeChange}
						onTotalParkingTimeChange={this.onTotalParkingTimeChange}
						onAtTimeChange={this.onAtTimeChange}
						atTime={this.state.atTime}
						weight={this.state.weight}
						changeWeight={this.changeWeight}
						otherStation={this.state.otherStation}
						onOtherStationChange={this.onOtherStationChange}
						deleteSubFreightCard={this.deleteSubFreightCard}
						addSubFreightCard={this.addSubFreightCard}
						subFreights={this.state.freightFormData.subFreights}
						notes={this.state.notes}
						onNotesChange={this.onNotesChange}
						isRequesting={this.isRequesting}
						showAtTime={this.state.showAtTime}
						changeShowAtTime={this.changeShowAtTime}
						changeShowEH={this.changeShowEH}
						changeShowWT={this.changeShowWT}
						onIsFieldsFreightsChange={this.onIsFieldsFreightsChange}
						onEngineHoursChange={this.onEngineHoursChange}
						onWorkingTimeChange={this.onWorkingTimeChange}
						changeShowBegTime={this.changeShowBegTime}
						clearSeletedLocation={this.clearSeletedLocation}
						deselectAllOptions={this.deselectAllOptions}
						selectAllOptions={this.selectAllOptions}
						unselected__={this.unselected__}
						restSelectionnow={this.restSelectionnow}
						onNotesOfInformal={this.onNotesOfInformal}
						notesForAbseance={this.state.notesForAbseance}
					/>
				) : null}
				{this.state.showEditModal ? (
					<EditModal
						open={this.state.showEditModal}
						closeEditModal={this.closeEditModal}
						edit={this.edit}
						vehicles={this.state.resources.vehicles}
						shifts={this.state.resources.shifts}
						filteredShifts={this.state.resources.filteredShifts}
						reasons={this.state.resources.reasons}
						selectedVehicle={this.state.freightFormData.selectedVehicle}
						selectedShift={this.state.freightFormData.selectedShift}
						selectedReason={this.state.freightFormData.selectedReason}
						selectedDayDate={this.state.freightFormData.selectedDayDate}
						locations={this.state.resources.locations}
						fieldsData={this.state.freightFormData}
						onLocationChange={this.onLocationChange}
						changeIsPresent={this.changeIsPresent}
						changeFormStatus={this.changeFormStatus}
						onVehicleChange={this.onVehicleChange}
						onVehicleChange2={this.onVehicleChange2}
						onShiftChange={this.onShiftChange}
						onShiftChange2={this.onShiftChange2}
						onReasonChange={this.onReasonChange}
						onDayDateChange={this.onDayDateChange}
						changeField={this.changeField}
						onBegTimeChange={this.onBegTimeChange}
						onEndTimeChange={this.onEndTimeChange}
						onTotalParkingTimeChange={this.onTotalParkingTimeChange}
						isRequesting={this.isRequesting}
						deleteFreight={this.deleteFreight}
						changeTPT={this.changeTPT}
						changeShowEndTime={this.changeShowEndTime}
						onEngineHoursChange={this.onEngineHoursChange}
						changeShowEH={this.changeShowEH}
						changeShowWT={this.changeShowWT}
						onWorkingTimeChange={this.onWorkingTimeChange}
						changeShowBegTime={this.changeShowBegTime}
						clearSeletedLocation={this.clearSeletedLocation}
						deselectAllOptions={this.deselectAllOptions}
						selectAllOptions={this.selectAllOptions}
						unselected__={this.unselected__}
						restSelectionnow={this.restSelectionnow}
					></EditModal>
				) : null}
				{this.state.yesNoDialog.open ? (
					<YesNoDialog
						open={this.state.yesNoDialog.open}
						title={this.state.yesNoDialog.title}
						msg={this.state.yesNoDialog.msg}
						onYes={this.onYes}
						cancel={this.cancel}
						isRequesting={this.state.isRequesting}
					/>
				) : null}
				{
					// for subfreights
					this.state.yesNoDialogSubFreight.open ? (
						<YesNoDialogSubFreight
							open={this.state.yesNoDialogSubFreight.open}
							title={this.state.yesNoDialogSubFreight.title}
							msg={this.state.yesNoDialogSubFreight.msg}
							onYes={this.onYesSubFreight}
							cancel={this.cancelSubfreight}
							isRequesting={this.state.isRequesting}
						/>
					) : null
				}
				{this.state.showSubFreightDialog ? (
					<EditSubFreightModal
						open={this.state.showSubFreightDialog}
						stations={this.state.resources.stations}
						selectedStation={this.state.freightFormData.selectedStation}
						onStationChange={this.onStationChange}
						weight={this.state.weight}
						changeWeight={this.changeWeight}
						selectedAtTime={this.state.atTime}
						onAtTimeChange={this.onAtTimeChange}
						notes={this.state.notes}
						onNotesChange={this.onNotesChange}
						otherStation={this.otherStation}
						onOtherStationChange={this.onOtherStationChange}
						closeEditModal={this.closeSubFreightEditModal}
						isRequesting={this.state.isRequesting}
						edit={this.editSubFreightAction}
						showAtTime={this.state.showAtTime}
						changeShowAtTime={this.changeShowAtTime}
					/>
				) : null}

				{this.state.showAddSubFreightDialog ? (
					<AddSubFreightDialog
						show={this.state.showAddSubFreightDialog}
						hideModal={this.hideAddSubFreightDialog}
						isRequesting={this.state.isRequesting}
						save={this.saveNewSubFreight}
						stations={this.state.resources.stations}
						selectedStation={this.state.freightFormData.selectedStation}
						onStationChange={this.onStationChange}
						weight={this.state.weight}
						changeWeight={this.changeWeight}
						notes={this.state.notes}
						onNotesChange={this.onNotesChange}
						otherStation={this.state.otherStation}
						onOtherStationChange={this.onOtherStationChange}
						showAtTime={this.state.showAtTime}
						changeShowAtTime={this.changeShowAtTime}
						selectedAtTime={this.state.atTime}
						onAtTimeChange={this.onAtTimeChange}
					></AddSubFreightDialog>
				) : null}
			</Root>
		)
	}
	openStats(e) {
		this.setState({
			openStats: true,
		})
	}
	closeStats(e) {
		this.setState({
			openStats: false,
		})
	}
	saveNewSubFreight(e) {
		if (!this.state.freightIDToAddSubFreight) {
			this.setState({
				alert: {
					show: true,
					title: 'لم يتم اختيار سجل لاضافة شحنة اليه!',
					msg: formatErrorMgs2(['لم يتم اختيار سجل لاضافة شحنة اليه!']),
				},
			})
			return
		}
		let errors = this.validateSubFreightFields()
		if (errors.length > 0) {
			this.setState({
				alert: {
					show: true,
					title: 'خطأ في المدخلات',
					msg: formatErrorMgs2(errors),
				},
			})
			return
		}
		let data = {
			freight_id: this.state.freightIDToAddSubFreight.id,
			station_id: this.state.freightFormData.selectedStation.id,
			weight: this.state.weight,
		}
		if (this.state.showAtTime) {
			data = {
				...data,
				at_time: configureTime(this.state.atTime),
			}
		} else {
			data = {
				...data,
				at_time: null,
			}
		}
		if (this.state.notes.length > 0) {
			data = {
				...data,
				notes: this.state.notes,
			}
		}
		if (this.state.freightFormData.selectedStation.name == 'others') {
			data = {
				...data,
				other_notes: this.state.otherStation,
			}
		}

		this.addSubFreightRequest(data)
	}

	addSubFreightRequest(data) {
		this.setState({
			isRequesting: true,
		})
		axios({
			url: url + `/subFreights`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let msg = 'تم اضافة الشحنة بنجاح'
				this.setState((pv) => ({
					freightFormData: {
						...pv.freightFormData,
						selectedStation: this.state.resources.stations
							? this.state.resources.stations[0]
							: null,
					},
					weight: 0,
					notes: '',
					otherStation: '',
					alert: {
						...pv.alert,
						show: true,
						color: 'success',
						title: msg,
						msg: msg,
					},
					isRequesting: false,
				}))
				let freightToUpdate = this.state.freightIDToAddSubFreight
				// console.log(freightToUpdate)
				let freights = this.state.freights
				let index = freights.findIndex(function (item) {
					return item.id == freightToUpdate.id
				})
				if (index > -1) {
					freights[index].sub_freights.push(response.data.Data)
					this.setState((pv) => ({
						...pv,
						freights: freights,
						filteredFreights: freights,
					}))
				}
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				this.setState((pv) => ({
					alert: {
						...pv.alert,
						show: true,
						color: 'error',
						title: 'هنالك خطأ ما',
						msg: errCodeObj.how_to_fix,
					},
					isRequesting: false,
				}))
			})
	}

	changeShowAtTime(e) {
		this.setState({
			showAtTime: e.target.checked,
		})
	}
	onIsFieldsFreightsChange(e) {
		e.persist()
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				isFieldsFreights: e.target.checked,
			},
		}))
	}
	// for subfreights
	editSubFreightAction(e) {
		if (this.state.subFreightToDelete) {
			let errors = this.validateSubFreightFields()
			if (errors.length > 0) {
				this.setState({
					alert: {
						show: true,
						title: 'خطأ في المدخلات',
						msg: formatErrorMgs2(errors),
					},
				})
				return
			}
			let data = {
				station_id: this.state.freightFormData.selectedStation.id,
				weight: this.state.weight,
				at_time: this.state.showAtTime
					? configureTime(this.state.atTime)
					: null,
				_method: 'PUT',
			}
			if (this.state.freightFormData.selectedStation.name === 'others') {
				data = {
					...data,
					other_notes: this.state.otherStation,
				}
			}
			if (this.state.notes && this.state.notes.length > 0) {
				data = {
					...data,
					notes: this.state.notes,
				}
			}
			this.editSubFreightRequest(this.state.subFreightToDelete.id, data)
		}
	}
	validateSubFreightFields() {
		let errors = []
		if (!this.state.freightFormData.selectedStation)
			errors.push('يجب اختيار الموقع اولا')
		if (this.state.freightFormData.selectedStation.name === 'others') {
			if (
				this.state.otherStation.length === 0 ||
				this.state.otherStation.length < 3
			) {
				errors.push('يجب كتابة الموقع عندما تقوم باختيار موقع اخر')
			}
		}

		if (this.state.showAtTime) {
			if (!this.state.atTime) {
				errors.push('يجب تحديد وقت الدخول')
			}
		}
		if (
			this.state.notes &&
			this.state.notes.length > 0 &&
			this.state.notes.length < 3
		)
			errors.push('يجب ان تكون الملاحظات اكبر من 3 احرف')

		return errors
	}
	editSubFreightRequest(freightID, data) {
		let that = this
		this.setState({
			// isLoading: true,
			isRequesting: true,
		})
		axios({
			url: url + `/subFreights/` + freightID,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let object = response.data.Data
				let freights = this.state.freights
				let index = freights.findIndex(function (item) {
					return item.id === object.freight_id
				})
				if (index > -1) {
					if (freights[index].sub_freights) {
						let subFreightIndex = freights[index].sub_freights.findIndex(
							function (item) {
								return item.id === object.id
							}
						)
						if (subFreightIndex > -1) {
							freights[index].sub_freights[subFreightIndex] = object
						}
					}
				}
				this.fetchFreights()
				this.closeSubFreightEditModal()
				that.setState({
					// isLoading: false,
					isRequesting: false,
					freights: freights,
					filteredFreights: freights,
					alert: {
						show: true,
						color: 'success',
						title: 'تم التعديل بنجاح',
						msg: 'تم التعديل بنجاح',
					},
				})
				// that.fetchFreights()
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				this.setState({
					alert: {
						show: true,
						color: 'error',
						title: 'هنالك خطأ ما',
						msg: errCodeObj.how_to_fix,
					},
					errCode: errCodeObj,
					// isLoading: false,
					isRequesting: false,
				})
			})
	}
	hideAddSubFreightDialog(e) {
		this.setState({
			showAddSubFreightDialog: false,
			stopListening: false,
			freightIDToAddSubFreight: null,
		})
	}
	closeSubFreightEditModal(e) {
		this.setState({
			weight: 0,
			notes: '',

			showSubFreightDialog: false,
			stopListening: false,
		})
	}
	editSubFreight(subFreightObj) {
		let that = this
		this.setState(
			{
				...this.state,

				subFreightToDelete: subFreightObj,
				weight: subFreightObj.weight,
				notes: subFreightObj.notes,
				otherStation: subFreightObj.otherStation,
				atTime: createTimeFromStrAmPm2(
					subFreightObj.at_time,
					subFreightObj?.freight?.day_date
				),
				showAtTime: subFreightObj.at_time ? true : false,
				freightFormData: {
					...this.state.freightFormData,
					selectedStation: subFreightObj.station,
				},
			},
			() => {
				that.setState({
					showSubFreightDialog: true,
					stopListening: true,
				})
			}
		)
	}

	deleteSubFreight(subFreightObj) {
		this.setState({
			yesNoDialogSubFreight: {
				open: true,
				title: 'حذف شحنة',
				msg: 'هل أنت متاكد من حذف هذه الشحنة؟',
			},
			subFreightToDelete: subFreightObj,
			stopListening: true,
		})
	}
	onYesSubFreight(e) {
		if (this.state.subFreightToDelete) {
			this.deleteSubFreightRequest(this.state.subFreightToDelete.id)
		}
	}
	deleteSubFreightRequest(subFreightID) {
		let that = this
		this.setState({
			// isLoading: true,
			isRequesting: true,
		})
		axios({
			url: url + `/subFreights/` + subFreightID,
			method: 'delete',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
			.then((response) => {
				let objectIDToDelete = response.data.Data
				let freights = this.state.freights
				let fIndex = freights.findIndex(function (item) {
					return item.id === objectIDToDelete.freight_id
				})
				if (fIndex > -1) {
					let subIndex = freights[fIndex].sub_freights.findIndex(function (
						item
					) {
						return item.id === objectIDToDelete.id
					})
					if (subIndex > -1) {
						freights[fIndex].sub_freights.splice(subIndex, 1)
					}
				}

				that.setState({
					// isLoading: false,
					isRequesting: false,
					freights: freights,
					filteredFreights: freights,
					alert: {
						show: true,
						color: 'success',
						title: 'تم الحذف بنجاح',
						msg: 'تم الحذف بنجاح',
					},
					yesNoDialogSubFreight: {
						open: false,
						title: '',
						msg: '',
					},
					stopListening: false,
				})
				// that.fetchFreights()
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				this.setState({
					alert: {
						show: true,
						color: 'error',
						title: 'هنالك خطأ ما',
						msg: errCodeObj.how_to_fix,
					},
					errCode: errCodeObj,
					// isLoading: false,
					isRequesting: false,
				})
			})
	}
	cancelSubfreight(e) {
		this.setState({
			yesNoDialogSubFreight: {
				open: false,
				title: '',
				msg: '',
			},
			stopListening: false,
		})
	}
	// for delete
	onYes(e) {
		if (this.state.freightToDelete) {
			this.deleteFreightRequest(this.state.freightToDelete.id)
		}
	}

	cancel(e) {
		this.setState({
			yesNoDialog: {
				open: false,
				title: '',
				msg: '',
			},
			stopListening: false,
		})
	}
	deleteFreightRequest(freightID) {
		let that = this
		this.setState({
			isLoading: true,
			isRequesting: true,
		})
		axios({
			url: url + `/freights/` + freightID,
			method: 'delete',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
			.then((response) => {
				that.setState({
					isLoading: false,
					isRequesting: false,
					alert: {
						show: true,
						color: 'success',
						title: 'تم الحذف بنجاح',
						msg: 'تم الحذف بنجاح',
					},
					yesNoDialog: {
						open: false,
						title: '',
						msg: '',
					},
				})
				let freights = this.state.freights
				let index = freights.findIndex(function (item) {
					return item.id === freightID
				})
				if (index > -1) {
					freights.splice(index, 1)

					that.setState({
						freights: freights,
						filteredFreights: freights,
					})
				}

				that.fetchFreights()
				that.fetchResources()
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				this.setState({
					alert: {
						show: true,
						color: 'error',
						title: 'هنالك خطأ ما',
						msg: errCodeObj.how_to_fix,
					},
					errCode: errCodeObj,
					isLoading: false,
					isRequesting: false,
				})
			})
	}

	deleteFreight(freightObj) {
		this.setState({
			yesNoDialog: {
				open: true,
				title: 'حذف سجل يومي للمركبة',
				msg: 'هل أنت متاكد من حذف هذا السجل؟',
			},
			freightToDelete: freightObj,
			stopListening: true,
		})
	}
	// *********
	// for edit
	edit(e) {
		let errors = this.validate()
		if (errors.length > 0) {
			this.setState({
				alert: {
					color: 'error',
					show: true,
					title: 'خطأ في المدخلات',
					msg: formatErrorMgs2(errors),
				},
			})
			return
		}
		var final_eng_hour=this.state.freightFormData.engineHours;
		var final_eng_work=this.state.freightFormData.workingTime;
		if(this.state.freightFormData.is_present==0&&this.state.freightFormData.selectedReason.id !=5){
			final_eng_hour=null
			final_eng_work=null
		}

		let data = {
			vehicle_id: this.state.freightFormData.selectedVehicle.id,
			driver_id:
				this.state.freightFormData.selectedVehicle && this.state.freightFormData.selectedVehicle.current_driver && this.state.freightFormData.selectedVehicle.current_driver.driver_id,
			shift_id: this.state.freightFormData.selectedShift.id,
			day_date: configureDate(this.state.freightFormData.selectedDayDate),
			is_present: this.state.freightFormData.is_present
				? '1'
				: this.state.freightFormData.selectedReason.id == 5
					? '1'
					: '0',
			engine_hours: this.state.freightFormData.showEH
				? final_eng_hour
					? configureTime(final_eng_hour)
					: null
				: null,
			working_hours: this.state.freightFormData.showWT
				? final_eng_work
					? configureTime(final_eng_work)
					: null
				: null,
			total_parking_time: this.state.freightFormData.showTPT
				? configureTime(this.state.freightFormData.selectedTotalParkingTime)
				: null,
			absence_reason_id: !this.state.freightFormData.is_present
				? this.state.freightFormData.selectedReason.id
				: null,
			trip_beg_location: this.state.freightFormData.is_present
				? this.state.freightFormData.selectedLocation
					? this.state.freightFormData.selectedLocation.name
					: ''
				: '',

			beg_time: this.state.freightFormData.is_present
				? this.state.freightFormData.showBegTime
					? configureTime(this.state.freightFormData.selectedBegTime)
					: null
				: null,
			end_time: this.state.freightFormData.is_present
				? this.state.freightFormData.showEndTime
					? configureTime(this.state.freightFormData.selectedEndTime)
					: null
				: null,

			_method: 'PUT',
		}
		console.log(this.state.freightFormData.selectedLocation)
		console.log(this.state.freightFormData.trip_beg_location)

		this.editFreightRequest(this.state.freightFormData.freight_id, data)
	}
	editFreightRequest(freightID, data) {
		let that = this
		this.setState({
			isRequesting: true,
		})
		axios({
			url: url + `/freights/` + freightID,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				that.handleRemoveKeysFromLocalStorage()
				this.fetchFreights()
				setTimeout(() => {
					that.fetchVehiclesDataForSelectedDayDate(null)
				}, 250)
				that.setState({
					isRequesting: false,
					alert: {
						show: true,
						color: 'success',
						title: 'تم التعديل بنجاح',
						msg: 'تم التعديل بنجاح',
					},
					freightFormData: that.defaultDataForm(),
					showEditModal: false,
				})
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				// console.log(errCodeObj)
				this.setState({
					alert: {
						show: true,
						color: 'error',
						title: 'هنالك خطأ ما',
						msg: errCodeObj.server_errors,
					},
					errCode: errCodeObj,
					isRequesting: false,
				})
			})
	}

	addNewSubFreight(subFreightsObj) {
		let that = this
		if (subFreightsObj) {
			// console.log(subFreightsObj)
			that.setState({
				showAddSubFreightDialog: true,
				stopListening: true,
				freightIDToAddSubFreight: subFreightsObj,
			})
		}
	}
	async showEditModalFunc(freightObj) {
		console.log('asdasdasdsada===>', freightObj)
		localStorage.setItem('center_id', freightObj?.center_id)
		localStorage.setItem('shift_id', freightObj?.shift_id)
		localStorage.setItem('vehicle_id', freightObj?.vehicle_id)
		await this.fetchVehiclesDataForSelectedDayDate(freightObj)
		setTimeout(() => {
			console.log(freightObj)
			let that = this
			// find vehicle
			let vehicles = this.state.resources.vehicles
			let index = vehicles.findIndex(function (item) {
				return (
					item.id === freightObj.vehicle.id &&
					item.current_driver?.driver_id === freightObj.driver?.id
				)
			})
			let vehicle = null
			if (index > -1) {
				vehicle = vehicles[index]
			}
			// console.log('vhicle found')
			// console.log(vehicle)
			let shifts = this.state.resources.shifts
			index = shifts.findIndex(function (item) {
				return item.id === freightObj.shift.id
			})
			let shift = null
			if (index > -1) {
				shift = shifts[index]
			}

			let filteredShiftsTemp = []
			console.log('vSh', this.state.resources.vehicles)
			console.log('sSh', shifts)
			if (vehicle && vehicle.shifts) {
				for (let i = 0; i < vehicle.shifts.length; i++) {
					for (let j = 0; j < shifts.length; j++) {
						if (vehicle.shifts[i].shift_id == shifts[j].id) {
							console.log('pushedcurr', shifts[j])
							filteredShiftsTemp.push(shifts[j])
						}
					}
				}
			} else {
				filteredShiftsTemp.push([])
			}
			if (filteredShiftsTemp && filteredShiftsTemp?.length > 0) {
				filteredShiftsTemp = filteredShiftsTemp.sort((a, b) => a.id - b.id);
			}

			this.setState(
				{
					...this.state,
					selectedVehicleInUpdate: vehicle,
					selectedShiftsInUpdate: shift,
					filteredShiftsInUpdate: filteredShiftsTemp,
					freightFormData: {
						...this.state.freightFormData,
						freight_id: freightObj.id,
						selectedVehicle: vehicle,
						selectedShift: shift,
						is_present: freightObj.is_present === '1' ? true : false,
						selectedDayDate: new Date(freightObj.day_date),
						trip_beg_location:
							freightObj.is_present === '1' ? freightObj.trip_beg_location : '',
						selectedLocation: {
							name:
								freightObj.is_present === '1'
									? freightObj.trip_beg_location
									: '',
						},
						selectedBegTime:
							freightObj.is_present === '1'
								? freightObj.beg_time
									? createTimeFromStrAmPm(
										freightObj.beg_time,
										freightObj?.day_date
									)
									: new Date()
								: new Date(),
						selectedEndTime:
							freightObj.is_present === '1'
								? freightObj.end_time
									? createTimeFromStrAmPm(
										freightObj.end_time,
										freightObj?.day_date
									)
									: new Date()
								: new Date(),
						selectedTotalParkingTime:
							freightObj.is_present === '1'
								? freightObj.total_parking_time
									? createTimeFromStr(freightObj.total_parking_time)
									: new Date()
								: new Date(),
						status: freightObj.status === '1' ? true : false,
						selectedReason:
							freightObj.is_present === '1' ? null : freightObj.absence_reason,
						engineHours: freightObj.engine_hours
							? createTimeFromStrAmPm2(
								freightObj.engine_hours,
								freightObj?.day_date
							)
							: null,
						workingTime: freightObj.working_hours
							? createTimeFromStrAmPm2(
								freightObj.working_hours,
								freightObj?.day_date
							)
							: null,
						showEH: freightObj.engine_hours ? true : false,
						showWT: freightObj.working_hours ? true : false,
						showEndTime: freightObj.end_time ? true : false,
						showBegTime: freightObj.beg_time ? true : false,
						showTPT: freightObj.total_parking_time ? true : false,
						unselectall:
							freightObj.end_time ||
								freightObj.beg_time ||
								freightObj.total_parking_time
								? false
								: true,
						isFieldsFreights: false,
					},
					resources: {
						...this.state.resources,
						filteredShifts: filteredShiftsTemp,
					},
				},
				() => {
					that.setState({
						...this.state,
						showEditModal: true,
						stopListening: true,
					})
				}
			)
		}, 900)
	}
	closeEditModal(e) {
		this.setState({
			showEditModal: false,
			stopListening: false,
			freightFormData: {
				...this.state.freightFormData,
				selectedDayDate: new Date(),
			},
		})
		//here i will remove some keys that i used when I get vehicels
		this.handleRemoveKeysFromLocalStorage() //here will removed the keys
		setTimeout(() => {
			this.fetchVehiclesDataForSelectedDayDate(null) //then we will retrieve  data.
		}, 250)
	}
	handleRemoveKeysFromLocalStorage() {
		localStorage.removeItem('center_id')
		localStorage.removeItem('shift_id')
		localStorage.removeItem('vehicle_id')
		this.setState({
			selectedVehicleInUpdate: null,
			selectedShiftsInUpdate: null,
			filteredShiftsInUpdate: [],
		})
	}
	//*** */
	onNotesChange(e) {
		if (e)
			this.setState({
				notes: e.target.value,
			})
	}

	onOtherStationChange(e) {
		if (e)
			this.setState({
				otherStation: e.target.value,
			})
	}
	changeWeight(e) {
		if (e.target.value < 0) {
			return
		}
		this.setState({
			weight: e.target.value,
		})
	}
	// delete card from subfreights
	deleteSubFreightCard(stationID, weight, atTime) {
		let list = this.state.freightFormData.subFreights
		// console.log('list', list)
		if (!list) return

		if (stationID === -1) {
			// mean delete all subfreights
			list = []
		} else {
			let itemIndex = list.findIndex(function (item) {
				return (
					item.station.id === stationID &&
					item.weight === weight &&
					item.at_time === atTime
				)
			})

			// console.log('item index', itemIndex)

			if (itemIndex > -1) {
				list.splice(itemIndex, 1)
			}
		}
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				subFreights: list,
			},
		}))
	}

	addSubFreightCard(e) {
		if (!this.state.freightFormData.selectedStation) {
			alert('يجب اختيار الموقع')
			return
		}
		if (
			this.state.freightFormData.selectedStation.name === 'others' &&
			this.state.otherStation.length === 0
		) {
			alert('يجب كتابة اسم الموقع')
			return
		}
		let list = this.state.freightFormData.subFreights
		let obj = {
			station_id: this.state.freightFormData.selectedStation.id,
			station: this.state.freightFormData.selectedStation,
		}
		if (this.state.weight && this.state.weight > -1) {
			obj = {
				...obj,
				weight: this.state.weight,
			}
		} else {
			obj = {
				...obj,
				weight: 0,
			}
		}
		if (this.state.showAtTime) {
			obj = {
				...obj,
				at_time: configureTime(this.state.atTime),
			}
		}

		if (this.state.notes.length && this.state.notes.length > 0) {
			obj = {
				...obj,
				notes: this.state.notes,
			}
		}
		if (this.state.otherStation && this.state.otherStation.length > 0) {
			obj = {
				...obj,
				other_notes: this.state.otherStation,
			}
		}
		list.push(obj)
		this.setState(
			(pv) => ({
				freightFormData: {
					...pv.freightFormData,
					subFreights: list,
				},
			}),
			() => { }
		)
	}
	// on changes function
	onAtTimeChange(e) {
		// console.log('changed')

		this.setState({
			atTime: e,
		})
	}
	// change feild here.
	changeField(e, value) {
		// console.log(this.state.freightFormData.locations)
		e.persist()
		console.log(value)

		this.setState({
			...this.state,
			freightFormData: {
				...this.state.freightFormData,
				selectedLocation: value,
			},
		})

		// console.log(this.state.freightFormData.selectedLocation)

		let data = {
			username: userData ? userData.userInfo.role.name : '',
			location: value ? value.name : '',
		}
		localStorage.setItem('selectedLocation', JSON.stringify(data))
	}
	changeShowEndTime(e) {
		e.persist()
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				showEndTime: e.target.checked,
			},
		}))
	}
	changeTPT(e) {
		e.persist()
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				showTPT: e.target.checked,
			},
		}))
	}
	changeIsPresent(e) {
		e.persist()
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				is_present: e.target.checked,
				notesForAbseance: e.target.checked ? '' : this.state.freightFormData.notesForAbseance
			},
		}))
	}
	changeShowEH(e) {
		e.persist()
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				showEH: e.target.checked,
			},
		}))
	}
	changeShowBegTime(e, location) {
		e.persist()
		if (e.target.checked == false) {
			console.log(false)
			this.setState({
				...this.state,
				freightFormData: {
					...this.state.freightFormData,
					showBegTime: e.target.checked,
					selectedLocation: null,
				},
			})
		} else {
			this.setState({
				...this.state,
				freightFormData: {
					...this.state.freightFormData,
					showBegTime: e.target.checked,
					selectedLocation: location,
				},
			})
		}
	}

	restSelectionnow(e, location) {
		// e.persist()
		if (e.target.checked == true) {
			// console.log(false)
			this.setState({
				...this.state,
				freightFormData: {
					...this.state.freightFormData,
					selectedLocation: {},
				},
			})
		} else {
			this.setState({
				...this.state,
				freightFormData: {
					...this.state.freightFormData,
					selectedLocation: location,
				},
			})
		}
	}
	changeShowWT(e) {
		e.persist()
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				showWT: e.target.checked,
			},
		}))
	}
	changeFormStatus(e) {
		e.persist()
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				status: e.target.checked,
			},
		}))
	}
	onTotalParkingTimeChange(e) {
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				selectedTotalParkingTime: e,
			},
		}))
	}
	onBegTimeChange(e) {
		console.log('on begin change', e)
		this.setState(
			(pv) => ({
				freightFormData: {
					...pv.freightFormData,
					selectedBegTime: e,
				},
			}),
			() => { }
		)
	}
	onEndTimeChange(e) {
		console.log('onEndTimeChange', e)
		this.setState(
			(pv) => ({
				freightFormData: {
					...pv.freightFormData,
					selectedEndTime: e,
				},
			}),
			() => { }
		)
	}
	onDayDateChange(e) {
		this.setState(
			(pv) => ({
				freightFormData: {
					...pv.freightFormData,
					selectedDayDate: e,
				},
			}),
			() => { }
		)
		this.fetchVehiclesDataForSelectedDayDate({
			day_date: moment(e).format('YYYY-MM-DD'),
		})
	}
	onVehicleChange(e, value) {
		if (value) {
			let shifts = this.state.resources.shifts
			let filteredShifts = []
			let vehicleShifts = value.shifts
			// console.log(value)
			for (let i = 0; i < vehicleShifts.length; i++) {
				for (let j = 0; j < shifts.length; j++) {
					if (vehicleShifts[i].shift_id === shifts[j].id) {
						filteredShifts.push(shifts[j])
					}
				}
			}

			// filteredShifts = filteredShifts.sort(function (a, b) {
			// 	return a.id - b.id
			// })

			let selectedshiftnow = {}
			if (value.current_driver) {
				for (let k = 0; k < filteredShifts.length; k++) {
					if (filteredShifts[k].id == value.current_driver.shift_id) {
						selectedshiftnow = filteredShifts[k]
						break
					} else if (k == filteredShifts.length - 1) {
						selectedshiftnow = filteredShifts[k]
					}
				}
			}
			console.log('adasdasdasdasdasda===>ve', value)
			console.log('adasdasdasdasdasda===>sh', selectedshiftnow)
			if (this.state.showEditModal) {
				let vehicle_id___ = value && value?.id ? value?.id : null
				let shift_id__ =
					selectedshiftnow && selectedshiftnow?.id ? selectedshiftnow?.id : null
				var center_id___ = null
				if (value && value?.shifts && value?.shifts?.length) {
					value.shifts.map((item) => {
						if (item?.shift_id == selectedshiftnow?.id) {
							center_id___ = item?.center_id
						}
					})
				}
				localStorage.setItem('center_id', center_id___)
				localStorage.setItem('vehicle_id', vehicle_id___)
				localStorage.setItem('shift_id', shift_id__)
			}

			if (selectedshiftnow) {
				this.setState((pv) => ({
					...this.state,
					selectedVehicleInUpdate: this.state.showEditModal ? value : null,
					selectedShiftsInUpdate: this.state.showEditModal
						? selectedshiftnow
							? selectedshiftnow
							: null
						: null,
					filteredShiftsInUpdate: this.state.showEditModal
						? filteredShifts
						: [],
					freightFormData: {
						...pv.freightFormData,
						selectedVehicle: value,
						selectedShift: selectedshiftnow ? selectedshiftnow : null,
					},
					resources: {
						...pv.resources,
						filteredShifts: filteredShifts,
					},
				}))
			}
		}
	}

	onVehicleChange2(dataItem) {
		if (dataItem) {
			let value = null
			if (this.state.resources.vehicles) {
				if (this.state.resources.vehicles.length > 0) {
					this.state.resources.vehicles.map((data) => {
						if (data.current_driver) {
							if (
								data.current_driver.shift_id == dataItem.id &&
								this.state.freightFormData.selectedVehicle.plate_number ==
								data.plate_number
							) {
								console.log('done here')
								// setselectedVehiclesNow(data)
								// props.onVehicleChange2(data)
								value = data
							}
						}
					})
				}
			}
			if (value) {
				let shifts = this.state.resources.shifts
				let filteredShifts = []
				let vehicleShifts = value.shifts
				console.log(value)
				for (let i = 0; i < vehicleShifts.length; i++) {
					for (let j = 0; j < shifts.length; j++) {
						if (vehicleShifts[i].shift_id === shifts[j].id) {
							filteredShifts.push(shifts[j])
						}
					}
				}
				let selectedshiftnow = {}
				for (let k = 0; k < filteredShifts.length; k++) {
					if (filteredShifts[k].id === value.current_driver?.driver.shift_id) {
						selectedshiftnow = filteredShifts[k]
					} else if (k == filteredShifts.length - 1) {
						selectedshiftnow = filteredShifts[0]
					}
				}

				this.setState((pv) => ({
					...this.state,
					freightFormData: {
						...pv.freightFormData,
						selectedVehicle: value,
						selectedShift: selectedshiftnow ? selectedshiftnow : null,
					},
					resources: {
						...pv.resources,
						filteredShifts: filteredShifts,
					},
				}))
			}
		}
	}

	onShiftChange(e, value) {
		// console.log(value)
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				selectedShift: value,
			},
		}))
	}

	onShiftChange2(value) {
		// console.log(value)

		let data = null
		if (value) {
			if (value.current_driver) {
				if (this.state.resources.shifts?.length > 0) {
					this.state.resources.shifts.map((item) => {
						if (item.id == value.current_driver.shift_id) {
							console.log(item)
							data = item
							this.setState({
								...this.state,
								freightFormData: {
									...this.state.freightFormData,
									selectedShift: { id: 2, name: 'مسائي' },
								},
							})
							// return
						}
					})
				}
			}
		}

		// if (data) {
		// }
	}
	onLocationChange(e, value) {
		console.log(value)
		// e.persist()

		this.setState({
			...this.state,
			freightFormData: {
				...this.state.freightFormData,
				selectedLocation: value,
			},
		})
	}

	onLocationTextChange(e, value) {
		localStorage.setItem('locationtext', e.target.value)
		// console.log(e.target.value, value)
	}

	// add location here.
	addNewLocation() {
		if (
			localStorage.getItem('locationtext') &&
			localStorage.getItem('locationtext') != '' &&
			localStorage.getItem('locationtext') != null
		) {
			const data = {
				location: localStorage.getItem('locationtext')
					? localStorage.getItem('locationtext')
					: '',
			}
			let that = this
			this.setState({
				isLoading: true,
				isRequesting: true,
			})

			axios({
				url: url + `/freights/location`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
				data: data,
			})
				.then((response) => {
					if (response.data.status == 200) {
						let alert = {
							show: true,
							color: 'success',
							title: 'تم اضافة موقع انطلاق جديد',
							msg: 'تم اضافة موقع انطلاق جديد',
						}
						localStorage.removeItem('locationtext')
						that.setState({
							...this.state,
							locationName: '',
							resources: {
								...this.state.resources,
								locations: response.data.data,
							},
							freightFormData: {
								...this.state.freightFormData,
								selectedLocation:
									response.data.data[response.data.data.length - 1].name,
							},
							isRequesting: false,
							isLoading: false,
							alert: alert,
						})
					}
				})
				.catch((error) => {
					// {"Code":400,"Error":[{"location":["The location has already been taken."]}] }
					let code = getStatusCode(error)
					let errCodeObj = handleErr(code, error)
					this.setState({
						alert: {
							color: 'error',
							show: true,
							title: 'هنالك خطأ ما',
							msg: errCodeObj.server_errors,
						},

						errCode: errCodeObj,
						isLoading: false,
						isRequesting: false,
					})
				})
		} else {
			this.setState({
				...this.state,
				alert: {
					color: 'error',
					show: true,
					title: 'يجب ادخال البيانات في الحقل',
					msg: 'يجب ادخال البيانات في الحقل',
				},
				isLoading: false,
				isRequesting: false,
			})
		}
	}

	onStationChange(e, value) {
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				selectedStation: value,
			},
		}))
	}
	onReasonChange(e, value) {
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				selectedReason: value,
				notesForAbseance: value?.id == 5 ? '' : this.state.freightFormData.notesForAbseance
			},
		}))
	}

	async fetchResources(freightsData = null) {
		this.setState({
			isLoading: true,
			isRequesting: true,
			firstLoading: false,
		})
		let data = {
			items: [
				'departments',
				'shifts',
				'locations',
				'vehicles',
				'reasons',
				'stations',
				'users_stats',
			],
			from: configureDate(this.state.from),
			to: configureDate(this.state.to),
			is_freights: true,
			selectedDayDate:
				freightsData && freightsData?.day_date
					? freightsData?.day_date
					: this.state.freightFormData.selectedDayDate
						? configureDate(this.state.freightFormData.selectedDayDate)
						: configureDate(new Date()),
			shift_id:
				freightsData && freightsData?.shift_id ? freightsData?.shift_id : null,
			vehicle_id:
				freightsData && freightsData?.vehicle_id
					? freightsData?.vehicle_id
					: null,
			center_id:
				freightsData && freightsData?.center_id
					? freightsData?.center_id
					: null,
		}
		let result = await getGivenItemsJson(data)
		// this.setState({
		// 	isLoading: false,
		// 	isRequesting: false,
		// 	firstLoading: false,
		// })
		if (!result.data || !result.data.Data) return
		// console.log('loation',result.data.Data.returned_items)
		let arrays = result.data.Data.returned_items
		// find initial shift
		//console.log('arrayVehicles is ' + JSON.stringify(arrays.vehicles))
		// for (let i = 0; i < arrays.vehicles.length; i++) {
		// 	console.log(arrays.vehicles[i].shifts)
		// }

		//get it the id of shift here only.
		let filteredShiftOfFirstVehicle = arrays.vehicles
			? arrays.vehicles[0].shifts.length > 0
				? arrays.vehicles[0].shifts[arrays.vehicles[0].shifts.length - 1]
					.shift_id
				: 0
			: 0

		//making an array for only active vehicles.
		// arrays.vehicles.map(item=>{
		// if(item.is_active=="1"){
		// 	this.setState({activeVehicles:[...this.state.activeVehicles,item]})
		// }

		// })
		let selectedShiftTemp = null
		if (filteredShiftOfFirstVehicle > 0) {
			selectedShiftTemp = arrays.shifts.find(function (shift) {
				return shift.id == filteredShiftOfFirstVehicle
			})
		}
		// console.log('default shift id is ')
		// console.log(arrays.vehicles)

		let se_vh = []
		if (arrays.vehicles.length > 0) {
			for (let j = 0; j < arrays.vehicles.length; j++) {
				if (arrays.vehicles[j].current_driver.length > 0) {
					//sort by shift id
					arrays.vehicles[j].current_driver = arrays.vehicles[
						j
					].current_driver.sort(function (a, b) {
						return a.shift_id - b.shift_id
					})

					for (let i = 0; i < arrays.vehicles[j].current_driver.length; i++) {
						let check = 0
						let check1 = 0
						if (se_vh && se_vh.length > 0) {
							for (let x = 0; x < se_vh.length; x++) {
								// if(arrays?.vehicles[j]?.current_driver[i]?.driver_id==499 || arrays?.vehicles[j]?.current_driver[i]?.driver_id==711)
								// {
								// 	check=0;
								// }
								if (
									se_vh[x].id == arrays?.vehicles[j]?.id &&
									se_vh[x].current_driver?.driver_id ==
									arrays?.vehicles[j]?.current_driver[i]?.driver_id &&
									se_vh[x]?.shifts?.id ==
									arrays?.vehicles[j]?.current_driver[i]?.shift_id
								) {
									check = 1
								}
							}
						}
						let currentUserShiftName = ''
						let slectedShiftId = null
						for (let y = 0; y < arrays.vehicles[j].shifts.length; y++) {
							// if(se_vh[x].current_driver.driver)

							if (
								arrays.vehicles[j].shifts[y].shift_id ==
								arrays.vehicles[j].current_driver[i].shift_id
							) {
								check1 = 1
								slectedShiftId = arrays.vehicles[j].shifts[y].shift_id
							}
						}
						if (arrays && arrays?.shifts && arrays?.shifts?.length)
							for (let index = 0; index < arrays?.shifts?.length; index++) {
								if (slectedShiftId == arrays?.shifts[index]?.id) {
									currentUserShiftName = arrays?.shifts[index]?.name
								}
							}

						if (check == 0 && check1 == 1) {
							se_vh.push({
								id: arrays.vehicles[j].id,
								plate_number: arrays.vehicles[j].plate_number,
								brand: arrays.vehicles[j].brand,
								is_active: arrays.vehicles[j].is_active,
								shifts: arrays.vehicles[j].shifts,
								shift_name: currentUserShiftName,
								current_driver: arrays.vehicles[j].current_driver[i],
							})
						}
						check = 0
						check1 = 0
					}

					// se_vh =
				} else {
					se_vh.push({
						id: arrays.vehicles[j].id,
						plate_number: arrays.vehicles[j].plate_number,
						brand: arrays.vehicles[j].brand,
						is_active: arrays.vehicles[j].is_active,
						shifts: arrays.vehicles[j].shifts,
						shift_name: '',
						current_driver: null,
					})
				}
			}
		}

		se_vh.forEach((element) => {
			if (element.plate_number == 'كابسة نيسان - T 60038') {
				console.log('vechiles list=>', element)
			}
		})
		console.log('vecfsdfsdfdsfsdf=.....>', se_vh[0]?.shifts)
		let vehicle = se_vh && se_vh?.length ? se_vh[0] : null

		if (vehicle) {
			let shifts =
				arrays?.shifts && arrays?.shifts?.length ? arrays?.shifts : []
			var filteredShifts = []
			let vehicleShifts =
				vehicle?.shifts && vehicle?.shifts?.length ? vehicle?.shifts : []

			for (let i = 0; i < vehicleShifts.length; i++) {
				for (let j = 0; j < shifts.length; j++) {
					if (vehicleShifts[i].shift_id === shifts[j].id) {
						filteredShifts.push(shifts[j])
					}
				}
			}
		}
		if (filteredShifts && filteredShifts?.length > 0) {
			var filteredShifts = filteredShifts.sort((a, b) => a.id - b.id);
		}
		this.setState((pv) => ({
			isLoading: false,
			isRequesting: false,
			firstLoading: false,
			resources: {
				departments: arrays.departments,
				shifts: arrays.shifts,
				locations: arrays.locations,
				filteredShifts: filteredShifts ? filteredShifts : [],
				vehicles: se_vh,
				reasons: arrays.reasons,
				stations: arrays.stations,
			},
			freightFormData: {
				...pv.freightFormData,
				selectedShift:
					filteredShifts && filteredShifts?.length ? filteredShifts[0] : null,
				selectedVehicle: se_vh ? se_vh[0] : null,
				selectedReason: arrays.reasons ? arrays.reasons[0] : null,
				selectedStation: arrays.stations ? arrays.stations[0] : null,
			},
			usersData: arrays.users_stats,
		}))
	}
	async fetchVehiclesDataForSelectedDayDate(freightsData = null) {
		this.setState({
			isLoading: true,
			isRequesting: true,
			firstLoading: false,
		})
		let data = {
			items: ['shifts', 'vehicles'],
			from: configureDate(this.state.from),
			to: configureDate(this.state.to),
			is_freights: true,
			selectedDayDate:
				freightsData && freightsData?.day_date
					? freightsData?.day_date
					: this.state.freightFormData.selectedDayDate
						? configureDate(this.state.freightFormData.selectedDayDate)
						: configureDate(new Date()),
			shift_id:
				freightsData && freightsData?.shift_id
					? freightsData?.shift_id
					: localStorage.getItem('shift_id')
						? localStorage.getItem('shift_id')
						: null,
			vehicle_id:
				freightsData && freightsData?.vehicle_id
					? freightsData?.vehicle_id
					: localStorage.getItem('vehicle_id')
						? localStorage.getItem('vehicle_id')
						: null,
			center_id:
				freightsData && freightsData?.center_id
					? freightsData?.center_id
					: localStorage.getItem('center_id')
						? localStorage.getItem('center_id')
						: null,
		}
		let result = await getGivenItemsJson(data)
		// this.setState({
		// 	isLoading: false,
		// 	isRequesting: false,
		// 	firstLoading: false,
		// })
		if (!result.data || !result.data.Data) return
		// console.log('loation',result.data.Data.returned_items)
		let arrays = result.data.Data.returned_items
		// find initial shift
		//console.log('arrayVehicles is ' + JSON.stringify(arrays.vehicles))
		// for (let i = 0; i < arrays.vehicles.length; i++) {
		// 	console.log(arrays.vehicles[i].shifts)
		// }

		//get it the id of shift here only.
		let filteredShiftOfFirstVehicle = arrays.vehicles
			? arrays.vehicles[0].shifts.length > 0
				? arrays.vehicles[0].shifts[arrays.vehicles[0].shifts.length - 1]
					.shift_id
				: 0
			: 0

		//making an array for only active vehicles.
		// arrays.vehicles.map(item=>{
		// if(item.is_active=="1"){
		// 	this.setState({activeVehicles:[...this.state.activeVehicles,item]})
		// }

		// })
		let selectedShiftTemp = null
		if (filteredShiftOfFirstVehicle > 0) {
			selectedShiftTemp = arrays.shifts.find(function (shift) {
				return shift.id == filteredShiftOfFirstVehicle
			})
		}
		// console.log('default shift id is ')
		// console.log(arrays.vehicles)

		let se_vh = []
		if (arrays.vehicles.length > 0) {
			for (let j = 0; j < arrays.vehicles.length; j++) {
				if (arrays.vehicles[j].current_driver.length > 0) {
					//sort by shift id
					arrays.vehicles[j].current_driver = arrays.vehicles[
						j
					].current_driver.sort(function (a, b) {
						return a.shift_id - b.shift_id
					})

					for (let i = 0; i < arrays.vehicles[j].current_driver.length; i++) {
						let check = 0
						let check1 = 0
						if (se_vh && se_vh.length > 0) {
							for (let x = 0; x < se_vh.length; x++) {
								// if(arrays?.vehicles[j]?.current_driver[i]?.driver_id==499 || arrays?.vehicles[j]?.current_driver[i]?.driver_id==711)
								// {
								// 	check=0;
								// }
								if (
									se_vh[x].id == arrays?.vehicles[j]?.id &&
									se_vh[x].current_driver?.driver_id ==
									arrays?.vehicles[j]?.current_driver[i]?.driver_id &&
									se_vh[x]?.shifts?.id ==
									arrays?.vehicles[j]?.current_driver[i]?.shift_id
								) {
									check = 1
								}
							}
						}
						let currentUserShiftName = ''
						let slectedShiftId = null
						for (let y = 0; y < arrays.vehicles[j].shifts.length; y++) {
							// if(se_vh[x].current_driver.driver)

							if (
								arrays.vehicles[j].shifts[y].shift_id ==
								arrays.vehicles[j].current_driver[i].shift_id
							) {
								check1 = 1
								slectedShiftId = arrays.vehicles[j].shifts[y].shift_id
							}
						}
						if (arrays && arrays?.shifts && arrays?.shifts?.length)
							for (let index = 0; index < arrays?.shifts?.length; index++) {
								if (slectedShiftId == arrays?.shifts[index]?.id) {
									currentUserShiftName = arrays?.shifts[index]?.name
								}
							}

						if (check == 0 && check1 == 1) {
							se_vh.push({
								id: arrays.vehicles[j].id,
								plate_number: arrays.vehicles[j].plate_number,
								brand: arrays.vehicles[j].brand,
								is_active: arrays.vehicles[j].is_active,
								shifts: arrays.vehicles[j].shifts,
								shift_name: currentUserShiftName,
								current_driver: arrays.vehicles[j].current_driver[i],
							})
						}
						check = 0
						check1 = 0
					}

					// se_vh =
				} else {
					se_vh.push({
						id: arrays.vehicles[j].id,
						plate_number: arrays.vehicles[j].plate_number,
						brand: arrays.vehicles[j].brand,
						is_active: arrays.vehicles[j].is_active,
						shifts: arrays.vehicles[j].shifts,
						shift_name: '',
						current_driver: null,
					})
				}
			}
		}

		se_vh.forEach((element) => {
			if (element.plate_number == 'كابسة نيسان - T 60038') {
				console.log('vechiles list=>', element)
			}
		})
		console.log('vecfsdfsdfdsfsdf=.....>', se_vh[0]?.shifts)
		let vehicle = se_vh && se_vh?.length ? se_vh[0] : null

		if (vehicle) {
			let shifts =
				arrays?.shifts && arrays?.shifts?.length ? arrays?.shifts : []
			var filteredShifts = []
			let vehicleShifts =
				vehicle?.shifts && vehicle?.shifts?.length ? vehicle?.shifts : []

			for (let i = 0; i < vehicleShifts.length; i++) {
				for (let j = 0; j < shifts.length; j++) {
					if (vehicleShifts[i].shift_id === shifts[j].id) {
						filteredShifts.push(shifts[j])
					}
				}
			}
		}
		if (filteredShifts && filteredShifts?.length > 0) {
			var filteredShifts = filteredShifts.sort((a, b) => a.id - b.id);
		}

		this.setState((pv) => ({
			...pv,
			isLoading: false,
			isRequesting: false,
			firstLoading: false,
			resources: {
				...pv.resources,
				// departments: arrays.departments,
				shifts: arrays.shifts,
				// locations: arrays.locations,
				filteredShifts: this.state.filteredShiftsInUpdate
					? this.state.filteredShiftsInUpdate
					: filteredShifts
						? filteredShifts
						: [],
				vehicles: se_vh,
				// reasons: arrays.reasons,
				// stations: arrays.stations,
			},
			freightFormData: {
				...pv.freightFormData,
				selectedShift:
					filteredShifts && filteredShifts?.length
						? this.state.selectedShiftsInUpdate
							? this.state.selectedShiftsInUpdate
							: filteredShifts[0]
						: null,
				selectedVehicle: se_vh
					? this.state.selectedVehicleInUpdate
						? this.state.selectedVehicleInUpdate
						: se_vh[0]
					: null,
				// selectedReason: arrays.reasons ? arrays.reasons[0] : null,
				// selectedStation: arrays.stations ? arrays.stations[0] : null,
			},
			// usersData: arrays.users_stats,
		}))
	}

	save(e) {
		let errors = this.validate()
		if (errors && errors.length > 0) {
			this.setState({
				alert: {
					color: 'warning',
					show: true,
					title: 'خطأ في المدخلات',
					msg: formatErrorMgs2(errors),
				},
			})
			return
		}

		let data = {
			vehicle_id: this.state.freightFormData.selectedVehicle.id,
			shift_id: this.state.freightFormData.selectedShift.id,
			day_date: configureDate(this.state.freightFormData.selectedDayDate),
			is_present:
				this.state.freightFormData.is_present ||
					this.state.freightFormData.selectedReason.id == 5
					? '1'
					: '0',
			status: this.state.freightFormData.status ? '1' : '0',
			notes_for_abseance: this.state.freightFormData.notesForAbseance
		}
		if (this.state.freightFormData.is_present) {
			data = {
				...data,
				trip_beg_location: this.state.freightFormData.selectedLocation
					? this.state.freightFormData.selectedLocation.name
					: '',

				// beg_time: configureTime(this.state.freightFormData.selectedBegTime)
			}
			if (this.state.freightFormData.showBegTime) {
				data = {
					...data,
					beg_time: configureTime(this.state.freightFormData.selectedBegTime),
				}
			}

			if (this.state.freightFormData.showEndTime) {
				data = {
					...data,
					end_time: configureTime(this.state.freightFormData.selectedEndTime),
				}
			}
			if (this.state.freightFormData.showTPT) {
				data = {
					...data,
					total_parking_time: configureTime(
						this.state.freightFormData.selectedTotalParkingTime
					),
				}
			}
			if (this.state.freightFormData.isFieldsFreights) {
				if (this.state.freightFormData.subFreights.length > 0) {
					data = {
						...data,
						freights_count: this.state.freightFormData.subFreights.length,
						sub_freights: this.state.freightFormData.subFreights,
					}
				}
			} else {
				if (this.state.freightFormData.showEH > 0) {
					data = {
						...data,
						engine_hours: configureTime(this.state.freightFormData.engineHours),
					}
				}
				if (this.state.freightFormData.showWT > 0) {
					data = {
						...data,
						working_hours: configureTime(
							this.state.freightFormData.workingTime
						),
					}
				}
			}
		}
		if (!this.state.freightFormData.is_present) {
			data = {
				...data,
				absence_reason_id: this.state.freightFormData.selectedReason.id,
			}
		}
		if (this.state.freightFormData.isFieldsFreights) {
			if (this.state.freightFormData.subFreights.length > 0) {
				data = {
					...data,
					freights_count: this.state.freightFormData.subFreights.length,
					sub_freights: this.state.freightFormData.subFreights,
				}
			}
		}

		//console.log("this is data" + JSON.stringify(this.state.freightFormData))
		this.addFreightRequest(data)
	}
	validate() {
		let errors = []
		if (!this.state.freightFormData.selectedVehicle)
			errors.push('يجب اختيار الالية')

		if (!this.state.freightFormData.selectedShift)
			errors.push('يجب اختيار الوجبة')

		if (!this.state.freightFormData.selectedDayDate)
			errors.push('يجب اختيار تاريخ اليوم')

		if (this.state.freightFormData.is_present) {
			if (this.state.freightFormData.showBegTime) {
				if (!this.state.freightFormData.selectedBegTime)
					errors.push('يجب أختيار وقت الانطلاق')
			}

			if (!this.state.freightFormData.selectedEndTime)
				errors.push('يجب أختيار وقت العودة')

			if (!this.state.freightFormData.selectedTotalParkingTime)
				errors.push('يجب ادخال مجموع التوقفات')
			else {
				if (this.state.freightFormData.show) {
					let d = this.state.freightFormData.selectedTotalParkingTime

					if (d.getHours() == 0 && d.getMinutes() == 0) {
						errors.push(' 00 يجب ادخال مجموع التوقفات')
					}
				}
			}

			// if (!this.state.freightFormData.trip_beg_location)
			//     errors.push('يجب ادخال موقع الانطلاق')
			if (this.state.freightFormData.showEndTime) {
				console.log('beg', this.state.freightFormData.selectedBegTime)
				console.log('end', this.state.freightFormData.selectedEndTime)
				const today = new Date()

				if (
					//e.g. "2020-06-24 22:57:36" > "2020-06-25 06:35:28" returns false
					this.state.freightFormData.selectedBegTime >=
					this.state.freightFormData.selectedEndTime
					// this.state.freightFormData.selectedBegTime.toLocaleTimeString() >=
					// this.state.freightFormData.selectedEndTime.toLocaleTimeString()
				) {
					errors.push('يجب ان يكون وقت العودة اكبر من وقت الانطلاق')
				}
			}
			if (this.state.freightFormData.isFieldsFreights) {
				// if(this.state.freightFormData.subFreights.length <= 0)
				// {
				//     errors.push('يرحى اضافة الشحنات اولاً')
				// }
			} else {
				// console.log(this.state.freightFormData.showEH+' ,'+(this.state.freightFormData.engineHours?true:false))

				if (
					this.state.freightFormData.showEH &&
					!this.state.freightFormData.engineHours
				) {
					errors.push('يجب تحديد ساعات العمل')
				}
				if (
					this.state.freightFormData.showWT &&
					!this.state.freightFormData.workingTime
				) {
					errors.push('يجب تحديد وقت الحركة')
				}
			}
		} else {
			if (!this.state.freightFormData.selectedReason) {
				errors.push('يجب اختيار سبب الغياب')
			}
		}

		return errors
	}
	addFreightRequest(data) {
		console.log('post data' + JSON.stringify(data))
		let that = this
		this.setState({
			isLoading: true,
			isRequesting: true,
		})
		axios({
			url: url + `/freights`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let alert = {
					show: true,
					color: 'success',
					title: 'تم اضافة المعلومات بنجاح',
					msg: 'تم اضافة المعلومات بنجاح',
				}
				var filteredShifts = null
				if (this.state.resources.filteredShifts)
					filteredShifts = this.state.resources.filteredShifts.sort(function (
						a,
						b
					) {
						return a.id - b.id
					})
				this.onVehicleChange(
					null,
					this.state.resources && this.state.resources.vehicles[0]
						? this.state.resources.vehicles[0]
						: null
				)
				that.setState((pv) => ({
					isRequesting: false,
					isLoading: false,
					alert: alert,

					freightFormData: that.resetDataForm(),

					atTime: new Date(),
					weight: 0,
					otherStation: '',
					notes: '',
					showAtTime: true,
				}))

				that.fetchFreights()
				that.fetchResources()
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				this.setState({
					alert: {
						show: true,
						color: 'error',
						title: 'هنالك خطأ ما',
						msg: errCodeObj.server_errors,
					},
					errCode: errCodeObj,
					isLoading: false,
					isRequesting: false,
				})
			})
	}

	closeAddModal(e) {
		this.setState({
			openAddFreightModal: false,
			stopListening: false,
		})

		// this.fetchFreights()
	}
	openAddModel(e) {
		if (
			this.state.resources &&
			this.state.resources.vehicles &&
			this.state.resources.vehicles?.length <= 0
		) {
			// alert('لا توجد مركبات غير مدخلة لهذا اليوم !')
			let alert = {
				show: true,
				color: 'warning',
				title: 'لا توجد مركبات غير مدخلة لهذا اليوم !',
				msg: 'لا توجد مركبات غير مدخلة لهذا اليوم !',
			}
			this.setState({
				alert: alert
			})
			return
		}
		if (
			!this.state.resources ||
			!this.state.resources.vehicles ||
			this.state.resources.vehicles?.length <= 0 ||
			!this.state.resources.shifts ||
			!this.state.resources.reasons ||
			!this.state.resources.stations
		) {
			alert('يجب تحميل جميع المصادر اولا')
			return
		}
		let vehicle =
			this.state.resources &&
				this.state.resources?.vehicles &&
				this.state.resources?.vehicles?.length > 0
				? this.state.resources.vehicles[0]
				: null

		if (vehicle) {
			let shifts =
				this.state?.resources?.shifts && this.state?.resources?.shifts?.length
					? this.state?.resources?.shifts
					: []
			let filteredShifts = []
			let vehicleShifts =
				vehicle?.shifts && vehicle?.shifts?.length ? vehicle?.shifts : []

			for (let i = 0; i < vehicleShifts?.length; i++) {
				for (let j = 0; j < shifts?.length; j++) {
					if (vehicleShifts[i]?.shift_id === shifts[j]?.id) {
						filteredShifts.push(shifts[j])
					}
				}
			}
			if (filteredShifts && filteredShifts?.length > 0) {
				filteredShifts = filteredShifts.sort((a, b) => a.id - b.id);
			}

			this.setState((pv) => ({
				...pv,
				openAddFreightModal: true,
				stopListening: true,
				selectedVehicleInUpdate: null,
				selectedShiftsInUpdate: null,
				filteredShiftsInUpdate: [],
				freightFormData: {
					...pv.freightFormData,
					selectedVehicle: vehicle,
					selectedShift: filteredShifts.length > 0 ? filteredShifts[0] : null,
					selectedLocation: {},
				},
				resources: {
					...pv.resources,
					filteredShifts: filteredShifts,
				},
			}))
		} else {
			alert('لم يتم اختيار اي مركبة')
			return
		}
	}
	changeStatus(FreightObj) {
		this.changeStatusRequest(FreightObj)
	}
	changeStatusRequest(FreightObj) {
		let that = this
		this.setState({
			// isLoading: true,
			errCode: {
				status: 0,
				title: '',
				server_errors: [],
				how_to_fix: '',
			},
			isThereError: false,
			alert: {
				show: false,
				color: 'error',
				title: '',
				msg: '',
			},
		})
		let newStatus = FreightObj.status === '0' ? '1' : '0'
		axios({
			url: url + `/freights/${FreightObj.id}/cs?newStatus=${newStatus}`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
			.then((response) => {
				let msg = 'تم تعديل حالة السجل بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '
					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors.push(response.data.Error.details[i])
					}

					if (errors)
						that.setState({
							alert: {
								show: true,
								colot: 'success',
								title: 'تمت الطلب بنجاح مع وجود خطأ',
								msg: msg,
							},
						})
				}

				let freightsList = that.state.freights
				let itemToFind = freightsList.findIndex(function (item) {
					return item.id === FreightObj.id
				})
				if (itemToFind > -1) {
					freightsList[itemToFind] = response.data.Data
				}
				// freightsList[FreightObj.freight.day_date][FreightObj.index] = response.data.Data

				that.setState(
					{
						// isLoading: false,
						freights: freightsList,
						search: '',
					},
					() => { }
				)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				that.setState({
					isLoading: false,
					isThereError: true,
					errCode: errCodeObj,
				})
			})
	}
	async handlePageNumberOrPageSizeChgange(value, number) {
		if (number == 1) {
			console.log('change====>pageNnmber ', value)
			await this.setState({
				...this.state,
				pageNumber: value,
			})
		} else {
			console.log('change====>pageSize ', value)
			await this.setState({
				...this.state,
				pageSize: value,
			})
		}
	}

	async fetchFreights() {
		let that = this
		this.setState({
			isLoading: true,
			isStatsLoading: true,
			errCode: {
				status: 0,
				title: '',
				server_errors: [],
				how_to_fix: '',
			},
			showFilteringPopup: false,
			stopListening: false,
		})

		let dFrom = configureDate(this.state.from)

		let dTo = configureDate(this.state.to)
		let result = null
		try {
			result = await axios({
				url: url + `/freights?from=` + dFrom + '&to=' + dTo,
				// url: url + `/freights?from=1970-03-01`+'&to=1970-03-29',
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData.token,
				},
				params: {
					page: this.state?.pageNumber,
					page_size: this.state?.pageSize,
					searchText:
						typeof this.state.searchFieldFilter == 'object' ||
							!this.state.searchFieldFilter
							? null
							: this.state.searchFieldFilter,
					searchTextPerColumn:
						this.state.searchTextArray && this.state.searchTextArray?.length > 0
							? this.state.searchTextArray
							: [],
				},
			})

			if (result?.data?.Data?.data) {
				console.log('dataLIST==>1  ', result?.data?.Data?.data)

				console.log(result.data.Data)
				this.setState({
					...this.state,
					isStatsLoading: false,
					freights: result?.data?.Data?.data,
					filteredFreights: result?.data?.Data?.data,
					pageCount: result.data.Data?.last_page,
					totalRows: result.data.Data?.total,
					showFilteringPopup: false,
					stopListening: false,
					// pageNumber: 1,
					isLoading: false,
					isThereError: false,
					errCode: {
						status: 0,
						title: '',
						server_errors: [],
						how_to_fix: '',
					},
				})
			} else {
				console.log('dataLIST==>2  ', result?.data?.Data?.data)
			}
			console.log(result)
		} catch (error) {
			let code = getStatusCode(error)
			let errCodeObj = handleErr(code, error)
			that.setState({
				isStatsLoading: false,
				isLoading: false,
				errCode: errCodeObj,
				isThereError: true,
				alert: {
					show: true,
					title: 'حدث خظأ ما',
					color: 'error',
					msg: 'حدث خطأ ما',
				},
			})
		}
		// load last status
		this.fetchStats()
	}

	deselectAllOptions() {
		this.setState({
			...this.state,
			freightFormData: {
				...this.state.freightFormData,
				showTPT: false,
				showBegTime: false,
				showEndTime: false,
				unselectall: true,
				selectedLocation: null,
			},
		})
		// props.fieldsData.showTPT = false
		// props.fieldsData.showBegTime = false
		// props.fieldsData.showEndTime = false
	}

	selectAllOptions(location) {
		this.setState({
			...this.state,
			freightFormData: {
				...this.state.freightFormData,
				showTPT: true,
				showBegTime: true,
				showEndTime: true,
				unselectall: false,
				selectedLocation: location,
			},
		})
		// props.fieldsData.showTPT = true
		// props.fieldsData.showBegTime = true
		// props.fieldsData.showEndTime = true
	}

	unselected__(e) {
		this.setState({
			...this.state,
			freightFormData: {
				...this.state.freightFormData,
				unselectall: e,
			},
		})
	}

	onEngineHoursChange(date) {
		// console.log(date)
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				engineHours: date,
			},
		}))
	}
	onWorkingTimeChange(e) {
		this.setState((pv) => ({
			freightFormData: {
				...pv.freightFormData,
				workingTime: e,
			},
		}))
	}
	onFromDateChange(e) {
		console.log('rendering on input change')
		this.setState({
			...this.state,
			from: e,
		})
	}
	onToDateChange(e) {
		this.setState({
			to: e,
		})
	}

	getInitFromDate() {
		var my_date = new Date()
		var first_date = new Date(
			my_date.getFullYear(),
			my_date.getMonth(),
			my_date.getDate()
		)
		return first_date
	}
	closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}

		this.setState({
			alert: {
				show: false,
				title: '',
				msg: '',
				color: 'error',
			},
			errCode: {
				status: 0,
				title: '',
				server_errors: [],
				how_to_fix: '',
			},
		})
	}
	defaultDataForm() {
		let d = new Date()
		d.setHours(0)
		d.setMinutes(0)
		d.setSeconds(0)
		return {
			selectedVehicle: this.state.resources.vehicles
				? this.state.resources.vehicles.length > 0
					? this.state.resources.vehicles[0]
					: null
				: null,

			selectedShift: this.state.freightFormData.selectedVehicle
				? this.state.resources.filteredShifts
					? this.state.resources.filteredShifts.length > 0
						? this.state.resources.filteredShifts[0]
						: null
					: null
				: null,
			selectedReason: this.state.resources.reasons
				? this.state.resources.reasons[0]
				: null,
			selectedStation: this.state.resources.stations
				? this.state.resources.stations[0]
				: null,
			selectedDayDate: new Date(),
			is_present: true,
			status: true,
			selectedBegTime: d,
			selectedEndTime: d,
			showEndTime: true,
			total_parking_time: d,
			trip_beg_location: '',
			selectedTotalParkingTime: d,
			subFreights: [],
			showTPT: true,
			isFieldsFreights: true,
			engineHours: d,
			workingTime: d,
			showEH: true,
			showWT: true,
			showBegTime: true,
		}
	}
	resetDataForm() {
		let d = new Date()
		d.setHours(0)
		d.setMinutes(0)
		d.setSeconds(0)

		return {
			...this.state.freightFormData,
			selectedVehicle: this.state.resources.vehicles
				? this.state.resources.vehicles.length > 0
					? this.state.resources.vehicles[0]
					: null
				: null,

			selectedShift: this.state.freightFormData.selectedVehicle
				? this.state.resources.filteredShifts
					? this.state.resources.filteredShifts.length > 0
						? this.state.resources.filteredShifts[0]
						: null
					: null
				: null,
			selectedReason: this.state.resources.reasons
				? this.state.resources.reasons[0]
				: null,
			selectedStation: this.state.resources.stations
				? this.state.resources.stations[0]
				: null,
			is_present: true,
			status: true,
			selectedBegTime: d,
			selectedEndTime: d,
			showEndTime: true,
			total_parking_time: d,
			trip_beg_location: '',
			selectedTotalParkingTime: d,
			subFreights: [],
			showTPT: true,
			isFieldsFreights: true,
			engineHours: d,
			workingTime: d,
			showEH: true,
			showWT: true,
			showBegTime: true,
			unselectall: false,
		}
	}
	// clear selected location here
	async clearSeletedLocation(reason) {
		if (reason === 'clear') {
			if (this.state.freightFormData.selectedLocation) {
				try {
					// let data = JSON.parse(localStorage.getItem('selectedLocation'))
					let data_search = {
						locationname: this.state.freightFormData.selectedLocation
							? this.state.freightFormData.selectedLocation.name
							: '',
					}
					// let result_search = await clearselectedLocation(
					// 	'trplocation/clear',
					// 	data_search
					// )

					let that = this
					this.setState({
						isLoading: true,
						isRequesting: true,
					})

					axios({
						url: url + `/trplocation/clear`,
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: 'Bearer ' + userData.token,
						},
						data: data_search,
					})
						.then((response) => {
							if (response.data.status == 200) {
								let alert = {
									show: true,
									color: 'success',
									title: 'تم حذف الموقع المحدد بنجاح',
									msg: 'تم حذف الموقع المحدد بنجاح',
								}

								that.setState({
									...this.state,
									locationName: '',
									resources: {
										...this.state.resources,
										locations: response.data.data,
									},
									freightFormData: {
										...this.state.freightFormData,
										selectedLocation: this.state.resources.locations
											? this.state.resources.locations[0]
											: '',
									},
									isRequesting: false,
									isLoading: false,
									alert: alert,
								})

								let data = {
									username: userData ? userData.userInfo.role.name : '',
									location: this.state.resources.locations
										? this.state.resources.locations[0].name
										: '',
								}
								localStorage.setItem('selectedLocation', JSON.stringify(data))
							}
						})
						.catch((error) => {
							// {"Code":400,"Error":[{"location":["The location has already been taken."]}] }
							let code = getStatusCode(error)
							let errCodeObj = handleErr(code, error)
							this.setState({
								alert: {
									color: 'error',
									show: true,
									title: 'هنالك خطأ ما',
									msg: errCodeObj.server_errors,
								},

								errCode: errCodeObj,
								isLoading: false,
								isRequesting: false,
							})
						})
				} catch (err) {
					console.log(err)
				}
			}
		}
	}

	// rest search here.
	alertRestsearchhere() {
		this.setState({
			...this.state,
			alert: {
				show: true,
				color: 'success',
				title: 'تم حذف البحث بنجاح',
				msg: 'تم حذف البحث بنجاح',
			},
			isLoading: false,
			isRequesting: false,
		})
	}
}

export default Freights
