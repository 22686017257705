import React from 'react'


export const defaultState = {
    isLoading: false,
    isNavBarMenuOpen: false,
    isCookieDismissed: false
}
export default props => {
    const { initialState = defaultState } = props

    const [isLoading, setIsLoading] = React.useState(initialState.isLoading)
    const [isNavBarMenuOpen, setIsNavBarMenuOpen] = React.useState(initialState.isNavBarMenuOpen)
    const [isCookieDismissed, setIsCookieDismissed] = React.useState(initialState.isCookieDismissed)

    const onNavMenuClose = () => {
        setIsNavBarMenuOpen(false)
    }
    const onCookieBarClose = () => {
        localStorage.setItem('sdims-consent', 1)
        setIsCookieDismissed(true)
    }

    return {
        setIsLoading,
        // state
        isLoading,
        isNavBarMenuOpen,
        isCookieDismissed,
        // handlers
        onNavMenuClose,
        onCookieBarClose
    }
}
