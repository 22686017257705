import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import MyTableRow from '../../common/TableRow'
import MyTableCell from '../../common/CustomTableCell'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'

const crudColors = require('../../../assets/files/env.json').theme.colors.crud
const headers = require('../../../assets/files/headers.json').assigned_drivers

const useStyles = makeStyles({
	avatar: {
		backgroundColor: '#fff',
		color: '#007AC4'
	}
})

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	}
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		}
	}
}))(TableRow)

function SimpleDialog(props) {
	const classes = useStyles()

	let numbering = 0
	let prop = props
	
	return (
		<Dialog
			maxWidth="xl"
			onClose={props.closeAssignedDrivers}
			aria-labelledby="simple-dialog-title"
			open={true}
		>
			<DialogTitle id="simple-dialog-title">
				السائقين الذين يعملون على هذه الالية
			</DialogTitle>
			<TableContainer component={Paper}>
				<Table
					size="small"
					className={classes.table}
					aria-label="customized table"
				>
					<TableHead>
						<MyTableRow header={headers}></MyTableRow>
					</TableHead>
					<TableBody>
						{prop.vcsds.map(function(item, index) {
							numbering++
							return (
								<StyledTableRow key={index}>
									<MyTableCell
										key={'driver_ass_a' + index}
										value={numbering}
									></MyTableCell>
									<MyTableCell
										key={'driver_ass_b' + index}
										value={item.center ? item.center.name : 'U/N'}
									></MyTableCell>
									<MyTableCell
										key={'driver_ass_c' + index}
										value={item.shift ? item.shift.name : 'U/N'}
									></MyTableCell>
									<MyTableCell
										key={'driver_ass_d' + index}
										value={item.driver ? item.driver.name : 'U/N'}
									></MyTableCell>
									<MyTableCell
										key={'driver_ass_f' + index}
										value={item.bound_at}
									></MyTableCell>
									<MyTableCell
										key={'driver_ass_g' + index}
										value={item.bound_by ? item.bound_by.name : 'U/N'}
									></MyTableCell>
									<MyTableCell
										key={'driver_ass_h' + index}
										value={item.unbound_at ? item.unbound_at : '-'}
									></MyTableCell>
									<MyTableCell
										key={'driver_ass_i' + index}
										value={item.unbound_by ? item.unbound_by.name : '-'}
									></MyTableCell>

									<StyledTableCell key={'driver_ass_j' + index} align="right">
										<IconButton
											onClick={() => prop.unbindDriverFromVehicle(item)}
										>
											<CancelIcon style={{ color: crudColors.d }} />
										</IconButton>
									</StyledTableCell>
								</StyledTableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Dialog>
	)
}

export default SimpleDialog
