import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import rtl from 'jss-rtl';



const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


const theme = createMuiTheme({
    direction: 'rtl',
    typography: {
        fontFamily: [
            'Cairo',
            'Arial',
            'sans-serif'
        ].join(','),
    },

});

 class SubFreightsTable extends Component {
   

    constructor(props)
    {
        super(props)
        this.state = {

        }
      
    }

    render() {
        let that = this
        return (
            <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
            <div style={{maxWidth:'90%',margin:'15px'}}>
                <MaterialTable
                columns={
                    [
                       
                        {"title":"الموقع","field":"station",render: rowData => rowData.station?rowData.station.caption:'*'},
                        {"title":"الوقت","field":"at_time",render: rowData => rowData.at_time?rowData.at_time:'*'},
                        {"title":"ملاحظات","field":"notes",render: rowData => rowData.notes?rowData.notes:''},
                        {"title":"ملاحظات اخرى","field":"other_notes",render: rowData => rowData.other_notes?rowData.other_notes:''},
                        {"title":"الوزن","field":"weight",render: rowData => rowData.weight?rowData.weight:'0'},
                        {"title":"ألمستخدم","field":"users",render: rowData => rowData.user?rowData.user.name:''}
                       
                    ]
                }
                title="الشحنات"
                data={this.props.data?this.props.data:[]}
                actions={[
                                        
                    {
                      icon: 'delete',
                      tooltip: 'حذف شحنة',
                      onClick: (event, rowData) => this.props.deleteSubFreight(rowData)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'تعديل شحنة',
                        onClick: (event, rowData) => this.props.editSubFreight(rowData)
                      }
                  ]}
                  options={{
                    grouping: false,
                    actionsColumnIndex:7,
                    pageSize:5,
                    pageSizeOptions:[10,15,20,25],
                    sorting:true,
                    filtering:false,
                  
                    
                  }}
                  localization={{
                      grouping:{
                          groupedBy:''
                      },
                      header:{
                        actions:'الاجراءات'
                    },
                    toolbar:{
                        addRemoveColumns:'Add or remove columns'
                    }
                  }}
                >
                
                </MaterialTable>
            </div>
            </StylesProvider>
     </ThemeProvider>
        )
    }

   
     
}

export default SubFreightsTable
