import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import { TextField, makeStyles } from '@material-ui/core'
import { useState } from 'react'
import Cookies from 'js-cookie'
import { handleError, getBaseURL } from '../../common/Utils'
import axios from 'axios'
import DateFnsUtils from '@date-io/date-fns'
import DayDate from '../../common/DatePicker'

import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers'
import BegTimePicker from '../../common/TimePicker'

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	)
}
const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '600px',
		backgroundColor: '#fff',
	},
	pickers: {
		backgroundColor: '#fff',
		marginRight: '15px',
	},
	textField: {
		backgroundColor: '#fff',
	},
}))

export default function FilterPoPup(props) {
	const classes = useStyles()

	const [open, setOpen] = React.useState(false)
	const [beginTime, setBeginTIme] = useState(
		localStorage.getItem('beginTime')
			? new Date(localStorage.getItem('beginTime'))
			: null
	)
	const [endTime, setEndTime] = useState(
		localStorage.getItem('endTime')
			? new Date(localStorage.getItem('endTime'))
			: null
	)
	const [totalParking, setTotalParking] = useState(
		localStorage.getItem('totalParking')
			? new Date(localStorage.getItem('totalParking'))
			: null
	)
	const [enginHoure, setEnginHoure] = useState(
		localStorage.getItem('enginHoure')
			? new Date(localStorage.getItem('enginHoure'))
			: null
	)
	const [workingHoure, setWorkingHoure] = useState(
		localStorage.getItem('workingHoure')
			? new Date(localStorage.getItem('workingHoure'))
			: null
	)

	// const [search,setSearch]=useState(props?.storageSearch)

	const handleClickOpen = () => {
		props.setOpen(true)
	}

	const handleClose = () => {
		props.setOpen(false)
	}
	const restbuttonClicked = async () => {
		localStorage.removeItem('search_data')
		props.sethandleSearchFilterChange('', 1)
		let result = await setFilterSearch('filter/freights/search', '')
		if (result && result.data.status == 200) {
			props.alertRestsearchhere()
		}
	}
	const setFilterSearch = async (route, data) => {
		const url = getBaseURL()

		let userData = null
		try {
			userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
		} catch (error) {
			window.location.href = '/'
		}

		let result = null
		try {
			result = await axios({
				url: url + '/' + route,
				method: 'post',
				data: data,
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
			})
			return result
		} catch (error) { }
	}
	const setsearchChange = async (text) => {
		if (text) {
			localStorage.setItem('search_data', text)
			props.sethandleSearchFilterChange(text, 1)

			let data = {
				search_text: text,
			}

			let result = await setFilterSearch('filter/freights/search', data)
		} else {
			restbuttonClicked()
		}
	}
	const submit = () => {
		props.executeFilter()
	}

	function formatTime(date) {
		var d = new Date(date),
			hours = d.getHours(),
			min = d.getMinutes(),
			sec = d.getSeconds()

		if (hours < 10) hours = '0' + hours
		if (min < 10) min = '0' + min
		if (sec < 10) {
			sec = '0' + sec
		}

		return [hours, min, sec].join(':')
	}
	function formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	useEffect(() => {
		console.log('props.getDatesState(1)', props.getDatesState(1))
	}, [props])
	return (
		<Dialog
			open={props?.open}
			onClose={handleClose}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
			style={{}}
			maxWidth="500px"
			onKeyDown={(event) => {
				if (event.keyCode === 13) {
					submit()
				} else if (event.key == 'Escape') {
					handleClose()
				}
			}}
		>
			<DialogTitle
				style={{ cursor: 'move', backgroundColor: '#007AC4' }}
				id="draggable-dialog-title"
			>
				<div
					style={{
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						color: '#fff',
					}}
				>
					فلترة البيانات
				</div>
			</DialogTitle>
			<DialogContent
				style={{
					maxWidth: '800px',
					minWidth: '600px',
				}}
			>
				<div
					style={{
						width: '100% !important',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						flexWrap: 'wrap',
						gap: '10px',
					}}
				>
					<div
						style={{
							margin: '5px',
							width: 'fit-content',
						}}
					>
						<TextField
							id="outlined-basic"
							label="بحث"
							variant="outlined"
							value={props.gethandleSearchFilter(1)}
							onChange={(e) => {
								setsearchChange(e?.target?.value, 1)
							}}
							style={{
								width: '100%',
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							width: '100%',
						}}
					></div>
					<div
						style={{
							margin: '5px',
							width: 'fit-content',
						}}
					>
						{/* <TextField
							id="outlined-basic"
							label="اليوم"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(1)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 1,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/> */}
						<DayDate
							stylesh={{
								// padding: '15px',
								// backgroundColor: '#fff',
								// marginRight: '15px',
								width: '200px',
							}}
							title="اليوم"
							selectedDate={props.getSearchFieldFilterPerColumnDate(1)}
							handleDateChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 1,
									name: e ? formatDate(e) : '',
									date: e,
								})
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: 'fit-content',
						}}
					>
						<TextField
							id="outlined-basic"
							label="الالية ونوعها"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(2)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 2,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: 'fit-content',
						}}
					>
						<TextField
							id="outlined-basic"
							label="السائق"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(3)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 3,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: 'fit-content',
						}}
					>
						<TextField
							id="outlined-basic"
							label="القسم"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(4)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 4,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<TextField
							id="outlined-basic"
							label="المركز"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(5)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 5,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<TextField
							id="outlined-basic"
							label="الوجبة"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(6)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 6,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
							direction: 'rtl',
						}}
					>
						<BegTimePicker
							style={{
								padding: '15px',
								marginRight: '15px',
							}}
							className={classes.pickers}
							title="وقت الانطلاق"
							selectedDate={props.getSearchFieldFilterPerColumnDate(7)}
							withSeconds={true}
							handleDateChange={(date) => {
								console.log('sadasdasda=>', date)
								// setBeginTIme(date)
								// props.setDatesState(1, date)
								if (date) localStorage.setItem('beginTime', date)
								else localStorage.removeItem('beginTime')
								props.setSearchFieldFilterPerColumn({
									id: 7,
									name: date ? formatTime(date) : '',
									date: date,
								})
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
							direction: 'rtl',
						}}
					>
						<BegTimePicker
							style={{
								padding: '15px',
								marginRight: '15px',
							}}
							className={classes.pickers}
							title="وقت العودة"
							selectedDate={props.getSearchFieldFilterPerColumnDate(8)}
							withSeconds={true}
							handleDateChange={(date) => {
								console.log('sadasdasda=>', date)
								setEndTime(date)
								props.setDatesState(2, date)
								if (date) localStorage.setItem('endTime', date)
								else localStorage.removeItem('endTime')

								props.setSearchFieldFilterPerColumn({
									id: 8,
									name: date ? formatTime(date) : '',
									date: date,
								})
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<TextField
							id="outlined-basic"
							label="موقع الانطلاق"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(9)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 9,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<BegTimePicker
							style={{
								padding: '15px',
								marginRight: '15px',
							}}
							className={classes.pickers}
							title="مجموع التوقفات"
							selectedDate={props.getSearchFieldFilterPerColumnDate(10)}
							withSeconds={true}
							handleDateChange={(date) => {
								console.log('sadasdasda=>', date)
								setTotalParking(date)
								if (date) localStorage.setItem('totalParking', date)
								else localStorage.removeItem('totalParking')
								props.setSearchFieldFilterPerColumn({
									id: 10,
									name: date ? formatTime(date) : '',
									date: date,
								})
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<TextField
							id="outlined-basic"
							label="عدد الشحنات"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(11)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 11,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<TextField
							id="outlined-basic"
							label="الوزن الكلي"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(12)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 12,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<BegTimePicker
							style={{
								padding: '15px',
								marginRight: '15px',
							}}
							className={classes.pickers}
							title="المحرك"
							selectedDate={props.getSearchFieldFilterPerColumnDate(13)}
							withSeconds={true}
							handleDateChange={(date) => {
								console.log('sadasdasda=>', date)
								setEnginHoure(date)
								if (date) localStorage.setItem('enginHoure', date)
								else localStorage.removeItem('enginHoure')
								props.setSearchFieldFilterPerColumn({
									id: 13,
									name: date ? formatTime(date) : '',
									date: date,
								})
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<BegTimePicker
							style={{
								padding: '15px',
								marginRight: '15px',
							}}
							className={classes.pickers}
							title="الحركة"
							selectedDate={props.getSearchFieldFilterPerColumnDate(14)}
							withSeconds={true}
							handleDateChange={(date) => {
								console.log('sadasdasda=>', date)
								setWorkingHoure(date)
								if (date) localStorage.setItem('workingHoure', date)
								else localStorage.removeItem('workingHoure')
								props.setSearchFieldFilterPerColumn({
									id: 14,
									name: date ? formatTime(date) : '',
									date: date,
								})
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<TextField
							id="outlined-basic"
							label="المستخدم"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(15)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 15,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
					<div
						style={{
							margin: '5px',
							width: '200px',
						}}
					>
						<TextField
							id="outlined-basic"
							label="سبب الغياب"
							variant="outlined"
							value={props.getSearchFieldFilterPerColumn(16)}
							onChange={(e) => {
								props.setSearchFieldFilterPerColumn({
									id: 16,
									name: e.target.value,
								})
							}}
							style={{
								direction: 'rtl',
							}}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleClose} color="primary">
					اغلاق
				</Button>
				<Button onClick={submit} color="primary">
					تنقيذ
				</Button>
			</DialogActions>
		</Dialog>
	)
}
