import React from 'react'
import styled from 'styled-components'
import ProgressBar from './ProgressBar'
const PBDiv = styled.div`
position:absolute;
top:50%;
right:50%;
`;

function Loading(props) {
    return (
       <PBDiv>
           <ProgressBar/>
       </PBDiv>
    )
}

export default Loading
