import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`

function BodyMRoot(props) {
	return <Root>{props.children}</Root>
}

export default BodyMRoot
