import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { getGivenItemsJson } from '../../common/ApiHelper'
import {
	getColor,
	getBaseURL,
	handleError,
	getStatusCode,
	configureDate
} from '../../common/Utils'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import CheckBox from '../../common/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Alert from '../../common/Alert'
import Grid from '@material-ui/core/Grid'
import Table from './UsersAclTable'
import axios from 'axios'
import Cookies from 'js-cookie'
import YesNoDialog from '../../common/YesNoDialog'
import Loading from '../../common/Loading'

const url = getBaseURL()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(',')
	}
})

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
		backgroundColor: getColor('primary').dark
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	textField: {
		width: '100%',
		marginTop: '10px'
	}
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const Root2 = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
`

const RootControl = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 40px;
	width: 100%;
`
export default function FullScreenDialog(props) {
	const classes = useStyles()
	const [acls, setAcls] = useState([])

	const [isRequesting, setIsRequesting] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isError, setIsError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [formErrors, setFormErrors] = useState([])
	const [updateAcls, setUpdateAcls] = useState(false)
	const [yesNoDialog, setYesNoDialog] = useState({
		open: false,
		title: '',
		msg: ''
	})

	const [udcToDelete, setUdcToDelete] = useState(null)
	const [requiredData, setRequiredData] = useState()
	const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))

	const getInitFromDate = () => {
		var my_date = new Date()
		var first_date = new Date(
			my_date.getFullYear(),
			my_date.getMonth(),
			my_date.getDate() - 1
		)
		return first_date
	}

	useEffect(() => {
		const getAclOfUser = async userID => {
			setIsLoading(true)
			setIsError(false)
			setErrorMsg('')
			let result = null
			try {
				result = await axios({
					url: url + `/udcs/acl/list/${userID}`,
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + userData.token
					},
					data: {}
				})
				// console.log('userACL',result.data.Data)
				let tempRequired = await getGivenItemsJson({
					items: ['users_stats'],
					from: configureDate(getInitFromDate()),
					to: configureDate(getInitFromDate())
				})
				setRequiredData(tempRequired)
				setAcls(result.data.Data)
			} catch (error) {
				let errors = handleError(error)
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
				}

				setIsError(true)
				setErrorMsg(errors)
			}
			setIsLoading(false)
		}
		getAclOfUser(props.userToUpdate.user.id)
	}, [updateAcls])
	const handleEnteredEvent = (e) => {
		if (!yesNoDialog.open&&!isRequesting) {
			if (e.key === 'Enter') {
				save()
			} else if (e.key === 'Escape') {
				props.hideAclModal()
			}
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
			   
				<Dialog
					fullScreen
					open={props.show}
					onClose={props.hideAclModal}
					TransitionComponent={Transition}
					onKeyDown={handleEnteredEvent}
				>
					<AppBar className={classes.appBar}>
						<Toolbar>
							{/* <IconButton
								edge="end"
								color="inherit"
								onClick={props.hideAclModal}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton> */}
							<Typography variant="h6" className={classes.title}>
								ادارة صلاحيات المستخدم{' '}
								<span
									style={{
										fontSize: '18px',
										color: '#FFA200'
									}}
								>
									({`${props.userToUpdate.user.name}`})
								</span>
							</Typography>
							<Button autoFocus color="inherit" onClick={props.hideAclModal}>
								اغلاق
							</Button>
						</Toolbar>
					</AppBar>
					{isLoading ? (
						<Loading />
					) : (
						<Grid container spacing={3}>
							<Grid item xs={8}>
								<Root2>
									{props.userToUpdate.user.role.id > 2 ? (
										<React.Fragment>
											<RootControl>
												<Button
													disabled={isRequesting}
													variant="contained"
													color="primary"
													size="small"
													onClick={save}
												>
													اضافة
												</Button>
												<Autocomplete
													size="small"
													id="departments"
													value={
														props.selectedDepartment
															? props.selectedDepartment
															: props.departments.length > 0
																? props.departments[0]
																: null
													}
													options={props.departments}
													onChange={props.onAclDepartmentChange}
													getOptionLabel={option => option.name}
													style={{ width: 200, marginLeft: 5, marginRight: 5 }}
													renderInput={params => (
														<TextField
															{...params}
															label="الاقسام"
															variant="outlined"
														/>
													)}
												/>
												<Autocomplete
													size="small"
													id="centers"
													value={
														props.selectedCenter
															? props.selectedCenter
															: props.departments.length > 0
																? props.departments[0].centers[0]
																: null
													}
													options={props.centers}
													onChange={props.onAclCenterChange}
													getOptionLabel={option => option.name}
													style={{ width: 200 }}
													renderInput={params => (
														<TextField
															{...params}
															label="المراكز والشعب"
															variant="outlined"
														/>
													)}
												/>
												<Autocomplete
													size="small"
													id="vehicleTypes"
													value={
														props.selectedVehicleType
															? props.selectedVehicleType
															: props.vehicleTypes[0]
													}
													options={props.vehicleTypes}
													onChange={props.onVehicleTypeChange}
													getOptionLabel={option => option.name}
													style={{ width: 150, marginRight: 5, marginLeft: 5 }}
													renderInput={params => (
														<TextField
															{...params}
															label="عائدية المركبات"
															variant="outlined"
														/>
													)}
												/>
												<Autocomplete
													size="small"
													id="vehicleTypes"
													value={
														props.selectedVehicleClass
															? props.selectedVehicleClass
															: props.vehicleClasses[0]
													}
													options={props.vehicleClasses}
													onChange={props.onVehicleClassChange}
													getOptionLabel={option => option.name}
													style={{ width: 150, }}
													renderInput={params => (
														<TextField
															{...params}
															label="نوع الالية"
															variant="outlined"
														/>
													)}
												/>
											</RootControl>
											{acls && acls.length > 0 ? (
												<Table
													data={acls}
													requiredData={requiredData.data.Data.returned_items.users_stats}
													// showEditModalFun={showEditModalFun}
													showDeleteDialogFun={showDeleteDialogFun}
												></Table>
											) : (
												<p>لا توجد صلاحيات مخصصة</p>
											)}
										</React.Fragment>
									) : (
										<p>
											ان هذا المستخدم يتمتع بصلاحية المسؤول, لتخصيص صلاحياته يجب
											تغيير نوع الصلاحية الى مدخل بيانات او مسؤول تقارير
										</p>
									)}
									{yesNoDialog.open ? (
										<YesNoDialog
											open={yesNoDialog.open}
											title={yesNoDialog.title}
											msg={yesNoDialog.msg}
											onYes={onYes}
											cancel={cancel}
											isRequesting={isRequesting}
										/>
									) : null}
								</Root2>
							</Grid>

							<Grid item xs={6}>
								{formErrors && formErrors.length > 0 ? (
									<Alert
										title="مدخلات غير صحيحة"
										msg={formErrors}
										severity="error"
									></Alert>
								) : null}
							</Grid>
						</Grid>
					)}
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
	function showDeleteDialogFun(item) {
		setUdcToDelete(item)
		setYesNoDialog({
			open: true,
			title: 'حذف صلاحية؟',
			msg: 'هل انت متأكد من حذف الصلاحية؟'
		})
	}
	function onYes(e) {
		if (udcToDelete) {
			deleteUdcRequest(udcToDelete)
		}
	}

	function deleteUdcRequest(udcToDelete) {
		setIsRequesting(true)
		axios({
			url: url + `/udcs/` + udcToDelete.udc.id,
			method: 'delete',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token
			}
		})
			.then(response => {
				setIsRequesting(false)
				setYesNoDialog({
					open: false,
					title: '',
					msg: ''
				})

				let udcsTemp = acls
				let index = udcsTemp.findIndex(function (item) {
					return item.id == udcToDelete.udc.id
				})

				if (index > -1) {
					udcsTemp.splice(index, 1)
					setAcls(udcsTemp)
					setUpdateAcls(!updateAcls)
					alert('تم حذف الصلاحية بنجاح')
				}
			})
			.catch(error => {
				let errors = handleError(error)
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
				}

				setIsError(true)
				setErrorMsg(errors)
			})
		setIsRequesting(false)
	}

	function cancel(e) {
		setUdcToDelete(null)
		setYesNoDialog({
			open: false,
			title: '',
			msg: ''
		})
	}

	async function save(e) {
		let errors = validate()
		if (errors.length > 0) {
			setFormErrors(errors)
			return
		}
		let data = configureData()
		setIsRequesting(true)
		const [request, userRequired] = await Promise.all([
			makeRequest(data), getGivenItemsJson({
				items: ['users_stats'],
				from: configureDate(getInitFromDate()),
				to: configureDate(getInitFromDate())
			})
		])
		setAcls(request)
		setRequiredData(userRequired)
		setUpdateAcls(!updateAcls)
		// clear inputs
		setIsRequesting(false)
		setFormErrors([])

	}
	function validate() {
		let errors = []
		if (!props.selectedDepartment) errors.push('اختر قسم')

		if (!props.selectedVehicleClass) errors.push('اختر نوع الالية')

		if (!props.selectedCenter) errors.push('اختر مركز')

		if (!props.selectedVehicleType) errors.push('اختر عائدية المركبات')

		if (
			props.selectedCenter &&
			props.selectedDepartment &&
			props.selectedVehicleType &&
			props.selectedVehicleClass
		) {
			let aclsTemp = acls
			let index = aclsTemp.findIndex(function (item) {
				return (
					item.center_id == props.selectedCenter.id &&
					item.department_id == props.selectedDepartment.id &&
					item.vehicle_type_id == props.selectedVehicleType.id &&
					item.vehicle_class_id == props.selectedVehicleClass.id
				)
			})
			if (index > -1) {
				errors.push('الصلاحية للقسم والمركز وعائدية المركبة متكررة')
			}
		}
		return errors
	}
	function configureData() {
		let data = {
			department_id: props.selectedDepartment.id,
			center_id: props.selectedCenter.id,
			vehicle_type_id: props.selectedVehicleType.id,
			vehicle_class_id: props.selectedVehicleClass.id,
			user_id: props.userToUpdate.user.id
		}
		return data
	}

	async function makeRequest(data) {

		axios({
			url: url + `/udcs`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token
			},
			data: data
		})
			.then(response => {
				alert('تم اضافة الصلاحية بنجاح')
				let udcs = acls
				udcs.push(response.data.Data)
				return udcs
			})
			.catch(error => {
				let errors = handleError(error)

				let errorsArr = [errors]
				setFormErrors(errorsArr)

				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
				setIsRequesting(false)
			})
	}
}
