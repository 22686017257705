import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 9999,
		color: '#fff',
	},
}))

export default function BackDropLoading(props) {
	const classes = useStyles()
	const [open, setOpen] = React.useState(false)
	const handleClose = () => {
		// props?.setOpen(false)
	}
	const handleToggle = () => {
		setOpen(!open)
	}
	return (
		<Backdrop className={classes.backdrop} open={props?.open} onClick={handleClose}>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}
