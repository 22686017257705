import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import AtTimePicker from '../../common/TimePicker'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import CheckBox from '../../common/CheckBox'
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const colors = require('../../../assets/files/env.json').theme.colors

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(',')
	}
})

const AtTimeDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
`
const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
`
const SubFContainers = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	background-color: '#fff';
`

const DataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const useStyles = makeStyles(theme => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '500px',
		backgroundColor: '#fff'
	},
	pickers: {
		backgroundColor: '#fff'
	},
	textField: {
		backgroundColor: '#fff',
		marginTop: '10px'
	}
}))

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	)
}

export default function DraggableDialog(props) {
	const classes = useStyles()
	const handleEnteredEvent = (e) => {
		if (!props.isRequesting) {
			if (e.key === 'Enter') {
				props.edit()
			} else if (e.key === 'Escape') {
				props.closeEditModal()
			}
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullWidth={true}
					maxWidth="lg"
					disableBackdropClick={true}
					open={props.open}
					onClose={props.closeEditModal}
					PaperComponent={PaperComponent}
					aria-labelledby="draggable-dialog-title"
					onKeyDown={handleEnteredEvent}
				>
					<DialogTitle
						style={{
							backgroundColor: '#007AC4',
							color: '#fff',
							cursor: 'move'
						}}
						id="draggable-dialog-title"
					>
						تعديل شحنة
					</DialogTitle>
					<DialogContent
						style={{
							backgroundColor: '#DEF3FF'
						}}
					>
						<Root>
							<Autocomplete
								className={classes.autocomplete}
								id="stations"
								name="selectedStation"
								value={
									props.selectedStation
										? props.selectedStation
										: props.stations[0]
								}
								options={props.stations}
								onChange={props.onStationChange}
								getOptionLabel={option => option.caption}
								style={{ width: 300 }}
								renderInput={params => (
									<TextField {...params} label="المواقع" variant="outlined" />
								)}
							/>
							{props.selectedStation &&
							props.selectedStation.name === 'others' ? (
								<TextField
									className={classes.textField}
									id="other_station"
									name="other_station"
									label="أخرى"
									variant="outlined"
									onChange={props.onOtherStationChange}
									value={props.otherStation}
								/>
							) : null}
							<TextField
								className={classes.textField}
								id="weight"
								name="weight"
								label="الوزن المحقق"
								variant="outlined"
								onChange={props.changeWeight}
								value={props.weight}
							/>
							<AtTimeDiv>
								<CheckBox
									name="status"
									label="وقت الدخول؟"
									state={props.showAtTime}
									changeState={props.changeShowAtTime}
								/>

								{props.showAtTime ? (
									<AtTimePicker
										withSeconds={false}
										title="وقت الدخول"
										selectedDate={props.selectedAtTime}
										handleDateChange={props.onAtTimeChange}
									/>
								) : null}
							</AtTimeDiv>
							<TextField
								className={classes.textField}
								id="notes"
								name="notes"
								label="ملاحظات"
								variant="outlined"
								onChange={props.onNotesChange}
								value={props.notes}
							/>
						</Root>
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={props.closeEditModal} color="primary">
							الغاء
						</Button>
						<Button
							disabled={props.isRequesting}
							onClick={props.edit}
							color="primary"
						>
							حفظ
						</Button>
					</DialogActions>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
