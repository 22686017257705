import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { getColor, getBaseURL, formatErrorMgs2 } from '../../common/Utils'
import styled from 'styled-components'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import RowDiv from './RowInfo'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ChvsTable from './ChvsTable'
import DriversLogTable from './DriversLogTable'
import VehicleLogTable from './VehicleLogTable'
import axios from 'axios'
import NoData from '../../common/NoData'
import Cookies from 'js-cookie'
import { getStatusCode, handleErr, handleError } from '../../common/Utils'
import Loading from '../../common/Loading'
import HandleError from '../../common/HandleError'
import YesNoDialog from '../../common/YesNoDialog'
import EditChvModal from './EditCHVModal'
import { getGivenItems } from '../../common/ApiHelper'
import Snackbar from '../../common/SnackBar'
import AddChvModal from './AddChvModal'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AddDriverModal from '../Drivers/AddDriverModal'
const url = getBaseURL()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const colors = require('../../../assets/files/env.json').theme.colors
const crudColors = require('../../../assets/files/env.json').theme.colors.crud

const chvsTableHeaders = [
	'#',
	'المركز/الشعبة',
	'الوجبة',
	'الحصة',
	'هل لديها وزن',
	'المعامل',
	'تعديل',
	'حذف',
]

const vehicleLogTableHeaders = [
	'#',
	'المركز/الشعبة',
	'الوجبة',
	'الحصة',
	'هل لديها وزن',
	'المعامل',
	'التاريخ',
	// 'تاريخ التعديل',
	// 'تاريخ الحذف',
	'من قبل المستخدم',
]

const driversLogTableHeaders = [
	'#',
	'المركز/الشعبة',
	'الوجبة',
	'السائق',
	'تاريخ الاسناد',
	'من قبل المستخدم',
	'تاريخ فك الاسناد',
	'من قبل المستخدم',
]

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		backgroundColor: getColor('primary').dark,
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	textField: {
		width: '100%',
		marginTop: '10px',
	},
	dialog: {
		backgroundColor: '#ffff',
	},
	Paper: {
		width: '100%',
		marginTop: '15px',
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const Root = styled.div`
	position: 'relative';
	flex-grow: 1;
	padding: 40px;
	width: 100%;
	height: 100%;
	background-color: ${colors.others.bg};
`

const BasicsDiv = styled.div`
	margin: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
`

const NoDataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-items: center;
`

export default function FullScreenDialog(props) {
	// state
	const [vehicle, setVehicle] = useState(null)
	const [vehicleLog, setVehicleLog] = useState(null)
	const [statusCode, setStatusCode] = useState(null)
	const [errors, setErrors] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [chvToProcess, setChvToProcess] = useState(null)
	const [disableCenterField, setDisableCenterField] = useState(false)
	const [alert, setAlert] = useState({
		show: false,
		title: '',
		msg: '',
		color: 'error',
	})
	const [isRequesting, setIsRequesting] = useState(false)
	// resources
	const [departments, setDepartments] = useState([])
	const [centers, setCenters] = useState([])
	const [showVehicleLog, setShowVehicleLog] = useState(true)
	const [showDriverLog, setShowDriverLog] = useState(true)

	const [shifts, setShifts] = useState([])
	const [exShifts, setExShifts] = useState(props.shifts)
	const [Oldshift, setOldShift] = useState([])
	const [OldCenter, setOldCenter] = useState([])
	const [selectedDepartment, setSelectedDepartment] = useState(null)
	const [selectedCenter, setSelectedCenter] = useState(null)
	const [selectedVehicle, setSelectedVehicle] = useState(null)
	const [selectedShift, setSelectedShift] = useState(null)
	const [showShare, setShowShare] = useState(true)
	const [share, setShare] = useState(null)
	const [isWeightOrNot, setIsWeightOrNot] = useState(true)
	const [weightOrNotValue, setWeightOrNotValue] = useState(null)

	const [showAddChvModal, setShowAddChvModal] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [driverFormData, setDriverFormData] = useState({
		name: '',
		phone: '',
		notes: '',
	})
	const [formErrors, setFormErrors] = useState([])
	const [errCode, setErrCode] = useState({
		status: 0,
		title: '',
		server_errors: '',
		how_to_fix: '',
	})
	// alert errors
	const [alertError, setAlertError] = useState(false)
	const [alertErrorMsg, setAlertErrorMsg] = useState([])
	const [alertColor, setAlertColor] = useState('error')
	const classes = useStyles()

	const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))

	const handleLoadAllRecources = () => {
		function getVehicleDetails(vehicleID) {
			axios({
				url: url + `/vehicles/${vehicleID}?filter_flags=f`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
			})
				.then((response) => {
					console.log('vehLOG', response.data.Data)
					setVehicle(response.data.Data)

					setExShifts(response.data.Data.centers.map((item) => item.shift))
					setStatusCode(200)
					setIsLoading(false)
				})
				.catch((error) => {
					setStatusCode(error.response.status)

					let errors = handleError(error)

					let errorsArr = []

					if (errors === 409)
						// conflict
						errorsArr.push('المركبة مضافة فعلا في النظام')
					else if (errorsArr === 400 || errorsArr === 500) {
						errorsArr.push(errors)
					}

					setErrors(errorsArr)

					if (errors === 401) {
						window.location.href = '/'
						Cookies.remove('SDIMS_USER_DATA')
						return 401
					}
					setIsLoading(false)
				})
		}
		getVehicleDetails(props.data.vehicle.id)
		getChvsData(props.data.vehicle.id)
		// load resources
		const fetchResources = async () => {
			let result = await getGivenItems(['departments', 'vehicles', 'shifts'])
			if (!result.data) {
				setAlert({
					show: true,
					title: 'حدثت مشكلة ما',
					msg: 'لم يتم تحميل المصادر',
					color: 'error',
				})
			}

			let arrays = result.data.Data.returned_items

			setDepartments(arrays.departments)
			setCenters(arrays.departments.length ? arrays.departments[0].centers : [])
			setShifts(arrays.shifts)
			// setVehicles(arrays.vehicles)
			// setSelectedCenter(arrays.departments.length > 0 ? (arrays.departments[0].centers.length > 0 ? arrays.departments[0].centers[0] : null) : null)
			// setSelectedDepartment(arrays.departments.length > 0 ? arrays.departments[0] : null)
			// setSelectedShift(arrays.shifts.length > 0 ? arrays.shifts[0] : null)
			// setSelectedVehicle(arrays.length>0?arrays.vehicles[0]:null)
		}
		fetchResources()
	}
	useEffect(() => {
		handleLoadAllRecources()
	}, [props.show])
	let newShifts = shifts.filter(function (objFromA) {
		return !exShifts.find(function (objFromB) {
			return objFromA.id === objFromB.id
		})
	})
	newShifts = newShifts.sort(function (a, b) {
		return a.id - b.id
	})
	const getChvsData = async (id) => {
		let data = null;
		try {
			data = await axios({
				url: url + '/chvs',
				method: 'get',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
				params: {
					vehicle_id: id,
				}
			})
			if (data && data?.status) {
				let arr = []
				let arr2 = []
				if (data.data) {
					data.data.map((item) => {
						if (item?.center) arr.push(item?.center)
						if (item?.shift) arr2.push(item?.shift)
					})
				}

				const uniqueArray = arr.filter(
					(object, index, self) =>
						index ===
						self.findIndex(
							(o) => o.id === object.id
						)
				)
				const uniqueArray2 = arr2.filter(
					(object, index, self) =>
						index ===
						self.findIndex(
							(o) => o.id === object.id
						)
				)
				props.setListOfChvs(uniqueArray)
				props.setListOfChvsShift(uniqueArray2)

			}
		} catch (error) {
			console.log(error?.message)
		}
	}
	function handleCreateDriver(e) {
		setIsLoading(true)
		let result = validateAddDriver()

		if (result.length === 0) {
			let data = new FormData()
			data.append('name', driverFormData.name)
			if (driverFormData.phone.length > 0)
				data.append('phone', driverFormData.phone)

			if (driverFormData.notes.length > 0) {
				if (('notes', driverFormData.notes));
			}

			createDriverRequest(data)
		} else {
			setIsLoading(false)
			setFormErrors(result)
		}
	}
	function changeField(e) {
		// console.log('sdasd')
		setDriverFormData({
			...driverFormData,

			[e.target.name]: e.target.value,
		})
	}
	function validateAddDriver() {
		let errors = []

		if (!driverFormData.name) errors.push('يرجى كتابة اسم السائق الثلاثي')

		if (
			driverFormData.phone.length > 0 &&
			(driverFormData.phone.length < 4 ||
				!driverFormData.phone.startsWith('964'))
		) {
			errors.push('يرجى كتابة رقم الهاتف بصورة صحيحة')
		}
		if (driverFormData.notes.length > 0 && driverFormData.notes.length < 4) {
			errors.push('يجب ان تقوم بكتابة ملاحظات باحرف لا تقل عن 4')
		}

		return errors
	}
	function createDriverRequest(data) {
		setIsLoading(true)
		setFormErrors([])
		axios({
			url: url + `/drivers`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				setAlertError(true)
				let msg = 'تم اضافة السائق بنجاح'
				if (response.data.Error) {
					msg = response.data.Error.msg + ' :' + 'مع وجود خطأ الاتي '
					let errors = []
					for (let i = 0; i < response.data.Error.details.length; i++) {
						errors.push(response.data.Error.details[i])
					}
					if (errors) setFormErrors(errors)
				} else {
					setFormErrors([])
				}
				handleLoadAllRecources()
				setAlertErrorMsg(msg)
				props.fetchResources(response?.data?.Data)
				setAlertColor('success')
				// clear inputs
				setDriverFormData({
					...driverFormData,
					name: '',
					phone: '',
					notes: '',
				})
				setFormErrors([])
				setOpenModal(false)
				setIsLoading(false)
				props.setSelectedDriver(response?.data?.Data)
				setTimeout(() => {
					props.assignNewDriver({
						vehicle: props.data.vehicle,
						index: props.data.index,
					})
				}, 1500)
				console.log('asdasdsadsadsadasdsa=====>', response?.data?.Data)
			})
			.catch((error) => {
				let errors = handleError(error)
				let errorsArr = []

				if (errors === 409)
					// conflict
					errors.push('المركبة مضافة فعلا في النظام')
				else if (errors === 400 || errors === 500) {
					errorsArr.push(errors)
				}

				setFormErrors(errorsArr)

				setIsLoading(false)

				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}
	const handleEnteredEvent = (e) => {
		if (e.key === 'Enter') {
			// save()
		} else if (e.key === 'Escape') {
			props.closeDetailsModal()
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullScreen
					open={props.show}
					onClose={props.hideEditModal}
					TransitionComponent={Transition}
					onKeyDown={handleEnteredEvent}
				>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton
								edge="end"
								color="inherit"
								// onClick={props.hideEditModal}
								onClick={props.closeDetailsModal}
								aria-label="close"
								style={{
									marginLeft: '10px',
								}}
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={classes.title}>
								تفاصيل المركبة
							</Typography>
							<Button
								autoFocus
								color="inherit"
								onClick={props.closeDetailsModal}
							>
								اغلاق
							</Button>
						</Toolbar>
					</AppBar>
					<Root>
						{isLoading ? (
							<Loading />
						) : errors && errors.length > 0 ? (
							<HandleError errors={errors} />
						) : (
							<Grid
								container
								spacing={3}
								direction="column"
								justify="center"
								alignItems="flex-start"
							>
								{/* for basic info */}
								<Grid item xs={12} spacing={3} container>
									<Paper className={classes.Paper}>
										<BasicsDiv>
											<h5
												style={{
													fontSize: '1.1rem',
													margin: '5px',
													backgroundColor: '#FFA200',
													padding: '10px',
													marginBottom: '15px',
												}}
											>
												معلومات المركبة الاساسية
											</h5>

											<Divider
												style={{
													width: '100%',
												}}
											/>
											<RowDiv
												k="رقم المركبة"
												value={vehicle ? vehicle.plate_number : '----'}
											></RowDiv>

											<RowDiv
												k="الماركة"
												value={vehicle ? vehicle.brand : '----'}
											></RowDiv>
											<RowDiv
												k="الموديل"
												value={vehicle ? vehicle.model : '----'}
											></RowDiv>

											<RowDiv
												k="الحالة"
												value={
													vehicle
														? vehicle.is_active == '1'
															? 'مفعلة'
															: ' غير مفعلة'
														: '----'
												}
											></RowDiv>

											<RowDiv
												k="قيمة الادامة"
												value={vehicle ? vehicle.ration_for_stability : '----'}
											></RowDiv>

											<RowDiv
												k="دخولية الكراج"
												value={
													vehicle
														? vehicle.notes == '1'
															? 'نعم'
															: 'كلا'
														: '----'
												}
											></RowDiv>

											<RowDiv
												k="الهوية التعريفية"
												value={vehicle ? vehicle.identity : '----'}
											>
												<RowDiv
													k="عدد الشحنات"
													value={vehicle ? vehicle.freights_count : '----'}
												></RowDiv>
											</RowDiv>
											<RowDiv
												k="عائدية المركبة"
												value={vehicle ? vehicle.vehicle_type.name : '----'}
											></RowDiv>

											<RowDiv
												k="الصنف"
												value={vehicle ? vehicle.class.name : '----'}
											></RowDiv>
											<RowDiv
												k="السائق الحالي"
												value={
													vehicle
														? vehicle.current_driver
															? vehicle.current_driver.driver.name
															: ''
														: '----'
												}
											></RowDiv>

											<RowDiv
												k="المستخدم المُنشئ"
												value={
													vehicle
														? vehicle.user
															? vehicle.user.name
															: '----'
														: '----'
												}
											></RowDiv>
											<RowDiv
												k="تاريخ الانشاء"
												value={vehicle ? vehicle.created_at : '----'}
											></RowDiv>

											<RowDiv
												k="تاريخ اخر تعديل"
												value={vehicle ? vehicle.updated_at : '----'}
											></RowDiv>
										</BasicsDiv>
									</Paper>
								</Grid>
								{/* chvs */}
								<Grid
									style={{
										paddingRight: '0px',
									}}
									item
									xs={12}
								>
									<Paper className={classes.Paper}>
										<BasicsDiv>
											<h5
												style={{
													fontSize: '1.1rem',
													margin: '5px',
													backgroundColor: '#FFA200',
													padding: '10px',
													marginBottom: '15px',
												}}
											>
												الوجبات التي تعمل بها المركبة
											</h5>

											<IconButton onClick={addChv}>
												<AddCircleIcon style={{ color: 'green' }} />
											</IconButton>

											<Divider
												style={{
													width: '100%',
												}}
											/>
											{vehicle ? (
												vehicle.centers ? (
													((vehicle.centers = vehicle.centers.sort(function (
														a,
														b
													) {
														return a.shift_id - b.shift_id
													})),
														(
															<ChvsTable
																header={chvsTableHeaders}
																list={vehicle.centers}
																editChv={editChv}
																deleteChv={deleteChv}
															></ChvsTable>
														))
												) : (
													<p>لا توجد ارتباطات</p>
												)
											) : null}
										</BasicsDiv>
									</Paper>
								</Grid>
								{/* drivers log */}
								<Grid item xs={12}>
									<Paper className={classes.Paper}>
										<BasicsDiv>
											<h5
												style={{
													fontSize: '1.1rem',
													margin: '5px',
													backgroundColor: '#FFA200',
													padding: '10px',
													marginBottom: '15px',
												}}
											>
												سجل المركبة
											</h5>
											<div
												style={{
													fontSize: '1rem',
													margin: '5px',
													color: '#013588',
													marginBottom: '15px',
													cursor: 'pointer',
												}}
												onClick={() => {
													setShowVehicleLog(!showVehicleLog)
												}}
											>
												اظهار / اخفاء
											</div>

											<Divider
												style={{
													width: '100%',
												}}
											/>
											{vehicle ? (
												vehicle.vehicle_log ? (
													showVehicleLog ? (
														<VehicleLogTable
															header={vehicleLogTableHeaders}
															list={vehicle.vehicle_log}
														></VehicleLogTable>
													) : null
												) : (
													<p>لا يوجد سجل للمركبة حالياً</p>
												)
											) : null}
										</BasicsDiv>
										<BasicsDiv>
											<div style={{ display: 'flex', gap: '5px' }}>
												<h5
													style={{
														fontSize: '1.1rem',
														margin: '5px',
														backgroundColor: '#FFA200',
														padding: '10px',
														marginBottom: '15px',
													}}
												>
													سجل السائقين
												</h5>

												{/* <IconButton
													onClick={() =>
														props.assignNewDriver({
															vehicle: props.data.vehicle,
															index: props.data.index,
														})
													}
												>
													<AddCircleIcon style={{ color: crudColors.c }} />
												</IconButton> */}
												<Button
													onClick={() =>
														props.assignNewDriver({
															vehicle: props.data.vehicle,
															index: props.data.index,
														})
													}
													variant="contained"
													color="primary"
													className={classes.button}
													startIcon={<AddCircleIcon />}
													style={{
														height: '47px',
														marginTop: '4px',
														backgroundColor: '#575c60'
													}}
												>
													<span
														style={{
															paddingRight: '10px',
														}}
													>
														اسناد سائق
													</span>
												</Button>
												<Button
													onClick={() => setOpenModal(true)}
													variant="contained"
													color="primary"
													className={classes.button}
													startIcon={<AddCircleIcon />}
													style={{
														height: '47px',
														marginTop: '4px',
														backgroundColor: '#575c60'
													}}
												>
													<span
														style={{
															paddingRight: '10px',
														}}
													>
														اضافة سائق
													</span>
												</Button>
											</div>
											<div
												style={{
													fontSize: '1rem',
													margin: '5px',
													color: '#013588',
													marginBottom: '15px',
													cursor: 'pointer',
												}}
												onClick={() => {
													setShowDriverLog(!showDriverLog)
												}}
											>
												اظهار / اخفاء
											</div>
											<Divider
												style={{
													width: '100%',
												}}
											/>
											{vehicle ? (
												vehicle.drivers_log ? (
													showDriverLog ? (
														<DriversLogTable
															header={driversLogTableHeaders}
															list={vehicle.drivers_log}
														></DriversLogTable>
													) : null
												) : (
													<p>لا توجد ارتباطات</p>
												)
											) : null}
										</BasicsDiv>
									</Paper>
								</Grid>

								<Grid item xs={12}></Grid>

							</Grid>
						)}
					</Root>
				</Dialog>
				{showDeleteModal ? (
					<YesNoDialog
						open={showDeleteModal}
						title="حذف العمل في وجبة"
						msg="هل انت متأكد من الحذف؟"
						closeDialog={closeDialog}
						cancel={cancel}
						onYes={onYes}
						isRequesting={isRequesting}
					/>
				) : null}
				{showEditModal ? (
					<EditChvModal
						open={showEditModal}
						closeEditModal={closeEditModal}
						isRequesting={isRequesting}
						disableCenterField={disableCenterField}
						shifts={shifts}
						departments={departments}
						centers={centers}
						selectedCenter={selectedCenter}
						selectedDepartment={selectedDepartment}
						selectedShift={selectedShift}
						selectedVehicle={selectedVehicle}
						share={share}
						setWeightOrNotValue={(value) => {
							setWeightOrNotValue(value)
						}}
						weightOrNotValue={weightOrNotValue}
						setIsWeightOrNot={setIsWeightOrNot}
						isWeightOrNot={isWeightOrNot}
						onShareChange={onShareChange}
						onDepartmentChange={onDepartmentChange}
						onCenterChange={onCenterChange}
						onShiftChange={onShiftChange}
						onVehicleChange={onVehicleChange}
						edit={edit}
						showShare={showShare}
						changeShowShare={changeShowShare}
					/>
				) : null}
				{showAddChvModal ? (
					<AddChvModal
						open={showAddChvModal}
						closeAddModal={closeAddModal}
						isRequesting={isRequesting}
						shifts={newShifts}
						disableCenterField={disableCenterField}
						departments={departments}
						centers={centers}
						selectedCenter={selectedCenter}
						selectedDepartment={selectedDepartment}
						selectedShift={selectedShift}
						selectedVehicle={selectedVehicle}
						share={share}
						setWeightOrNotValue={(value) => {
							setWeightOrNotValue(value)
						}}
						weightOrNotValue={weightOrNotValue}
						setIsWeightOrNot={setIsWeightOrNot}
						isWeightOrNot={isWeightOrNot}
						onShareChange={onShareChange}
						onDepartmentChange={onDepartmentChange}
						onCenterChange={onCenterChange}
						onShiftChange={onShiftChange}
						onVehicleChange={onVehicleChange}
						save={save}
						showShare={showShare}
						changeShowShare={changeShowShare}
					/>
				) : null}
				{openModal ? (
					<AddDriverModal
						show={openModal}
						hideAddDriverModal={() => setOpenModal(false)}
						create={handleCreateDriver}
						changeField={changeField}
						driverData={driverFormData}
						formErrors={formErrors}
						isLoading={isLoading}
					></AddDriverModal>
				) : null}
				{alert.show ? (
					<Snackbar
						show={true}
						msg={alert.msg}
						period={6000}
						closeSnackbar={closeSnackbar}
						severity={alert.color}
					/>
				) : null}
				{alertError ? (
					<Snackbar
						title="خطأ"
						msg={alertErrorMsg}
						show={alertError}
						period={6000}
						severity={alertColor}
						closeSnackbar={(event, reason) => {
							if (reason === 'clickaway') {
								return
							}
							setAlertError(false)
							setErrCode({
								status: 0,
								title: '',
								server_errors: '',
								how_to_fix: '',
							})
						}}
					></Snackbar>
				) : null}
			</StylesProvider>
		</ThemeProvider >
	)
	function save(e) {
		let errors = validate()
		if (errors.length > 0) {
			setAlert({
				show: true,
				title: 'خطأ في المدخلات',
				msg: formatErrorMgs2(errors),
				color: 'warning',
			})
			return
		}

		let data = {
			vehicle_id: vehicle.id,
			center_id: selectedCenter.id,
			shift_id: selectedShift.id,
			share: share,
			coefficient_weight_check: isWeightOrNot ? 1 : 0,
			coefficient_weight_or_not: weightOrNotValue,
		}
		addChvRequest(data)
	}
	function closeAddModal(e) {
		setShowAddChvModal(false)
	}
	function addChv(e) {
		setSelectedDepartment(
			departments ? (departments.length > 0 ? departments[0] : null) : null
		)
		setCenters(
			departments ? (departments.length > 0 ? departments[0].centers : []) : []
		)
		setSelectedCenter(centers ? (centers.length > 0 ? centers[0] : null) : null)
		setSelectedShift(
			newShifts ? (newShifts.length > 0 ? newShifts[0] : null) : null
		)
		setShowAddChvModal(true)
	}
	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlert({
			show: false,
			title: '',
			msg: '',
			color: '',
		})
	}
	function edit(e) {
		if (chvToProcess) {
			let errors = validate()
			if (errors.length > 0) {
				setAlert({
					show: true,
					title: 'خطأ في المدخلات',
					msg: formatErrorMgs2(errors),
					color: 'warning',
				})
				return
			}
			let data = {
				vehicle_id: vehicle.id,
				center_id: selectedCenter.id,
				shift_id: selectedShift.id,
				share: showShare ? share : null,
				coefficient_weight_check: isWeightOrNot ? 1 : 0,
				coefficient_weight_or_not: weightOrNotValue,
				_method: 'PUT',
			}
			editRequest(data, Oldshift, OldCenter, chvToProcess)
		}
	}
	function addChvRequest(data) {
		setIsRequesting(true)
		axios({
			url: url + `/chvs`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				// console.log(response.data.Data)
				// add new chv to centers
				let veh = vehicle
				veh.centers.push(response.data.Data)
				setVehicle(veh)
				setExShifts(veh.centers.map((item) => item.shift))
				if (props.data.vehicle.id)
					getChvsData(props.data.vehicle.id)
				setAlert({
					show: true,
					title: 'تمت الاضافة بنجاح',
					msg: 'تمت الاضافة بنجاح',
					color: 'success',
				})
				setShowAddChvModal(false)
				setIsRequesting(false)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setIsRequesting(false)
				setAlert({
					show: true,
					title: 'حدث خطأ ما',
					msg: `${errCodeObj.how_to_fix}`,
					color: 'error',
				})
			})
	}
	function validate() {
		let errors = []
		if (!selectedDepartment) errors.push('يجب اختيار القسم')
		if (!selectedCenter) errors.push('يجب احتيار المركز')
		if (!selectedShift) errors.push('يجب اختيار الوجبة')
		if (showShare) {
			if (!share || share < 0) errors.push('يجب ادخال قيمة الحصة بشكل صحيح')
		}
		if (!weightOrNotValue) {
			errors.push('يجب ادخال معامل الوزن او لغير الوزن')
		}

		return errors
	}
	function editRequest(data, Oldshift, OldCenter, chvToProcess) {
		setIsRequesting(true)
		axios({
			url: url + `/chvs/${chvToProcess.chv.chv_id}`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				// find chv record in center to update
				let vehicleTemp = vehicle
				let msg = null
				if (vehicleTemp && vehicleTemp?.centers) {
					vehicleTemp.centers[chvToProcess?.index] = response?.data?.Data
					setExShifts(vehicleTemp?.centers.map((item) => item?.shift))
				}

				setVehicle(vehicleTemp)
				// // let tempShift = props.data.vehicle.current_driver.length? props.data.vehicle.current_driver.filter(item=>item.shift_id === Oldshift.id) : null
				let tempRecord = props?.data?.vehicle?.current_driver?.length
					? props.data.vehicle?.current_driver?.filter(
						(item) =>
							item?.center_id === OldCenter?.id &&
							item?.shift_id === Oldshift?.id
					)
					: null
				// // let tempCenter = props.data.vehicle.current_driver.length? props.data.vehicle.current_driver.filter(item=>item.center_id === OldCenter.id && item.shift_id === Oldshift.id) : null
				let isRecordChanged = true
				// let isShiftChanged = true
				// let isCenterChanged = true
				console.log('this is tempsandc', tempRecord)
				// console.log('tjis isitem', props.data.vehicle.current_driver)
				// console.log('this is vehicleTemp.centers', vehicleTemp?.centers)
				if (vehicleTemp && vehicleTemp?.centers && vehicleTemp?.centers?.length)
					vehicleTemp.centers.map((item) => {
						// if (tempShift.length>0){
						// 	if (item.shift_id === tempShift[0].shift_id) {
						// 		isShiftChanged = false
						// 	}
						// }
						if (tempRecord?.length > 0) {
							if (
								item.center_id === tempRecord[0]?.center_id &&
								item.shift_id === tempRecord[0]?.shift_id
							) {
								isRecordChanged = false // that means the shift and center are the same so no need to unbound driver
								// means the share is probably changed so we just send the request without unbounding
								// if the shift or the center of the tempRecord cant be found in the vehicleTemp array ..
								// then there is a change in either the center or the shift so we need to unbound the driver.
							}
						}
					})
				if (isRecordChanged && tempRecord && tempRecord[0]) {
					props.unbindDriverFromVehicle(tempRecord[0])
					// tempCenter? tempCenter.length>0?(msg ='تم تعديل السجل بنجاح و فك اسناد سائق الوجبة', tempCenter[0]):null : null
				}
				if (props.data.vehicle.id)
					getChvsData(props.data.vehicle.id)
				setAlert({
					show: true,
					title: 'تم التعديل',
					msg: msg ? msg : 'تم تعديل السجل بنجاح',
					color: 'success',
				})
				setShowEditModal(false)
				setIsRequesting(false)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setIsRequesting(false)
				setAlert({
					show: true,
					title: 'حدث خطأ ما',
					msg: `${errCodeObj.how_to_fix}`,
					color: 'error',
				})
			})
	}
	function onDepartmentChange(e, value) {
		if (value) {
			setSelectedDepartment(value)
			setCenters(value.centers)
		}
	}
	function onCenterChange(e, value) {
		setSelectedCenter(value)
	}
	function onVehicleChange(e, value) {
		setSelectedVehicle(value)
	}
	function onShiftChange(e, value) {
		setSelectedShift(value)
	}
	function closeEditModal() {
		setChvToProcess(null)
		setShare(null)
		// setDisableCenterField(false)
		setShowEditModal(false)
	}
	function onShareChange(e) {
		setShare(e.target.value)
	}
	function changeShowShare(e) {
		if (e) {
			setShowShare(e.target.checked)
		}
	}
	function editChv(chvObj) {
		if (!centers || !departments || !shifts) {
			setAlert({
				show: true,
				title: 'لم يتم تحميل المصادر بشكل صحيح',
				msg: 'اعد تحميل الصفحة',
				color: 'error',
			})
			return
		}
		setChvToProcess(chvObj)
		if (chvObj.chv.share) {
			setShowShare(true)
			setShare(chvObj.chv.share)
			setWeightOrNotValue(chvObj?.chv?.coefficient_weight_or_not)
			setIsWeightOrNot(
				chvObj?.chv?.coefficient_weight_check == 1 ? true : false
			)
		} else {
			setShowShare(false)
			setShare(0)
		}
		let index = departments.findIndex(function (item) {
			return item.id === chvObj.chv.center.department_id
		})
		if (index > -1) {
			setSelectedDepartment(departments[index])
			let centerIndex = departments[index].centers.findIndex(function (item) {
				return item.id === chvObj.chv.center.id
			})
			if (centerIndex > -1) {
				setSelectedCenter(departments[index].centers[centerIndex])
			}
			setSelectedShift(chvObj.chv.shift)
			// console.log("this is selected shift for" , shift)
		} else {
			setSelectedDepartment(
				departments ? (departments.length > 0 ? departments[0] : null) : null
			)
			setSelectedCenter(
				departments
					? departments[0]?.centers
						? departments[0].centers[0]
						: null
					: null
			)
		}
		console.log('chvvv', chvObj.chv)
		setOldShift(chvObj.chv.shift)
		setOldCenter(chvObj.chv.center)
		// setSelectedShift(newShifts ? (newShifts.length > 0 ? newShifts[0] : null) : null)
		// setDisableCenterField(true)
		setShowEditModal(true)
	}
	function deleteChv(chvObj) {
		setChvToProcess(chvObj)
		setOldShift(chvObj.chv.shift)
		setShowDeleteModal(true)
	}
	function closeDialog(e) {
		setChvToProcess(null)
		setShowDeleteModal(false)
	}
	function cancel(e) {
		setChvToProcess(null)
		setShowDeleteModal(false)
	}
	function onYes(e) {
		if (chvToProcess) {
			deleteRequest(chvToProcess, Oldshift)
		}
	}
	function deleteRequest(chvToProcess, Oldshift) {
		setIsRequesting(true)
		axios({
			url: url + `/chvs/${chvToProcess.chv.chv_id}`,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: {
				id: chvToProcess.chv.chv_id,
				vehicle_id: vehicle.id,
				center_id: chvToProcess.chv.center_id,
				shift_id: chvToProcess.chv.shift_id,
				share: chvToProcess.chv.share,
			},
		})
			.then((response) => {
				let veh = vehicle
				let index = veh.centers.findIndex(function (item) {
					return item.chv_id === chvToProcess.chv.chv_id
				})
				if (index > -1) {
					veh.centers.splice(index, 1)
					setVehicle(veh)
					setExShifts(veh.centers.map((item) => item.shift))
					setChvToProcess(null)
				}
				let msg = null
				let tempShift = props.data.vehicle.current_driver.filter(
					(item) => item.shift_id === Oldshift.id
				)
				props.unbindDriverFromVehicle(
					tempShift.length > 0
						? ((msg = 'تم حذف السجل بنجاح و فك اسناد سائق الوجبة'),
							tempShift[0])
						: null
				)
				setAlert({
					show: true,
					title: 'تم الحذف',
					msg: msg ? msg : 'تم حذف السجل بنجاح',
					color: 'success',
				})
				setShowDeleteModal(false)
				setIsRequesting(false)
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setIsRequesting(false)
				setAlert({
					show: true,
					title: 'حدث خطأ ما',
					msg: `${errCodeObj.how_to_fix}`,
					color: 'error',
				})
			})
	}
}
