import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import MyTableRow from '../../common/TableRow'
import MyTableCell from '../../common/CustomTableCell'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1'
import styled from 'styled-components'
import CancelIcon from '@material-ui/icons/Cancel'

const crudColors = require('../../../assets/files/env.json').theme.colors.crud
const headers = require('../../../assets/files/headers.json').drivers_table

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	}
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		},
		borderBottom:'2px solid #c4ddec'

	}
}))(TableRow)

const AssignDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px;
	background-color: #393e46;
	border-radius: 5px;
	color: white;
`

const useStyles = makeStyles({
	table: {
		minWidth: 700
	}
})
const styles = theme => ({
	table: {
		minWidth: 700
	}
})

// const MyTooltip = withStyles({
//   tooltip: {
//     color: "white",
//     backgroundColor:'#007AC4' ,
//     fontSize:'1.1rem'
//   }
// })(Tooltip);

class CustomizedTables extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			counter:1
		}
	}
	componentDidMount() {}
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.data === nextProps.data) {
			return false
		} else {
			return true
		}
	}

	getVehicleList(items) {
		let info = ''
		for (let i = 0; i < items.length; i++) {
			info = info + ', ' + items[i].vehicle.plate_number
		}
		return info
	}
	render() {
		const openPop = Boolean(this.state.anchorEl)

		const { classes } = this.props
		let prop = this.props
		let that = this
		let numbering = 0
		let countedResult=((this.props?.pageNumber-1)*this.props?.pageSize)

		return (
			<TableContainer component={Paper}>
				<Table
					size="small"
					className={classes.table}
					aria-label="customized table"
				>
					<TableHead>
						<MyTableRow header={headers}></MyTableRow>
					</TableHead>
					<TableBody>
						{this.props.data.map(function(item, index) {
							numbering++
							
							return (
								<StyledTableRow key={index}>
									<MyTableCell
										key={'driver_a' + index}
										value={(numbering+countedResult)}
									></MyTableCell>
									<MyTableCell
										key={'driver_b' + index}
										value={item.name}
									></MyTableCell>
									<MyTableCell
										key={'driver_c' + index}
										value={item.phone}
									></MyTableCell>
									<MyTableCell
										key={'driver_d' + index}
										value={item.notes}
									></MyTableCell>

									<StyledTableCell key={'veh_e' + index} align="right">
										{item.current_bounding && item.current_bounding.length ? (
											item.current_bounding.length > 1 ? (
												<AssignDiv>
													<IconButton
														onClick={() =>
															prop.showBoundedVehicles({
																driver: item,
																index: index
															})
														}
													>
														<ExposurePlus1Icon style={{ color: 'white' }} />
													</IconButton>

													<IconButton
														onClick={() =>
															prop.assignDriverToVehicle({
																driver: item,
																index: index
															})
														}
													>
														<AddCircleIcon style={{ color: crudColors.u }} />
													</IconButton>
												</AssignDiv>
											) : (
												<AssignDiv>
													<IconButton
														onClick={() =>
															prop.showBoundedVehicles({
																driver: item,
																index: index
															})
														}
													>
														<p
															style={{
																fontSize: '1rem',
																color: 'white'
															}}
														>
															{item.current_bounding[0].vehicle.plate_number}
														</p>
													</IconButton>

													<IconButton
														onClick={() =>
															prop.unbindDriverFromVehicle({
																driver: item,
																index: index
															})
														}
													>
														<CancelIcon style={{ color: crudColors.d }} />
													</IconButton>
													<IconButton
														onClick={() =>
															prop.assignDriverToVehicle({
																driver: item,
																index: index
															})
														}
													>
														<AddCircleIcon style={{ color: crudColors.u }} />
													</IconButton>
												</AssignDiv>
											)
										) : (
											<IconButton
												onClick={() =>
													prop.assignDriverToVehicle({
														driver: item,
														index: index
													})
												}
											>
												<AddCircleIcon style={{ color: crudColors.u }} />
											</IconButton>
										)}
									</StyledTableCell>

									{/* <StyledTableCell key={'veh_f' + index} align="right">
										<IconButton
											onClick={() =>
												prop.openBoundingLogModal({
													driver: item,
													index: index
												})
											}
										>
											<LowPriorityIcon style={{ color: crudColors.r }} />
										</IconButton>
									</StyledTableCell> */}

									<MyTableCell
										key={'driver_g' + index}
										value={item.user ? item.user.name : '----'}
									></MyTableCell>

									<StyledTableCell key={'veh_h' + index} align="right">
										<IconButton
											onClick={() =>
												prop.showEditModalFun({
													driver: item,
													index: index
												})
											}
										>
											<EditIcon style={{ color: crudColors.u }} />
										</IconButton>
									</StyledTableCell>
								</StyledTableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
}

export default withStyles(styles, { withTheme: true })(CustomizedTables)
