import React from 'react'
import styled from 'styled-components'
import NoData from '../common/NoData'

const Root = styled.div`
display:flex;
flex-direction:column;
align-content:center;
justify-items:center;
`;

function NoDataDiv(props) {
    return (
        <Root>
           <NoData>

           </NoData>
        </Root>
    )
}

export default NoDataDiv
