import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import MyTableRow from '../../common/TableRow'
import MyTableCell from '../../common/CustomTableCell'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { throwStatement } from '@babel/types'
const crudColors = require('../../../assets/files/env.json').theme.colors.crud

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow)

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
})
const styles = (theme) => ({
	table: {
		minWidth: 700,
	},
})

class CustomizedTables extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
		// console.log(props.list)

		this.compare = this.compare.bind(this)
	}
	shouldComponentUpdate(nextProps, nextState) {
		// if (this.props.data === nextProps.data) {
		//   return false;
		// } else {
		//   return true;
		// }
		if (this.props.list) {
			this.props.list.sort(this.compare)
		}
		return true
	}

	render() {
		const { classes } = this.props
		let prop = this.props
		let numbering = 0

		return (
			<TableContainer component={Paper}>
				<Table
					size="small"
					className={classes.table}
					aria-label="customized table"
				>
					<TableHead>
						<MyTableRow header={this.props.header}></MyTableRow>
					</TableHead>
					<TableBody>
						{/* {console.log(this.props.list)} */}
						{this.props.list.map(function (item, index) {
							numbering++
							return (
								<StyledTableRow key={index}>
									<MyTableCell
										key={'chv_a' + index}
										value={numbering}
									></MyTableCell>
									<MyTableCell
										key={'chv_b' + index}
										value={item.center.name}
									></MyTableCell>
									<MyTableCell
										key={'chv_c' + index}
										value={item.shift.name}
									></MyTableCell>
									<MyTableCell
										key={'chv_d' + index}
										value={item.share}
									></MyTableCell>
									<MyTableCell
										key={'chv_d' + index}
										value={item?.coefficient_weight_check==1?'نعم':'كلا'}
									></MyTableCell>
									<MyTableCell
										key={'chv_d' + index}
										value={item?.coefficient_weight_or_not}
									></MyTableCell>
									<StyledTableCell key={'chv_e' + index} align="right">
										<IconButton
											onClick={() =>
												prop.editChv({
													chv: item,
													index: index,
												})
											}
										>
											<EditIcon style={{ color: crudColors.u }} />
										</IconButton>
									</StyledTableCell>
									<StyledTableCell key={'chv_f' + index} align="right">
										<IconButton
											onClick={() =>
												prop.deleteChv({
													chv: item,
													index: index,
												})
											}
										>
											<DeleteForeverIcon style={{ color: crudColors.d }} />
										</IconButton>
									</StyledTableCell>
								</StyledTableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
	compare(a, b) {
		if (a.shift_id < b.shift_id) {
			return -1
		}
		if (a.shift_id > b.shift_id) {
			return 1
		}
		return 0
	}
}

export default withStyles(styles, { withTheme: true })(CustomizedTables)
