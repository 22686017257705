import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import MyTableRow from '../../common/TableRow'
import MyTableCell from '../../common/CustomTableCell'
import Cookies from 'js-cookie'

const headers =
	require('../../../assets/files/headers.json').VehiclesNotAllowTable

const crudColors = require('../../../assets/files/env.json').theme.colors.crud
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		borderBottom:'2px solid #c4ddec'

	},
}))(TableRow)

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
})

function VehiclesNotAllowTable(props) {
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (e) {
		console.log(e)
	}
	const classes = useStyles()
	let numbering = 0
	let prop = props
	// console.log(prop.data)
	return (
		<TableContainer component={Paper}>
			<Table
				size="small"
				className={classes.table}
				aria-label="customized table"
			>
				<TableHead>
					<MyTableRow header={headers}></MyTableRow>
				</TableHead>
				<TableBody>
					{prop.data?.map((item, index) => {
						numbering++
						return (
							<StyledTableRow key={index}>
								<MyTableCell
									key={'VehiclesNotAllowTable_numbering1' + numbering}
									value={numbering}
								></MyTableCell>
								<MyTableCell
									key={'VehiclesNotAllowTable_numbering2' + numbering}
									value={item.user}
								></MyTableCell>

								<MyTableCell
									key={'VehiclesNotAllowTable_name' + numbering}
									value={item.vehicleName}
								></MyTableCell>

								<MyTableCell
									key={'VehiclesNotAllowTable_centername' + numbering}
									value={item.centername}
								></MyTableCell>

								<MyTableCell
									key={'VehiclesNotAllowTable_shiftname' + numbering}
									value={item.shiftName}
								></MyTableCell>

								<MyTableCell
									key={'VehiclesNotAllowTable_status' + numbering}
									value={item.status}
								></MyTableCell>

								<MyTableCell
									key={'VehiclesNotAllowTable_todaydate' + numbering}
									value={item.todaydate}
								></MyTableCell>
							</StyledTableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default VehiclesNotAllowTable
