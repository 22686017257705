import React, { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AtTime from '../../common/TimePicker'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'
import CheckBox from '../../common/CheckBox'

const AtTimeDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
`
const useStyles = makeStyles(theme => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '100%'
	}
}))
function SubControls(props) {
	const classes = useStyles()
	return (
		<Fragment>
			<Grid
				justify="flex-start"
				alignItems="baseline"
				container
				spacing={3}
				direction="row"
			>
				<Grid item xs={12} md={3} lg={4} xl={4}>
					<Autocomplete
						className={classes.autocomplete}
						id="stations"
						name="selectedStation"
						value={
							props.selectedStation ? props.selectedStation : props.stations[0]
						}
						options={props.stations}
						onChange={props.onStationChange}
						getOptionLabel={option => option.caption}
						style={{ width: '90px' }}
						renderInput={params => (
							<TextField 
							
							{...params} label="المواقع" variant="outlined" />
						)}
					/>
				</Grid>
				<Grid item xs={12} md={3} lg={3} xl={3}>
					<TextField
						className={classes.textField}
						type="number"
						id="weight"
						name="weight"
						label="الوزن"
						variant="outlined"
						onChange={props.changeWeight}
						value={props.weight}
					/>
				</Grid>
				<Grid item xs={12} md={3} lg={3} xl={3}>
					<TextField
						className={classes.textField}
						id="notes"
						name="notes"
						label="ملاحظات"
						variant="outlined"
						onChange={props.onNotesChange}
						value={props.notes}
					/>
				</Grid>
				{props.selectedStation && props.selectedStation.name === 'others' ? (
					<Grid item xs={12} md={3} lg={3} xl={3}>
						<TextField
							className={classes.textField}
							id="other_station"
							name="other_station"
							label="أخرى"
							variant="outlined"
							onChange={props.onOtherStationChange}
							value={props.otherStation}
						/>
					</Grid>
				) : null}
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<AtTimeDiv>
						<CheckBox
							name="status"
							label="وقت الدخول؟"
							state={props.showAtTime}
							changeState={props.changeShowAtTime}
						/>

						{props.showAtTime ? (
							<AtTime
								withSeconds={false}
								title="وقت الدخول"
								selectedDate={props.atTime}
								handleDateChange={props.onAtTimeChange}
							/>
						) : null}
					</AtTimeDiv>
				</Grid>

				<Grid item xs={12} md={3} lg={3} xl={3}>
					<IconButton onClick={() => props.addSubFreightCard()}>
						<AddCircleIcon style={{ color: 'green' }} />
					</IconButton>
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default SubControls
