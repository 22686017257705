import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
display:flex;
flex-direction:column;
align-items:flex-start;
border:solid 1px #A7A7A7;
border-radius:10px;
`;
const KeyValDiv = styled.div`
display:flex;
flex-direction:row;
margin:5px;
padding:5px;
`;
function ShareCard(props) {
    return (
        <Root dir="rtl">
             <KeyValDiv>
                <p>: المركبة</p>
                <p>{props.vehicleName}</p>
            </KeyValDiv>
            <KeyValDiv>
                <p>: المركز البلدي</p>
                <p>{props.share.centerName}</p>
            </KeyValDiv>
            <KeyValDiv>
                <p> : الوجبة</p>
                <p>{props.share.shiftName}</p>
            </KeyValDiv>
            <KeyValDiv>
                <p> : السائق</p>
                <p>{props.share.driverName}</p>
            </KeyValDiv>
            <KeyValDiv>
                <p> :  هل لديها وزن</p>
                <p style={{margin:'0 10px '}}>{props.share.coefficient_weight_check?'نعم':'كلا'}</p>
            </KeyValDiv>
            <KeyValDiv>
                <p> :  قيمة معامل الوزن</p>
                <p  style={{margin:'0 10px '}}>{props.share.coefficient_weight_or_not}</p>
            </KeyValDiv>
           
        </Root>
    )
}

export default ShareCard
