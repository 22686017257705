import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import TextField from '@material-ui/core/TextField'

const Root = styled.div`
	width: 100%;
	height: 75px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(0),
		backgroundColor: '#FFA200',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#75CDFF',
			color: '#000',
		},
	},
	TextField: {
		textAlign: 'center',
	},
}))

function CrudControl(props) {
	const classes = useStyles()
	return (
		<Root
		 style={{
			...(props?.stylesh?props?.stylesh:null)
		 }}
		>
			{!props.hiddeButton && props.hiddeButton !== true ? (
				<Button
					onClick={openModal}
					variant="contained"
					color="primary"
					className={classes.button}
					startIcon={<AddCircleIcon />}
				>
					<span
						style={{
							paddingRight: '10px',
						}}
					>
						اضافة
					</span>
				</Button>
			) : null}

			{props.showSearch ? (
				<TextField
					dir="rtl"
					className={classes.TextField}
					size="small"
					id="search"
					label="ابحث"
					variant="outlined"
					value={props.searchValue}
					onChange={props.onSearchChange}
					onKeyDown={props.onSearchEnter}
				/>
			) : null}
		</Root>
	)

	function openModal(e) {
		props.openModal()
	}
}

export default CrudControl
