import React, { PureComponent } from 'react'
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

 class MyTableRow extends React.Component {

    constructor(props)
    {
        super(props)
    
    }
    shouldComponentUpdate(nextProps, nextState)
    {
      if (this.props.header === nextProps.header) {
        return false;
      } else {
  
        return true;
      }
    }
    render() {
        return (
            <TableRow>
            {
                this.props.header.map(function(item,index){
                    return   <StyledTableCell align='right' key={index}>{item}</StyledTableCell>
                })
            }
            </TableRow>
        )
    }
}

export default MyTableRow
