import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { getColor } from '../../common/Utils'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import CheckBox from '../../common/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Alert from '../../common/Alert'
import Grid from '@material-ui/core/Grid'
import Pb from '../../common/ProgressBar'
import AtTime from '../../common/TimePicker'

const AtTimeDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	padding: 10px;
	margin: 10px;
`
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(',')
	}
})

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
		backgroundColor: getColor('primary').dark
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	textField: {
		width: '100%',
		marginTop: '10px'
	},

	autocomplete: {
		marginTop: '15px',
		minWidth: '100%'
	}
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const Root2 = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
`
export default function FullScreenDialog(props) {
	const classes = useStyles()
	const handleEnteredEvent = (e) => {
		if (!props.isRequesting) {
			if (e.key === 'Enter') {
				props.save()
			} else if (e.key === 'Escape') {
				props.hideModal()
			}
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullWidth={true}
					maxWidth="md"
					open={props.show}
					onClose={props.hideModal}
					TransitionComponent={Transition}
					onKeyDown={handleEnteredEvent}
				>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton
								edge="end"
								color="inherit"
								onClick={props.hideModal}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={classes.title}>
								اضافة شحنة
							</Typography>
							{props.isRequesting ? (
								<Pb color="#FFA200"></Pb>
							) : (
								<Button
									disabled={props.isRequesting}
									autoFocus
									color="inherit"
									onClick={props.save}
								>
									حفظ
								</Button>
							)}
						</Toolbar>
					</AppBar>
					<Grid
						justify="flex-start"
						alignItems="center"
						container
						spacing={3}
						direction="column"
					>
						<Grid item xs={12} md={12} lg={12} xl={12}>
							<Autocomplete
								className={classes.autocomplete}
								id="stations"
								name="selectedStation"
								value={
									props.selectedStation
										? props.selectedStation
										: props.stations[0]
								}
								options={props.stations}
								onChange={props.onStationChange}
								getOptionLabel={option => option.caption}
								style={{ width: '200px' }}
								renderInput={params => (
									<TextField {...params} label="المواقع" variant="outlined" />
								)}
							/>
						</Grid>
						<Grid item xs={12} md={12} lg={12} xl={12}>
							<TextField
								className={classes.textField}
								type="number"
								id="weight"
								name="weight"
								label="الوزن"
								variant="outlined"
								onChange={props.changeWeight}
								value={props.weight}
							/>
						</Grid>
						<Grid item xs={12} md={12} lg={12} xl={12}>
							<TextField
								className={classes.textField}
								id="notes"
								name="notes"
								label="ملاحظات"
								variant="outlined"
								onChange={props.onNotesChange}
								value={props.notes}
							/>
						</Grid>
						{props.selectedStation &&
						props.selectedStation.name === 'others' ? (
							<Grid item xs={12} md={12} lg={12} xl={12}>
								<TextField
									className={classes.textField}
									id="other_station"
									name="other_station"
									label="أخرى"
									variant="outlined"
									onChange={props.onOtherStationChange}
									value={props.otherStation}
								/>
							</Grid>
						) : null}
						<Grid item xs={12} md={12} lg={12} xl={12}>
							<AtTimeDiv>
								<CheckBox
									name="status"
									label="وقت الدخول؟"
									state={props.showAtTime}
									changeState={props.changeShowAtTime}
								/>

								{props.showAtTime ? (
									<AtTime
										withSeconds={false}
										title="وقت الدخول"
										selectedDate={props.selectedAtTime}
										handleDateChange={props.onAtTimeChange}
									/>
								) : null}
							</AtTimeDiv>
						</Grid>
					</Grid>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
