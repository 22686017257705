import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Pb from '../../common/ProgressBar'
import { getColor } from '../../common/Utils'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		backgroundColor: getColor('primary').dark,
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	textField: {
		width: '100%',
		marginTop: '10px',
	},
	autocomplete: {
		marginTop: '15px',
	},
}))

export default function FormDialog(props) {
	const classes = useStyles()
	// const shifts = props.vcsds

	const newShifts = props.shifts.filter(function (objFromA) {
		return !props.shiftsData.find(function (objFromB) {
			return objFromA.id === objFromB.id
		})
	})

	const nShifts = newShifts.sort(function (a, b) {
		return a.id - b.id
	})
	// props.onShiftChange('',nShifts[0])
	const handleEnteredEvent = (e) => {
		if (!props?.isRequesting) {
			if (e.key === 'Enter') {
				props.assign()
			} else if (e.key === 'Escape') {
				props.closeAssignModal()
			}
		}
	}
	return (
		console.log(props.selectedShift),
		newShifts ? (
			<ThemeProvider theme={theme}>
				<StylesProvider jss={jss}>
					<Dialog
						open={props.show}
						onClose={props.closeAssignModal}
						aria-labelledby="form-dialog-title"
						onKeyDown={handleEnteredEvent}
					>
						<DialogTitle id="form-dialog-title">
							اسناد سائق الى مركبة
						</DialogTitle>
						<DialogContent>
							<DialogContentText
								style={{ fontSize: '16px', marginBottom: '25px' }}
							>
								المركبة :{' '}
								{props.selectedVehicle.vehicle
									? props.selectedVehicle.vehicle.plate_number
									: null}
							</DialogContentText>
							<Autocomplete
								className={classes.autocomplete}
								id="drivers"
								value={
									props.selectedDriver ? props.selectedDriver : props.drivers[0]
								}
								options={props.drivers}
								onChange={props.onDriverChange}
								getOptionLabel={(option) => option.name}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField {...params} label="السائقين" variant="outlined" />
								)}
							/>
							<Autocomplete
								className={classes.autocomplete}
								id="centers"
								value={
									props.selectedCenter ? props.selectedCenter : props.centers[0]
								}
								options={props.listofChvs}
								onChange={props.onCenterChange}
								getOptionLabel={(option) => option.name}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField {...params} label="المراكز" variant="outlined" />
								)}
							/>
							<Autocomplete
								className={classes.autocomplete}
								id="shifts"
								value={props.selectedShift}
								options={props.listOfChvsShift}
								onChange={props.onShiftChange}
								getOptionLabel={(option) => option.name}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField
										{...params}
										label="الوجبات المتاحة للمركبة"
										variant="outlined"
									/>
								)}
							/>
							{props.isRequesting ? <Pb /> : null}
						</DialogContent>
						<DialogActions>
							<Button onClick={props.closeAssignModal} color="primary">
								الغاء
							</Button>
							<Button onClick={props.assign} color="primary">
								حفظ
							</Button>
						</DialogActions>
					</Dialog>
				</StylesProvider>
			</ThemeProvider>
		) : null
	)
}
