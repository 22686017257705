import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import rtl from 'jss-rtl';
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });



const theme = createMuiTheme({
  direction: 'rtl',
  typography: {
    fontFamily: [
      'Cairo',
      'Arial',
      'sans-serif'
    ].join(','),
  },

});

export default function FormDialog(props) {
  const handleEnteredEvent = (e) => {
    if (e.key === 'Enter') {
      props.saveNewPassword(props.user)
    } else if (e.key === 'Escape') {
      props.closeCPModal()
    }
  }
  return (

    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Dialog
          open={props.showPasswordModal}
          onClose={props.closeCPModal}
          aria-labelledby="form-dialog-title"
          onKeyDown={handleEnteredEvent}
        >
          <DialogTitle id="form-dialog-title-cp">تغيير كلمة المرور</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ادخل كلمة المرور الجديد مع التأكيد
            </DialogContentText>
            <TextField
              onChange={props.changePassword}
              autoFocus
              name="password1"
              value={props.newPassword['password1']}
              margin="dense"
              id="new_password"
              label="كلمة المرور"
              type="password"
              fullWidth
            />
            <TextField
              autoFocus
              name="password2"
              value={props.newPassword['password2']}

              margin="dense"
              id="c_new_password"
              label="تأكيد كلمة المرور"
              type="password"
              fullWidth
              onChange={props.changePassword}

            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.closeCPModal} color="primary">
              الغاء
            </Button>
            <Button onClick={() => props.saveNewPassword(props.user)} color="primary">
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      </StylesProvider>
    </ThemeProvider>


  );
}
