import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	}
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		},
		borderBottom:'2px solid #c4ddec'
	}
}))(TableRow)

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein }
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9)
]

const useStyles = makeStyles({
	table: {
		minWidth: 700
	}
})

function CustomizedTables(props) {
	const classes = useStyles()
	let numbering = 0
	return (
		<TableContainer component={Paper}>
			<Table
				size="small"
				className={classes.table}
				aria-label="customized table"
			>
				<TableHead>
					<TableRow>
						{props.header.map(function(item, index) {
							return (
								<StyledTableCell align="right" key={index}>
									{item}
								</StyledTableCell>
							)
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{props.data.map(function(item, index) {
						const reqData = props.requiredData.filter((req)=>{
							return req.id === item.id
						})
						numbering++
						return (
							<StyledTableRow key={item.id}>
								<StyledTableCell align="right">{numbering}</StyledTableCell>
								<StyledTableCell align="right">{item.username}</StyledTableCell>
								<StyledTableCell align="right">{item.name}</StyledTableCell>
								<StyledTableCell align="right">
									{item.is_active === '1' ? (
										<IconButton onClick={() => props.block(item, index)}>
											<CheckCircleIcon style={{ color: 'green' }} />
										</IconButton>
									) : (
										<IconButton onClick={() => props.block(item, index)}>
											<BlockIcon style={{ color: 'red' }} />
										</IconButton>
									)}
								</StyledTableCell>

								<StyledTableCell align="right">
									{item.role.name}
								</StyledTableCell>
								<StyledTableCell align="right">{item.notes}</StyledTableCell>

								<StyledTableCell align="right">{reqData.length > 0 ? reqData[0].vehicleRequired !== undefined? reqData[0].vehicleRequired : props.totalVeh: '-'}</StyledTableCell>
								<StyledTableCell align="right">{reqData.length > 0 ? reqData[0].required : '-'}</StyledTableCell>

								<StyledTableCell align="right">
									<IconButton
										onClick={() =>
											props.openPasswordModal({
												user: item,
												index: index
											})
										}
									>
										<EditIcon style={{ color: 'blue' }} />
									</IconButton>
								</StyledTableCell>
								<StyledTableCell align="right">
									<IconButton
										onClick={() =>
											props.showEditModalFun({
												user: item,
												index: index
											})
										}
									>
										<EditIcon style={{ color: 'blue' }} />
									</IconButton>
								</StyledTableCell>
								<StyledTableCell align="right">
									<IconButton
										onClick={() =>
											props.showAclModalFun({
												user: item,
												index: index
											})
										}
									>
										<FingerprintIcon style={{ color: 'red' }} />
									</IconButton>
								</StyledTableCell>
							</StyledTableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default CustomizedTables
