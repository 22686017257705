import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import rtl from 'jss-rtl'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Loading from '../../common/Loading'
import Snackbar from '../../common/SnackBar'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import axios from 'axios'
import { getBaseURL, handleError } from '../../common/Utils'
import Cookies from 'js-cookie'

const url = getBaseURL()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const theme = createMuiTheme({
    direction: 'rtl',
    typography: {
        fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(',')
    }
})

const colors = require('../../../assets/files/env.json').theme.colors
const FormBox = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 10px;
	-webkit-box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
	-moz-box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
	box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
`
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        width: '200px',
        marginRight: '15px',
        marginBottom: '10px'
    },
    StylesProvider: {
        marginTop: '20px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))
function WeightNotes(props) {
    const [data, setData] = useState()
    // const [oldData, setOldData] = useState()

    let oldData = data;
    const [isLoading, setIsLoading] = useState(true)
    const [alertError, setAlertError] = useState(false)
    const [alertErrorMsg, setAlertErrorMsg] = useState([])
    const [alertColor, setAlertColor] = useState('error')

    const [itemsShow, setItemsShow] = useState({
        kabesat: true,
        hawiyat: false,
        highUp: false,
        qalab: false,
        kia: false,
        shafal: false,
        astrat: false,
        sarokhia: false,
        shafita: false,
        sahiba: false,
        hafar: false,
        kanisa: false,
        tankar: false,
        takQalab: false,
    })


    const classes = useStyles()

    useEffect(() => {
        const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
        if (
            userData.userInfo.role.name !== 'Admin' &&
            userData.userInfo.role.name !== 'Root'
        ) {
            console.log('roile name is ' + userData.userInfo.role.name)
            window.location.href = '/'
        }

        const fetchData = async () => {

            let result = null
            try {
                result = await axios({
                    url: url + `/report/weightParam/13`, // to get monthly attendence params with id 8
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: 'Bearer ' + userData.token,
                    },
                    data: {},
                })
                // console.log('datamoajar', result.data.Data)
                setData(result.data.Data)
                // setThateeKabesatFirst(result.data.Data.thatee[0].kabesat[0].data)
                // console.log(data)
                setIsLoading(false)


            } catch (error) {
                let errors = handleError(error)
                if (errors === 401) {
                    window.location.href = '/'
                    Cookies.remove('SDIMS_USER_DATA')
                }


            }
            
        }
        fetchData()
        

    }, [])
    function closeSnackbar(event, reason) {
        if (reason === 'clickaway') {
            return
        }
        setAlertError(false)
    }

    function submitParam() {

        setIsLoading(true)
        axios({
            url: url + `/report/updateWeightParam/13`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + JSON.parse(Cookies.get('SDIMS_USER_DATA')).token
            },
            data: data
        })
            .then(response => {
                setIsLoading(false)
                setAlertError(true)
                setAlertErrorMsg('تم تعديل معامل الوزن بنجاح  .')
                setAlertColor('success')

                console.log(response)
            }).catch(error => {
                setIsLoading(false)
                setAlertError(true)
                setAlertErrorMsg('يجب عمل تعديل بأحد الحقول اولاً  .');
                setAlertColor('Warning')

            })
    }

    return (
        <>
            {alertError ? (
                <Snackbar
                    title="خطأ"
                    msg={alertErrorMsg}
                    show={alertError}
                    period={6000}
                    severity={alertColor}
                    closeSnackbar={closeSnackbar}
                ></Snackbar>
            ) : null}
            {isLoading ? (<Loading />) :
                <FormBox>
                    <div>
                        <ThemeProvider theme={theme}>

                            {/* for kabesat weights */}
                            <h1
                            onClick={()=>{setItemsShow({...itemsShow, kabesat:!itemsShow.kabesat})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                الكابسات
                            </h1>
                            {itemsShow.kabesat ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.thatee[0][0].data = e.target.value}}
                                    defaultValue={data.kabesat.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.kabesat.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.kabesat.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.kabesat.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.kabesat.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                            
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.kabesat.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.kabesat.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.kabesat.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.kabesat.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.kabesat.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.kabesat.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null
                            }

                            {/* for rafaat hawayat weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, hawiyat:!itemsShow.hawiyat})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                رافعة الحاويات
                            </h1>
                            {itemsShow.hawiyat ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.hawiyat.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.hawiyat.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.hawiyat.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.hawiyat.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.hawiyat.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.hawiyat.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.hawiyat.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.hawiyat.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.hawiyat.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.hawiyat.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.hawiyat.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}

                            {/* for high up weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, highUp:!itemsShow.highUp})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                هاي اب
                            </h1>
                            {itemsShow.highUp ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.highup.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.highup.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.highup.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.highup.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.highup.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.highup.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.highup.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.highup.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.highup.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.highup.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.highup.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}

                            {/* for qalab weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, qalab:!itemsShow.qalab})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                سكس قلاب
                            </h1>
                            {itemsShow.qalab ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.qalab.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.qalab.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.qalab.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.qalab.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.qalab.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.qalab.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.qalab.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.qalab.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.qalab.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.qalab.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.qalab.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}


                            {/* for kia weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, kia:!itemsShow.kia})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                الكيا
                            </h1>
                            {itemsShow.kia ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.kia.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.kia.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.kia.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.kia.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.kia.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.kia.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.kia.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.kia.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.kia.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.kia.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.kia.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}

                            {/* for shafal weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, shafal:!itemsShow.shafal})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                الشفل
                            </h1>
                            {itemsShow.shafal ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.shafal.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.shafal.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.shafal.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.shafal.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.shafal.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.shafal.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.shafal.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.shafal.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.shafal.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.shafal.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.shafal.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for astrat weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, astrat:!itemsShow.astrat})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                استرات
                            </h1>
                            {itemsShow.astrat ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.astrat.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.astrat.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.astrat.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.astrat.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.astrat.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.astrat.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.astrat.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.astrat.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.astrat.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.astrat.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.astrat.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for sarokhia weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, sarokhia:!itemsShow.sarokhia})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                الصاروخية
                            </h1>
                            {itemsShow.sarokhia ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.sarokhia.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.sarokhia.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.sarokhia.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.sarokhia.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.sarokhia.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.sarokhia.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.sarokhia.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.sarokhia.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.sarokhia.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.sarokhia.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.sarokhia.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for shafitat weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, shafita:!itemsShow.shafita})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                شافطات
                            </h1>
                            {itemsShow.shafita ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.shafita.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.shafita.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.shafita.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.shafita.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.shafita.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.shafita.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.shafita.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.shafita.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.shafita.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.shafita.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.shafita.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for sahibat weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, sahiba:!itemsShow.sahiba})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                ساحبات
                            </h1>
                            {itemsShow.sahiba ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.sahiba.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.sahiba.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.sahiba.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.sahiba.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.sahiba.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.sahiba.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.sahiba.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.sahiba.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.sahiba.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.sahiba.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.sahiba.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for hafara weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, hafar:!itemsShow.hafar})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                حفارة
                            </h1>
                            {itemsShow.hafar ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.hafar.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.hafar.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.hafar.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.hafar.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.hafar.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.hafar.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.hafar.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.hafar.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.hafar.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.hafar.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.hafar.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for kanisa weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, kanisa:!itemsShow.kanisa})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                كانسة
                            </h1>
                            {itemsShow.kanisa ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.kanisa.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.kanisa.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.kanisa.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.kanisa.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.kanisa.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.kanisa.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.kanisa.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.kanisa.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.kanisa.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.kanisa.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.kanisa.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for tankar weights */}
                            <h1
                                onClick={()=>{setItemsShow({...itemsShow, tankar:!itemsShow.tankar})}}
                                style={{
                                    backgroundColor:'#f5f5f5',
                                    width:'20%',
                                    cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                تنكر
                            </h1>
                            {itemsShow.tankar ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.tankar.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.tankar.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.tankar.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.tankar.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.tankar.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.tankar.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.tankar.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.tankar.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.tankar.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.tankar.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.tankar.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                            {/* for tak qalab weights */}
                            <h1
                               onClick={()=>{setItemsShow({...itemsShow, takQalab:!itemsShow.takQalab})}}
                               style={{
                                   backgroundColor:'#f5f5f5',
                                   width:'20%',
                                   cursor:'pointer',
                                    marginRight: '15px',
                                    marginBottom: '10px',
                                    marginTop: '40px',
                                }}
                            >
                                تك قلاب
                            </h1>
                            {itemsShow.takQalab ? <>
                            <StylesProvider jss={jss}>

                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد الذاتي
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="thateeOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.thatee[0][0].data = e.target.value }}
                                    defaultValue={data.takqalab.thatee[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.thatee[0][1].data = e.target.value }}
                                    defaultValue={data.takqalab.thatee[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.thatee[0][2].data = e.target.value }}
                                    defaultValue={data.takqalab.thatee[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.thatee[0][3].data = e.target.value }}
                                    defaultValue={data.takqalab.thatee[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="thateeFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.thatee[0][4].data = e.target.value }}
                                    defaultValue={data.takqalab.thatee[0][4].data}
                                />
                            </StylesProvider>
                            <StylesProvider jss={jss}>
                                <h3
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '20px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    مُعامل قسمة اوزان الجهد المؤجر
                                </h3>
                                <TextField
                                    className={classes.textField}
                                    id="moajarOne"
                                    name="notes"
                                    label="الشفت الفجري"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.moajar[0][0].data = e.target.value }}
                                    defaultValue={data.takqalab.moajar[0][0].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarTwo"
                                    name="notes"
                                    label="الشفت الصباحي"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.moajar[0][1].data = e.target.value }}
                                    defaultValue={data.takqalab.moajar[0][1].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarThree"
                                    name="notes"
                                    label="الشفت المسائي"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.moajar[0][2].data = e.target.value }}
                                    defaultValue={data.takqalab.moajar[0][2].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFour"
                                    name="notes"
                                    label="الشفت الليلي"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.moajar[0][3].data = e.target.value }}
                                    defaultValue={data.takqalab.moajar[0][3].data}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="moajarFive"
                                    name="notes"
                                    label="الطوارئ"
                                    variant="outlined"
                                    onChange={(e) => { data.takqalab.moajar[0][4].data = e.target.value }}
                                    defaultValue={data.takqalab.moajar[0][4].data}
                                />
                            </StylesProvider>
                            </>
                            :
                            null}
                        </ThemeProvider>



                    </div>


                    <div style={{
                        marginTop: '30px',
                        marginRight: '15px',
                        marginBottom: '30px'
                    }}>

                        <Button
                            variant="contained"
                            onClick={() => { submitParam() }}
                            color="primary"
                            size="large"
                        >
                            حفظ
                        </Button>

                    </div>

                </FormBox>
            }
        </>

    )
}

export default WeightNotes
