import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBox from '../../common/CheckBox'
import TextField from '@material-ui/core/TextField'

import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import { FormControlLabel, Switch } from '@material-ui/core'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const colors = require('../../../assets/files/env.json').theme.colors

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
`
const SubFContainers = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	background-color: '#fff';
`

const DataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const DivRow = styled.div`
	display: flex;
	flex-direction: row;
`
const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '500px',
		backgroundColor: '#fff',
	},
	pickers: {
		backgroundColor: '#fff',
	},
	textField: {
		backgroundColor: '#fff',
		marginTop: '10px',
	},
}))

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	)
}

export default function DraggableDialog(props) {
	const classes = useStyles()
	const handleEnteredEvent = (e) => {
		if (!props?.isRequesting) {
			if (e.key === 'Enter') {
				props.edit()
			} else if (e.key === 'Escape') {
				props.closeEditModal()
			}
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullWidth={true}
					maxWidth="lg"
					disableBackdropClick={true}
					open={props.open}
					onClose={props.closeEditModal}
					PaperComponent={PaperComponent}
					aria-labelledby="draggable-dialog-title"
					onKeyDown={handleEnteredEvent}
				>
					<DialogTitle
						style={{
							backgroundColor: '#007AC4',
							color: '#fff',
							cursor: 'move',
						}}
						id="draggable-dialog-title"
					>
						تعديل معلومات المركبة والوجبة
					</DialogTitle>
					<DialogContent
						style={{
							backgroundColor: '#DEF3FF',
						}}
					>
						<Root>
							<Autocomplete
								className={classes.autocomplete}
								id="departments"
								name="selectedDepartment"
								value={
									props.selectedDepartment
										? props.selectedDepartment
										: props.departments[0]
								}
								options={props.departments}
								onChange={props.onDepartmentChange}
								getOptionLabel={(option) => option.name}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField {...params} label="الاقسام" variant="outlined" />
								)}
							/>
							<Autocomplete
								className={classes.autocomplete}
								id=""
								disabled={props.disableCenterField}
								name="selectedCenter"
								value={
									props.selectedCenter ? props.selectedCenter : props.centers[0]
								}
								options={props.centers}
								onChange={props.onCenterChange}
								getOptionLabel={(option) => option.name}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField
										{...params}
										label="المراكز والشعب"
										variant="outlined"
									/>
								)}
							/>

							<Autocomplete
								className={classes.autocomplete}
								id=""
								name="selectedShift"
								value={
									props.selectedShift ? props.selectedShift : props.shifts[0]
								}
								options={props.shifts}
								onChange={props.onShiftChange}
								getOptionLabel={(option) => option.name}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField
										{...params}
										label="الوجبات المتاحة للمركبة"
										variant="outlined"
									/>
								)}
							/>
							<DivRow>
								<FormControlLabel
									control={
										<Switch
											checked={props?.isWeightOrNot}
											onChange={(e) => {
												props.setIsWeightOrNot(e?.target?.checked)
											}}
											color="primary"
										/>
									}
									label={
										props?.isWeightOrNot
											? 'معامل للمركبات ذات الوزن'
											: 'معامل للمركبات التي بدون وزن'
									}
								/>
								<TextField
									className={classes.textField}
									id="weight_coefficient"
									name="weight_coefficient"
									label="المعامل"
									variant="outlined"
									onChange={(e) => {
										console.log('jdfa;sdjfaksjfklasd=>', props.weightOrNotValue)
										if (!isNaN(e?.target?.value))
											props.setWeightOrNotValue(parseInt(e?.target?.value))
									}}
									value={props.weightOrNotValue ? props.weightOrNotValue : ''}
								/>
							</DivRow>
							<DivRow>
								<CheckBox
									name="show_share"
									label="اضافة الحصة؟"
									state={props.showShare}
									changeState={props.changeShowShare}
								></CheckBox>
								{props.showShare ? (
									<TextField
										className={classes.textField}
										id="share"
										name="share"
										label="الحصة"
										variant="outlined"
										onChange={props.onShareChange}
										value={props.share ? props.share : ''}
									/>
								) : null}
							</DivRow>
						</Root>
					</DialogContent>
					<DialogActions>
						<Button
							disabled={props.isRequesting}
							autoFocus
							onClick={props.closeEditModal}
							color="primary"
						>
							الغاء
						</Button>
						<Button
							disabled={props.isRequesting}
							onClick={props.edit}
							color="primary"
						>
							حفظ
						</Button>
					</DialogActions>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
