import React, { Component } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import {
	handleError,
	getColor,
	getBaseURL
} from '../../common/Utils'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import Context from '../../common/Context'
import PB from '../../common/ProgressBar'
import Cookies from 'js-cookie'

import packageJson from '../../../../package.json'
const ver = packageJson.version
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

// server
const url = getBaseURL()

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(',')
	},
	layer1: {
		position: 'absolute',
		top: '25px',
		left: '22px'
	}
})

// styling

const BgDiv = styled.div`
	height: 100vh;
	background-color: ${getColor('primary').dark};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const FormContainer = styled.div`
	width: 450px;
	height: 350px;
	background-color: #ffffff;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px;
`

const Container1 = styled.div`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-content: center;
`
const ImgDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const ImgLogo = styled.img`
	width: 40%;
	margin-top: 15px;
`

const DivTitle = styled.div`
	text-align: center;
	margin-top: 10px;
`

const Span = styled.span`
	font-family: 'Cairo', sans-serif;
`

const Container2 = styled.div`
	display: flex;
	width: 75%;
`

const VerDiv = styled.div`
	position: fixed;
	bottom: 25px;
`
const styles = theme => ({
	root: {
		backgroundColor: 'red'
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: getColor('primary').dark,
		'&.Mui-focused': {
			border: '2px solid red'
		}
	},
	typography: {
		fontFamily: 'Cairo,sans-serif'
	},
	alert: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2)
		},
		bottom: '60px'
	},
	pb: {
		color: '#fff'
	}
})

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLogging: false,
			username: '',
			password: '',
			snackBar: {
				show: false,
				msg: '',
				color: '#000'
			}
		}
		this.login = this.login.bind(this)
		this.onUsernameChange = this.onUsernameChange.bind(this)
		this.onPasswordChange = this.onPasswordChange.bind(this)
		this.closeSnackbar = this.closeSnackbar.bind(this)
		this.detectEnter = this.detectEnter.bind(this)
	}
	render() {
		const { classes } = this.props
		return (
			<Context.Consumer>
				{ctx => {
					return (
						<ThemeProvider theme={theme}>
							<StylesProvider jss={jss}>
								<BgDiv>
									<FormContainer>
										<Container1>
											<ImgDiv>
												<ImgLogo
													src={require('../../../assets/images/logo.png')}
												/>
											</ImgDiv>
											<DivTitle>
												<Span>نظام ادارة بيانات الـGPS</Span>
											</DivTitle>
										</Container1>
										<Container2>
											<form className={classes.form} noValidate>
												<TextField
													onKeyUp={this.detectEnter}
													dir="ltr"
													className={classes.typography}
													onChange={this.onUsernameChange}
													variant="outlined"
													margin="normal"
													required
													fullWidth
													id="username"
													label="اسم المستخدم"
													name="email"
													size="small"
													autoFocus
													inputProps={{ style: { textAlign: 'center' } }}
												/>
												<TextField
													onKeyUp={this.detectEnter}
													variant="outlined"
													margin="normal"
													onChange={this.onPasswordChange}
													dir="ltr"
													required
													fullWidth
													name="password"
													label="كلمة المرور"
													type="password"
													size="small"
													id="password"
													autoComplete="current-password"
													inputProps={{ style: { textAlign: 'center' } }}
												/>

												<Button
													onClick={this.login}
													fullWidth
													variant="contained"
													color="primary"
													className={classes.submit}
													// InputProps={{
													//     className:classes.submit
													// }}
												>
													تسجيل دخول
												</Button>
											</form>
										</Container2>
									</FormContainer>
									<Snackbar
										className={classes.alert}
										open={this.state.snackBar.show}
										autoHideDuration={6000}
										onClose={this.closeSnackbar}
									>
										<div dir="rtl">
											<Alert
												className="alert"
												onClose={this.closeSnackbar}
												severity="warning"
											>
												{this.state.snackBar.msg}
											</Alert>
										</div>
									</Snackbar>
									{this.state.isLogging ? (
										<div
											style={{
												position: 'absolute',
												bottom: '50px'
											}}
										>
											<PB color="secondary" />
										</div>
									) : (
										''
									)}
									<VerDiv>
										<span
											style={{
												backgroundColor: '#fff',
												color: 'black',
												padding: '5px',
												borderRadius: '5px',
												margin: '5px',
												fontSize: '0.8rem'
											}}
										>
											{`V${ver} `}{' '}
										</span>
										<span
											style={{
												color: '#fff',
												fontFamily: 'Cairo'
											}}
										>
											Copyright@2021 by Integrated Solutions For IT Company
										</span>
									</VerDiv>
									{/* <div
										style={{
											position: 'absolute',
											top: '15px',
											left: '25px',
											opacity: '0.3'
										}}
									>
										<img
											width="500"
											height="500"
											src={require('../../../assets/images/wenk-logo.svg')}
										/>
									</div> */}
								</BgDiv>
							</StylesProvider>
						</ThemeProvider>
					)
				}}
			</Context.Consumer>
		)
	}

	onUsernameChange(e) {
		this.setState({ username: e.target.value })
	}
	onPasswordChange(e) {
		this.setState({ password: e.target.value })
	}
	login(e) {
		// auth request
		// validation

		if (this.state.username.length < 4 || this.state.password.length < 4) {
			this.setState({
				...this.state,
				snackBar: {
					show: true,
					msg: 'تأكد من معلومات الدخول',
					color: 'warning'
				},
				isLogging: false
			})

			return
		}
		let that = this
		this.setState({ isLogging: true })
		let formData = new FormData()
		formData.append('username', this.state.username)
		formData.append('password', this.state.password)
		axios({
			url: url + `/users/login`,
			method: 'POST',
			header: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Accept':'application/json'
			},
			data: formData
		})
			.then(async response => {
				let userData = response.data.Data

				Cookies.set('SDIMS_USER_DATA', JSON.stringify(userData), { expires: 3 })

				if (
					userData.userInfo.role.name === 'Admin' ||
					userData.userInfo.role.name === 'Root'
				) {
					this.props.history.push('/dashboard')
					// console.log('going to dashboard')
				}
				if (userData.userInfo.role.name === 'Reporter')
					this.props.history.push('/reports')
				if (userData.userInfo.role.name === 'Data Entry')
					this.props.history.push('/freights')

				if (userData.userInfo.role.name === 'Reporter & Data Entry')
					this.props.history.push('/freights')
				this.setState({ isLogging: false })
			})
			.catch(function(err) {
				that.setState({
					...that.state,
					snackBar: {
						msg: handleError(err),
						show: true,
						color: 'error'
					},
					isLogging: false
				})
			})
	}

	closeSnackbar(e) {
		this.setState({
			...this.state,
			snackBar: {
				msg: '',
				color: '#000',
				show: false
			} // end snack
		})
	}
	detectEnter(e) {
		if (e.keyCode == 13) {
			this.login(null)
		}
	}
}

export default withStyles(styles, { withTheme: true })(Login)
