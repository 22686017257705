import React from 'react'

function Shifts() {
    return (
        <div>
            Shifts
        </div>
    )
}

export default Shifts
