import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DescriptionAlerts(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity={props.severity}>
        <AlertTitle>{props.title}</AlertTitle>
        {
          props.msg.map(function (item, index) {
            return <p key={index}>{item}</p>
          })
        }
      </Alert>

    </div>
  );
}
