import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import rtl from 'jss-rtl'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Loading from '../../common/Loading'
import Snackbar from '../../common/SnackBar'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import axios from 'axios'
import { getBaseURL, handleError } from '../../common/Utils'
import Cookies from 'js-cookie'

const url = getBaseURL()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const theme = createMuiTheme({
    direction: 'rtl',
    typography: {
        fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(',')
    }
})

const colors = require('../../../assets/files/env.json').theme.colors
const FormBox = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 10px;
	-webkit-box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
	-moz-box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
	box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
`
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        width: '600px',
        marginRight: '15px',
        marginBottom: '10px'
    },
    StylesProvider: {
        marginTop: '20px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))
function Notes(props) {
    const [data, setData] = useState()
    // const [oldData, setOldData] = useState()

    let oldData = data;
    const [isLoading, setIsLoading] = useState(true)
    const [alertError, setAlertError] = useState(false)
    const [alertErrorMsg, setAlertErrorMsg] = useState([])
    const [alertColor, setAlertColor] = useState('error')


    const classes = useStyles()

    useEffect(() => {
        const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
        if (
            userData.userInfo.role.name !== 'Admin' &&
            userData.userInfo.role.name !== 'Root'
        ) {
            console.log('roile name is ' + userData.userInfo.role.name)
            window.location.href = '/'
        }

        const fetchData = async () => {

            let result = null
            try {
                result = await axios({
                    url: url + `/report/param/8`, // to get monthly attendence params with id 8
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: 'Bearer ' + userData.token,
                    },
                    data: {},
                })

                setData(result.data.Data)
                // setThateeKabesatFirst(result.data.Data.thatee[0].kabesat[0].data)
                // console.log(data)


            } catch (error) {
                let errors = handleError(error)
                if (errors === 401) {
                    window.location.href = '/'
                    Cookies.remove('SDIMS_USER_DATA')
                }


            }
            setIsLoading(false)
        }
        fetchData()


    }, [])
    function closeSnackbar(event, reason) {
        if (reason === 'clickaway') {
            return
        }
        setAlertError(false)
    }

    function submitParam() {

        setIsLoading(true)
        axios({
            url: url + `/report/update/8`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + JSON.parse(Cookies.get('SDIMS_USER_DATA')).token
            },
            data: data
        })
            .then(response => {
                setIsLoading(false)
                setAlertError(true)
                setAlertErrorMsg('تم تعديل ملاحظة الحضورية الشهرية بنجاح  .')
                setAlertColor('success')

                console.log(response)
            }).catch(error => {
                setIsLoading(false)
                setAlertError(true)
                setAlertErrorMsg('يجب عمل تعديل بأحد الحقول اولاً  .');
                setAlertColor('Warning')

            })
    }
    useEffect(() => {
        console.log('sdsdsdfsdf====>')
        document.addEventListener('keydown', handleEnteredEvent)
        return () => {
            document.removeEventListener('keydown', handleEnteredEvent)
        }
    })
    const handleEnteredEvent = (e) => {
        if (e.key === 'Enter') {
            submitParam()
        } else if (e.key === 'Escape') {
          
        }
    }
    return (

        <>
            {alertError ? (
                <Snackbar
                    title="خطأ"
                    msg={alertErrorMsg}
                    show={alertError}
                    period={6000}
                    severity={alertColor}
                    closeSnackbar={closeSnackbar}
                ></Snackbar>
            ) : null}
            {isLoading ? (<Loading />) :
                <FormBox>
                    <div>
                        <ThemeProvider theme={theme}>
                            <StylesProvider jss={jss}>
                                <h2
                                    style={{
                                        marginRight: '15px',
                                        marginBottom: '30px',
                                        marginTop: '50px',
                                    }}
                                >
                                    ملاحظات الجهد الذاتي
                                </h2>
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="الكابسات الكبيرة"
                                    variant="outlined"
                                    onChange={(e) => { data.thatee[0].kabesat[0].data = e.target.value }}
                                    // defaultValue="الكابسات الكبيره 1 شحنه لكل وجبه عمل 5 طن"
                                    defaultValue={data.thatee[0].kabesat[0].data}
                                    multiline
                                    rows={2}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="الكابسات المتوسطة والصغيرة"
                                    variant="outlined"
                                    onChange={(e) => { data.thatee[0].kabesat[1].data = e.target.value }}
                                    defaultValue={data.thatee[0].kabesat[1].data}
                                    multiline
                                    rows={2}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="القلابات"
                                    variant="outlined"
                                    onChange={(e) => { data.thatee[0].others[0].data = e.target.value }}
                                    defaultValue={data.thatee[0].others[0].data}
                                    multiline
                                    rows={2}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="الشفلات"
                                    variant="outlined"
                                    onChange={(e) => { data.thatee[0].others[1].data = e.target.value }}
                                    defaultValue={data.thatee[0].others[1].data}
                                    multiline
                                    rows={2}
                                />
                            </StylesProvider>

                            <StylesProvider jss={jss}>
                                <h2
                                    style={{
                                        marginRight: '15px',
                                        marginTop: '50px',
                                        marginBottom: '30px',
                                    }}
                                >
                                    ملاحظات الجهد المؤجر
                                </h2>
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="الكابسات الكبيرة"
                                    variant="outlined"
                                    onChange={(e) => { data.moajar[0].kabesat[0].data = e.target.value }}
                                    defaultValue={data.moajar[0].kabesat[0].data}
                                    multiline
                                    rows={2}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="الكابسات المتوسطة والصغيرة"
                                    variant="outlined"
                                    onChange={(e) => { data.moajar[0].kabesat[1].data = e.target.value }}
                                    defaultValue={data.moajar[0].kabesat[1].data}
                                    multiline
                                    rows={2}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="القلابات"
                                    variant="outlined"
                                    onChange={(e) => { data.moajar[0].others[0].data = e.target.value }}
                                    defaultValue={data.moajar[0].others[0].data}
                                    multiline
                                    rows={2}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="notes"
                                    name="notes"
                                    label="الشفلات"
                                    variant="outlined"
                                    onChange={(e) => { data.moajar[0].others[1].data = e.target.value }}
                                    defaultValue={data.moajar[0].others[1].data}
                                    multiline
                                    rows={2}
                                />
                            </StylesProvider>

                        </ThemeProvider>



                    </div>


                    <div style={{
                        marginTop: '30px',
                        marginRight: '15px',
                        marginBottom: '30px'
                    }}>

                        <Button
                            variant="contained"
                            onClick={() => { submitParam() }}
                            color="primary"
                            size="large"
                        >
                            حفظ
                        </Button>

                    </div>

                </FormBox>
            }
        </>

    )
}

export default Notes
